import { decorate, observable, action } from "mobx";
import Service from "../services/Service";

class TextStore {
  texts = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.textService = new Service(`pageText`);
  }

  updateText = (page, props) => {
    return this.textService.replace(page, props);
  };

  createText = (props) => {
    return this.textService.create(props);
  };

  setTexts = () => {
    return this.textService.getAll().then((data) => {
      this.texts = data;
    });
  };
}

decorate(TextStore, {
  texts: observable,
  updateText: action,
  createText: action,
});

export default TextStore;
