import React from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { ROUTES } from "../../../constants";
import succesForgotPasswordStyles from "./SuccesForgotPassword.module.scss";
import globalStyles from "../../../styles/main.scss";

const SuccesForgotPassword = ({ history }) => {
  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    history.push(ROUTES.login);
  };

  return (
    <form onSubmit={handleSubmit} className={globalStyles.formContainer}>
      <p className={succesForgotPasswordStyles.bio}>
        {t("forgotten-password-succes-screen.intro")}
      </p>
      <button type="submit" className="btn btn-secondary">
        {t("forgotten-password-succes-screen.cta")}
      </button>
      <p className={succesForgotPasswordStyles.forgot}>
        {" "}
        {t("forgotten-password-succes-screen.remember")}{" "}
        <span className={succesForgotPasswordStyles.forgotSpan}>
          {t("forgotten-password-succes-screen.resend")}
        </span>
      </p>
    </form>
  );
};

export default withRouter(SuccesForgotPassword);
