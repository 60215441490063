import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Modal, Spinner, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

import * as yup from "yup";
import { Formik, Form, Field } from "formik";
import InputFormField from "../../../../components/fields/InputFormField";

const schema = yup.object({
  name: yup.string().required("Please fill in a phase name"),
});

const PhaseEdit = ({ surveyAdminStore, id, close }) => {
  const { t } = useTranslation();
  const [updating, setUpdating] = useState(false);
  const [archiving, setArchiving] = useState(false);

  const [phase, setPhase] = useState(null);

  useEffect(() => {
    surveyAdminStore
      .getPhaseCategoryById(id)
      .then((phase) => setPhase(phase))
      .catch((e) => alert(e.message));
  }, []);

  return (
    <section className="modal-wrapper">
      <Modal.Dialog size="lg">
        {phase ? (
          <Formik
            validationSchema={schema}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
              name: phase.name,
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              setUpdating(true);
              surveyAdminStore
                .updatePhaseCategory(id, values)
                .then(() => close());
              setUpdating(false);
              setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Modal.Header closeButton onHide={() => close()}>
                  <Modal.Title>
                    {phase && phase.name + " " + "Details"}
                  </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <div className="row mb-3">
                    <div className="col">
                      <Field
                        label={t("phase-cat-survey.name")}
                        name="name"
                        placeholder="Phase Name"
                        component={InputFormField}
                      />
                    </div>
                  </div>
                </Modal.Body>

                <Modal.Footer className="mt-4">
                  <Button onClick={() => close()} variant="link">
                    {t("cancel")}
                  </Button>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="primary"
                  >
                    {updating ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      t("save")
                    )}
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        ) : (
          <div className="d-flex justify-content-center align-items center p-5">
            <Spinner variant="primary" animation="border" />
          </div>
        )}
      </Modal.Dialog>
    </section>
  );
};

export default inject("surveyAdminStore")(withRouter(observer(PhaseEdit)));
