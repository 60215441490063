import React from "react";
import { Link, withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import { Nav } from "react-bootstrap";
import classNames from "classnames";

const StartupSidebar = ({ history, to, children, icon: Icon, exact }) => {
  const active = exact
    ? history.location.pathname === to
    : history.location.pathname.startsWith(to);
  return (
    <Nav.Link
      as={Link}
      to={to}
      className={classNames({
        active,
      })}
    >
      <div className="item">
        <Icon />
        <span className="item-description">{children}</span>
      </div>
    </Nav.Link>
  );
};

export default withRouter(observer(StartupSidebar));
