export default {
  dashboard: `/`,
  login: `/login`,
  passwordForgot: `/login/password-forgot`,
  succesForgot: `/login/password-forgot/succes`,
  resetPassword: `/password-reset`,
  resetSucces: `/password-reset/succes`,
  surveys: `/surveys`,
  takeSurvey: `/surveys/:id/take`,
  teamSurveys: `/surveys/team`,
  dreamSurveys: `/surveys/dream`,
  results: `/results`,
  resultDetail: `/results/:id`,
  reports: `/reports`,
  growthReports: `/reports/growth`,
  profile: `/profile`,
  editProfile: `/profile/edit`,
  editProfileStartup: `/profile/editStartup`,
  kanban: `/kanban`,
  users: `/users`,
  permissions: `/users/:id/permissions`,
  startups: `/startups`,
  organizations: `/organizations`,
};
