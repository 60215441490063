import React from "react";
import { Link, withRouter } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Nav, Button } from "react-bootstrap";

import SidebarItem from "./SidebarItem.jsx";

import logoIcon from "../../../assets/logo_icon-only.png";
import logoText from "../../../assets/logo_text-only.png";
import { ROUTES, PERMISSIONS } from "../../../constants/index";

import { ReactComponent as DashboardIcon } from "../../../assets/icons/menu/dashboard.svg";
import { ReactComponent as SurveysIcon } from "../../../assets/icons/menu/surveys.svg";
import { ReactComponent as ResultsIcon } from "../../../assets/icons/menu/results.svg";
import { ReactComponent as ReportsIcon } from "../../../assets/icons/menu/reports.svg";
import { ReactComponent as KanbanIcon } from "../../../assets/icons/menu/kanban.svg";
import { ReactComponent as ProfileIcon } from "../../../assets/icons/menu/profile.svg";
import { ReactComponent as UsersIcon } from "../../../assets/icons/menu/users.svg";
import { ReactComponent as StartupsIcon } from "../../../assets/icons/menu/startups.svg";
import { ReactComponent as OrganizationIcon } from "../../../assets/icons/menu/organizations.svg";

const StartupSidebar = ({ authStore, history }) => {
  const { t } = useTranslation();

  const hasPermission = (permission) => {
    return (
      authStore.user && authStore.user.permissions.find((p) => p === permission)
    );
  };

  const hasActiveStartup = () => authStore.activeStartupUuid;

  const sections = [
    {
      title: null,
      items: [
        <SidebarItem exact to={ROUTES.dashboard} icon={DashboardIcon} key="1">
          {t("menu-items.dashboard")}
        </SidebarItem>,
      ],
    },
    {
      title: t("menu-items.title-surveys"),
      items: [
        hasPermission(PERMISSIONS.SURVEYS_TAKE_DREAM_STARTUP) &&
          hasActiveStartup() && (
            <SidebarItem to={ROUTES.dreamSurveys} icon={SurveysIcon} key="2">
              {t("menu-items.takeSurveys")}
            </SidebarItem>
          ),
        hasPermission(PERMISSIONS.SURVEYS_LIST_ALL) && (
          <SidebarItem to="/surveys/management" icon={SurveysIcon} key="3">
            {t("menu-items.surveys")}
          </SidebarItem>
        ),
        hasPermission(PERMISSIONS.RESULTS_LIST_MINE) && (
          <SidebarItem to={ROUTES.results} icon={ResultsIcon} key="4">
            {t("menu-items.snapshots")}
          </SidebarItem>
        ),
        hasPermission(PERMISSIONS.REPORTS_GENERATE_KPI_STARTUP) && (
          <SidebarItem to={ROUTES.reports} icon={ReportsIcon} key="5">
            {t("menu-items.reports")}
          </SidebarItem>
        ),
      ],
    },
    {
      title: t("menu-items.title-work"),
      items: [
        hasPermission(PERMISSIONS.KANBAN_LIST_STARTUP) && (
          <SidebarItem to={ROUTES.kanban} icon={KanbanIcon} key="6">
            {t("menu-items.kanban")}
          </SidebarItem>
        ),
      ],
    },
    {
      title: t("menu-items.title-manage"),
      items: [
        hasPermission(PERMISSIONS.ORGANIZATIONS_LIST_ALL) && (
          <SidebarItem
            to={ROUTES.organizations}
            icon={OrganizationIcon}
            key="7"
          >
            {t("menu-items.organizations")}
          </SidebarItem>
        ),
        hasPermission(PERMISSIONS.STARTUPS_LIST_ORGANIZATION) && (
          <SidebarItem to={ROUTES.startups} icon={StartupsIcon} key="8">
            {t("menu-items.startups")}
          </SidebarItem>
        ),
        hasPermission(PERMISSIONS.USERS_LIST_ORGANIZATION) && (
          <SidebarItem to={ROUTES.users} icon={UsersIcon} key="9">
            {t("menu-items.users")}
          </SidebarItem>
        ),
      ],
    },
    {
      title: t("menu-items.title-profile"),
      items: [
        <SidebarItem to={ROUTES.profile} icon={ProfileIcon} key="10">
          <>
            {authStore.user && authStore.user.name}
            <span className="small text-muted d-block">
              {authStore.user && authStore.user.email}
            </span>
          </>
        </SidebarItem>,
      ],
    },
  ];

  return (
    <Nav className="flex-column">
      <Link to={ROUTES.dashboard}>
        <div className="d-inline-flex align-items-center p-3 mb-3">
          <img src={logoIcon} alt="Icon" className="menu-icon" height="40" />
          <img
            src={logoText}
            alt="Logo"
            className="menu-logo-extended ml-2"
            height="25"
          />
        </div>
      </Link>

      {authStore.user &&
        sections.map(
          (section, key) =>
            section.items.find((i) => i) && (
              <section key={key}>
                <h2 className="text-muted">{section.title}</h2>
                {section.items}
              </section>
            )
        )}

      <Button
        variant="link"
        className="text-danger p-0"
        onClick={() => {
          authStore.logout();
        }}
      >
        {t("profile-screen.logout")}
      </Button>
    </Nav>
  );
};

export default inject(`authStore`)(withRouter(observer(StartupSidebar)));
