import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Spinner, Button } from "react-bootstrap";

import PageSection from "../../../../components/PageSection";

import * as yup from "yup";
import { Formik, Form, Field, FieldArray } from "formik";
import InputFormField from "../../../../components/fields/InputFormField";

const schema = yup.object({});

const AnswerGAnswersNew = ({ surveyAdminStore, id, close }) => {
  const [updating, setUpdating] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {}, []);

  return (
    <Container className="pt-4">
      <Formik
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{ options: "" }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          setUpdating(true);

          values.options.map((o) => {
            const newAnswerOption = {
              identifier: o.identifier,
              text: o.text,
              groupId: Number(id),
            };
            surveyAdminStore
              .createAnswersOptions(newAnswerOption)
              .then(() => close());
          });
          setUpdating(false);
          setSubmitting(false);
        }}
      >
        {({ values, isSubmitting }) => (
          <Form>
            <PageSection title={t("answers-survey.titleAo")} className="p-2">
              <div className="row">
                <div className="col">{t("answers-survey.labels.id")}</div>
                <div className="col">{t("answers-survey.labels.name")}</div>
                <div className="col"></div>
              </div>
              <FieldArray
                name="options"
                render={(arrayHelpers) => (
                  <div>
                    {values.options &&
                      values.options.map((option, index) => (
                        <div key={index} className="row">
                          <div className="col">
                            <Field
                              name={`options.${index}.identifier`}
                              component={InputFormField}
                            />
                          </div>
                          <div className="col">
                            <Field
                              name={`options.${index}.text`}
                              component={InputFormField}
                            />
                          </div>
                          <div className="col pt-4">
                            <Button
                              variant="outline-secondary"
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              -
                            </Button>
                          </div>
                        </div>
                      ))}
                    <Button
                      variant="outline-primary"
                      className="mt-4 w-100"
                      onClick={() => arrayHelpers.push("")}
                    >
                      +
                    </Button>
                  </div>
                )}
              />
            </PageSection>
            <div className="text-right mb-3">
              <Button onClick={() => close()} variant="link">
                {t("cancel")}
              </Button>
              <Button type="submit" disabled={isSubmitting} variant="primary">
                {updating ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  t("save")
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default inject("surveyAdminStore")(
  withRouter(observer(AnswerGAnswersNew))
);
