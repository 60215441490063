import React from "react";

const PageLoader = () => (
  <div className="w-100 vh-100 d-flex justify-content-center align-items-center text-primary">
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);

export default PageLoader;
