import { decorate, observable, action } from "mobx";
import Survey from "../models/Survey";
import Service from "../services/Service";
class SurveyStore {
  surveys = [];
  allSurveys = [];
  savedAnswers = [];

  setAllSurveys = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.surveyService = new Service(`surveys`);
    this.snapshotService = new Service("snapshots");
    this.snapshotAnswersService = new Service("snapshots/answers");
  }

  findById = async (testId) => {
    return this.surveyService.getById(testId);
  };

  findAll = async () => {
    const allSurveys = [];

    await this.surveyService.getAll().then((data) => {
      data.forEach((d) => {
        allSurveys.push(d);
      });
    });

    return allSurveys;
  };

  findFirstByType = async (type) => {
    const allSurveys = [];

    await this.surveyService.getAll().then((data) => {
      data.forEach((d) => {
        allSurveys.push(d);
      });
    });

    const surveys = allSurveys.find((test) => test.type === type);

    return surveys;
  };

  findAllByType = async (type) => {
    const allSurveys = [];

    await this.surveyService.getAll().then((data) => {
      data.forEach((d) => {
        allSurveys.push(d);
      });
    });

    const surveys = allSurveys.filter((test) => test.type === type);

    return surveys;
  };

  saveTheAnswers = (props) => {
    return this.snapshotAnswersService.create(props);
  };

  createSurvey = (props) => {
    return this.surveyService.create(props);
  };

  updateSurvey = (id, props) => {
    return this.surveyService.replace(id, props);
  };

  deleteSurvey = (id) => {
    return this.surveyService.delete(id);
  };

  getById = (id) => {
    return this.surveyService
      .getById(id)
      .then((surveys) => (this.setAllSurveys = surveys));
  };

  setSurveys = () => {
    return this.surveyService
      .getAll()
      .then((surveys) => (this.setAllSurveys = surveys));
  };

  getAll = () => {
    this.surveyService.getAll().then((data) => {
      data.forEach((d) => {
        this.allSurveys.push(d);
      });
      data.forEach(this._addSurvey);
    });
  };

  addSurvey = (data) => {
    const newSurvey = new Survey();
    newSurvey.updateFromServer(data);
    this.surveys.push(newSurvey);
    return this.surveyService.create(newSurvey).then((surveyValues) => {
      newSurvey.updateFromServer(surveyValues);
      Promise.resolve();
    });
  };

  _addSurvey = (values) => {
    const survey = new Survey();
    survey.updateFromServer(values);
    this.surveys.push(survey);
  };

  createSnapshot = (surveyId) => {
    return this.snapshotService
      .create({
        surveyId,
      })
      .then((data) => {
        localStorage.setItem(`snapshotId for ${surveyId}`, data.id);
      });
  };
}

decorate(SurveyStore, {
  surveys: observable,
  addSurvey: action,
});

export default SurveyStore;
