import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Modal, Spinner, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

import * as yup from "yup";
import { Formik, Form, Field, FieldArray } from "formik";
import InputFormField from "../../../components/fields/InputFormField";
import SelectFormField from "../../../components/fields/SelectFormField";
import ReactSelectFormField from "../../../components/fields/ReactSelectFormField";
import { PERMISSIONS } from "../../../constants";
import { Landen } from "../../../components/landList";

const schema = yup.object({
  name: yup.string().required("Please fill in a Startup name"),
  surveyId: yup
    .number()
    .positive()
    .integer()
    .required("Please seleced a Survey"),
});

const NewStartupModel = ({
  startupStore,
  surveyStore,
  organizationStore,
  authStore,
  close,
}) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const [updating, setUpdating] = useState(false);
  const countryArray = Landen;

  const hasPermission = (permission) => {
    return (
      authStore.user && authStore.user.permissions.find((p) => p === permission)
    );
  };

  useEffect(() => {
    surveyStore
      .findAll()
      .then((surveys) => {
        const allOptions = [];
        surveys.forEach((survey) => {
          const item = { label: survey.name, value: survey.id };
          survey.active === true && allOptions.push(item);
        });

        setOptions(allOptions);
      })
      .catch((e) => alert(e.message));

    organizationStore
      .setOrganizations()
      .then(getOrganizationOptions)
      .catch((e) => alert(e.message));
  }, []);

  const getOrganizationOptions = () => {
    const organizationOptions = [];
    !hasPermission(PERMISSIONS.STARTUPS_LIST_ALL)
      ? organizationOptions.push({
          label: authStore.user.organization.name,
          value: authStore.user.organization.uuid,
        })
      : organizationStore.organizations &&
        organizationStore.organizations.map((u) => {
          organizationOptions.push({ label: u.name, value: u.uuid });
        });
    setOrganizationOptions(organizationOptions);
  };

  return (
    <section className="modal-wrapper">
      <Modal.Dialog size="lg">
        <Formik
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            name: "",
            phone: "",
            website: "",
            vat: "",
            organizations:
              !hasPermission(PERMISSIONS.STARTUPS_LIST_ALL) &&
              authStore.user.organization.uuid,
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            setUpdating(true);
            const valueOrg = [];

            values.organizations.length > 0 &&
              hasPermission(PERMISSIONS.STARTUPS_LIST_ALL) &&
              values.organizations.map((org) => valueOrg.push(org.uuid));

            const newValues = {
              name: values.name,
              email: values.email,
              phone: values.phone,
              website: values.website,
              vat: values.vat,
              country: values.country,
              surveyId: values.surveyId,
              organizationUuids: !hasPermission(PERMISSIONS.STARTUPS_LIST_ALL)
                ? [values.organizations]
                : valueOrg,
            };

            startupStore.createStartup(newValues).then(() => close());
            setUpdating(false);
            setSubmitting(false);
          }}
        >
          {({ values, isSubmitting }) => (
            <Form>
              <Modal.Header closeButton onHide={() => close()}>
                <Modal.Title>
                  {t("startup-admin-screen.new-modal.modal-title")}
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <div className="row row-cols-2 mb-3">
                  <div className="col">
                    <Field
                      label={t("startup-admin-screen.new-modal.field-title")}
                      name="name"
                      placeholder={t(
                        "startup-admin-screen.new-modal.field-title-placeholder"
                      )}
                      component={InputFormField}
                    />
                  </div>

                  <div className="col">
                    <Field
                      label={t("startup-admin-screen.new-modal.field-email")}
                      name="email"
                      placeholder={t(
                        "startup-admin-screen.new-modal.field-email-placeholder"
                      )}
                      component={InputFormField}
                    />
                  </div>
                </div>
                <div className="row row-cols-2 mb-3">
                  <div className="col">
                    <Field
                      label={t("startup-admin-screen.new-modal.field-phone")}
                      name="phone"
                      placeholder={t(
                        "startup-admin-screen.new-modal.field-phone-placeholder"
                      )}
                      component={InputFormField}
                    />
                  </div>
                  <div className="col">
                    <Field
                      label={t("startup-admin-screen.new-modal.field-website")}
                      name="website"
                      placeholder={t(
                        "startup-admin-screen.new-modal.field-website-placeholder"
                      )}
                      component={InputFormField}
                    />
                  </div>
                </div>
                <div className="row row-cols-2 mb-3">
                  <div className="col">
                    <Field
                      label={t("startup-admin-screen.new-modal.field-vat")}
                      name="vat"
                      placeholder={t(
                        "startup-admin-screen.new-modal.field-vat-placeholder"
                      )}
                      component={InputFormField}
                    />
                  </div>
                  <div className="col">
                    <Field
                      label={t("startup-admin-screen.edit-modal.field-country")}
                      name="country"
                      options={countryArray}
                      placeholder={t(
                        "startup-admin-screen.edit-modal.field-country-placeholder"
                      )}
                      component={SelectFormField}
                    />
                  </div>
                </div>
                <div className="row row-cols-2 mb-3">
                  <div className="col">
                    <Field
                      label={t("startup-admin-screen.new-modal.field-survey")}
                      name="surveyId"
                      options={options}
                      component={SelectFormField}
                    />
                  </div>
                  <div className="col">
                    <div>Organizations</div>
                    {!hasPermission(PERMISSIONS.STARTUPS_LIST_ALL) ? (
                      <div className="pt-3">
                        <Field
                          name="organizations"
                          options={organizationOptions}
                          component={ReactSelectFormField}
                        />
                      </div>
                    ) : (
                      <FieldArray
                        name="organizations"
                        render={(arrayHelpers) => (
                          <div>
                            {values.organizations &&
                              values.organizations.map((org, index) => (
                                <div key={index} className="row">
                                  <div className="col-9 pt-3 w-100">
                                    <Field
                                      name={`organizations.${index}.uuid`}
                                      options={organizationOptions}
                                      component={ReactSelectFormField}
                                    />
                                  </div>
                                  <div className="col-1 pt-3">
                                    <Button
                                      variant="outline-secondary"
                                      onClick={() => {
                                        arrayHelpers.remove(index);
                                      }}
                                    >
                                      -
                                    </Button>
                                  </div>
                                </div>
                              ))}
                            <Button
                              variant="outline-primary"
                              className="mt-4 w-100"
                              onClick={() => arrayHelpers.push("")}
                            >
                              +
                            </Button>
                          </div>
                        )}
                      />
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className="mt-4">
                <Button onClick={() => close()} variant="link">
                  {t("cancel")}
                </Button>
                <Button type="submit" disabled={isSubmitting} variant="primary">
                  {updating ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    t("save")
                  )}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal.Dialog>
    </section>
  );
};

export default inject(
  "startupStore",
  "surveyStore",
  "authStore",
  "organizationStore"
)(withRouter(observer(NewStartupModel)));
