import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Spinner, Button } from "react-bootstrap";

import PageSection from "../../../../components/PageSection";

import * as yup from "yup";
import { Formik, Form, Field } from "formik";
import InputFormField from "../../../../components/fields/InputFormField";
import SelectFormField from "../../../../components/fields/SelectFormField";

const schema = yup.object({
  identifier: yup
    .string()
    .required("Please fill in the answer group identifier"),
  name: yup.string().required("Please fill in the answer group name"),
  type: yup.string().required("Please select a type"),
});

const AnswerNew = ({ surveyAdminStore, close, history }) => {
  const [updating, setUpdating] = useState(false);

  const { t } = useTranslation();
  const answerGroupTypeOptions = [
    { label: "MULTIPLE_CHOICE", value: "MULTIPLE_CHOICE" },
    { label: "SINGLE_CHOICE", value: "SINGLE_CHOICE" },
  ];

  useEffect(() => {}, []);

  return (
    <Container className="pt-4">
      <Formik
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{ identifier: "", name: "" }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          setUpdating(true);
          surveyAdminStore
            .createAnswerGroup(values)
            .then((r) =>
              history.push(`/surveys/answer-group/new-options/${r.id}`)
            );
          setUpdating(false);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <PageSection title={t("answers-survey.title")} className="p-2">
              <Field
                label={t("answers-survey.labels.id")}
                name="identifier"
                component={InputFormField}
              />
              <Field
                label={t("answers-survey.labels.name")}
                name="name"
                component={InputFormField}
              />
              <Field
                label={t("answers-survey.labels.type")}
                options={answerGroupTypeOptions}
                name="type"
                component={SelectFormField}
              />
            </PageSection>
            <div className="text-right mb-3">
              <Button onClick={() => close()} variant="link">
                {t("cancel")}
              </Button>
              <Button type="submit" disabled={isSubmitting} variant="primary">
                {updating ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  t("next")
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default inject("surveyAdminStore")(withRouter(observer(AnswerNew)));
