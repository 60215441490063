import React, { useState, useEffect } from "react";
import { inject } from "mobx-react";
import { useHistory } from "react-router-dom";

import {
  Container,
  Row,
  Button,
  Col,
  Tooltip,
  OverlayTrigger,
  Spinner,
  ProgressBar,
} from "react-bootstrap";

import { ROUTES } from "../../constants";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import BootstrapForm from "react-bootstrap/Form";
import CheckFormField from "../../components/fields/CheckFormField";
import SelectFormField from "../../components/fields/SelectFormField";
import TextAreaFormField from "../../components/fields/TextAreaFormField";

import PageLoader from "../../components/PageLoader";

const TakeSurveys = ({ id, surveyStore, reportsStore }) => {
  //
  const { t } = useTranslation();
  let history = useHistory();
  //
  const [procentAmount, setProcentAmount] = useState();
  const [procent, setProcent] = useState();
  //
  const [count, setCount] = useState(0);
  //
  const [perPage, setPerPage] = useState(3);
  const [getSlice, setSlice] = useState("");
  //
  const [allQuestions, setAllQuestions] = useState([]);
  const [totalPages, setTotalPages] = useState("");
  //
  const [schema, setValidationSchema] = useState();
  const [loading, setLoading] = useState(false);
  //
  const getSurvey = async () => {
    const surveyQuestions = [];
    const survey = await surveyStore.findById(id);

    survey &&
      survey.questions.forEach((question) => {
        surveyQuestions.push(question.question);
      });

    const pages = Math.ceil(survey.questionCount / perPage);
    const procent01 = Math.round(100 / pages);
    setProcentAmount(procent01);

    setProcent(
      localStorage.getItem(`procent for ${id}`)
        ? Number(localStorage.getItem(`procent for ${id}`))
        : procent01
    );

    setTotalPages(pages);

    setAllQuestions(surveyQuestions);
    getQuestionsPerPage(
      localStorage.getItem(`offset for ${id}`)
        ? localStorage.getItem(`offset for ${id}`)
        : 0,
      surveyQuestions
    );
  };

  const getQuestionsPerPage = (offset, questions) => {
    const yupObjects = yup.object().shape({});
    const slice = questions.slice(offset, offset + perPage);

    slice.forEach((question) => {
      if (question.answerGroup.type === "MULTIPLE_CHOICE") {
        const newValidationArray = {
          [question.text]: yup
            .array()
            .nullable()
            .required("At least one option is required"),
        };
        Object.assign(yupObjects.fields, newValidationArray);
      } else {
        const newValidationObject = {
          [question.text]: yup.string().required("Is required"),
        };
        Object.assign(yupObjects.fields, newValidationObject);
      }
      yupObjects._nodes.push(question.text);
    });

    setValidationSchema(yupObjects);
    setSlice(slice);
    setLoading(false);
  };

  useEffect(() => {
    surveyStore.createSnapshot(id).then(() => getSurvey());

    setCount(
      localStorage.getItem(`count for ${id}`)
        ? localStorage.getItem(`count for ${id}`)
        : 0
    );
  }, []);

  const handleNext = (values) => {
    setLoading(true);
    const selectedPage = count + 1;
    setCount(selectedPage);
    const getNewOffset = selectedPage * perPage;
    getQuestionsPerPage(getNewOffset, allQuestions);

    if (procent > 0) {
      let currentProcent = procent + procentAmount;
      setProcent(currentProcent);
      localStorage.setItem(`procent for ${id}`, currentProcent);
    }
    localStorage.setItem(`values for ${id}`, JSON.stringify(values));
    localStorage.setItem(`count for ${id}`, selectedPage);
    localStorage.setItem(`offset for ${id}`, getNewOffset);
  };

  const handlePrevious = () => {
    setLoading(true);
    const selectedPage = count - 1;
    setCount(selectedPage);
    const getNewOffset = selectedPage * perPage;
    getQuestionsPerPage(getNewOffset, allQuestions);
    //
    if (procent > 0) {
      let currentProcent = procent - procentAmount;
      setProcent(currentProcent);
      localStorage.setItem(`procent for ${id}`, currentProcent);
    }
    localStorage.setItem(`count for ${id}`, selectedPage);
    localStorage.setItem(`offset for ${id}`, getNewOffset);
  };

  const handleFinishSubmit = async (values) => {
    setLoading(true);

    const allAnswers = [];
    const snapshotId = localStorage.getItem(`snapshotId for ${id}`);

    await Object.keys(values).map(async (key) => {
      const test = await allQuestions.find((c) => c.text === key);
      let textAnswers = null;
      let multipleAnswers = null;
      let selectedAnswers = null;

      switch (test.answerGroup.type) {
        case "MULTIPLE_CHOICE":
          let newId = [];
          for (let i = 0; i < values[key].length; i++) {
            const answer = test.answerGroup.answers.find(
              (c) => c.text === values[key][i]
            );
            newId.push(answer.id);
          }
          multipleAnswers = newId;
          break;
        case "TEXT":
          textAnswers = values[key];
          break;
        default:
          let selectedAnswer = test.answerGroup.answers.find(
            (c) => c.text === values[key]
          );
          selectedAnswers = selectedAnswer.id;
          break;
      }

      allAnswers.push({
        questionId: test.id,
        snapshotId: snapshotId,
        textResponse: textAnswers,
        multipleSelectedAnswerIds: multipleAnswers,
        selectedAnswerId: selectedAnswers,
      });
    });

    surveyStore.saveTheAnswers(allAnswers).then(() => {
      reportsStore
        .updateSnapshot(snapshotId, { completed: true })
        .then(history.push(ROUTES.results))
        .then(setLoading(false));
      localStorage.removeItem(`values for ${id}`);
      localStorage.removeItem(`snapshotId for ${id}`);
      localStorage.removeItem(`count for ${id}`);
      localStorage.removeItem(`offset for ${id}`);
      localStorage.removeItem(`procent for ${id}`);
    });
  };

  return getSlice ? (
    <Container>
      <Formik
        initialValues={
          localStorage.getItem(`values for ${id}`)
            ? JSON.parse(localStorage.getItem(`values for ${id}`))
            : {}
        }
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          Number(count) + 1 === totalPages
            ? await handleFinishSubmit(values)
            : handleNext(values);
          setSubmitting(false);
        }}
      >
        {({ errors, isSubmitting }) => (
          <Form>
            {getSlice.map((question, key) => {
              switch (question.answerGroup.type) {
                case "MULTIPLE_CHOICE":
                  return (
                    <div key={key} className="mt-4">
                      <BootstrapForm.Label>
                        {Number(allQuestions.indexOf(question) + 1)}.{" "}
                        {question.text}
                      </BootstrapForm.Label>
                      {errors[question.text] && (
                        <div className="text-danger">
                          {errors[question.text]}
                        </div>
                      )}
                      <BootstrapForm.Text className="text-muted">
                        {question.tooltip}
                      </BootstrapForm.Text>
                      <div className="row row-cols-2">
                        {question.answerGroup.answers.map((answer, key02) => {
                          return (
                            <div key={key02} className="col">
                              <div className="p-3 mb-3">
                                <Field
                                  type="checkbox"
                                  name={question.text}
                                  value={answer.text}
                                  label={answer.text}
                                  id={answer.id}
                                  component={CheckFormField}
                                  className="takeSurveyCheck"
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                case "SINGLE_CHOICE":
                  const options = [];
                  question.answerGroup.answers.forEach((answer) => {
                    const item = { label: answer.text, value: answer.text };
                    options.push(item);
                  });
                  return (
                    <div key={key} className="mt-4">
                      <Field
                        label={`${Number(
                          allQuestions.indexOf(question) + 1
                        )}. ${question.text}`}
                        description={question.tooltip}
                        name={question.text}
                        options={options}
                        component={SelectFormField}
                      />
                    </div>
                  );
                case "TEXT":
                  return (
                    <div key={key} className="mt-4">
                      <Field
                        label={`${Number(
                          allQuestions.indexOf(question) + 1
                        )}. ${question.text}`}
                        description={question.tooltip}
                        name={question.text}
                        component={TextAreaFormField}
                      />
                    </div>
                  );
                case "YES_NO":
                  return (
                    <div key={key} className="mt-4">
                      <BootstrapForm.Label>
                        {Number(allQuestions.indexOf(question) + 1)}.{" "}
                        {question.text}
                      </BootstrapForm.Label>
                      {errors[question.text] && (
                        <div className="small text-danger">
                          {errors[question.text]}
                        </div>
                      )}
                      <BootstrapForm.Text className="text-muted">
                        {question.tooltip}
                      </BootstrapForm.Text>
                      {question.answerGroup.answers.map((answer, key02) => {
                        return (
                          <div
                            className="custom-control-inline p-3"
                            key={key02}
                          >
                            <Field
                              type="radio"
                              name={question.text}
                              value={answer.text}
                              label={answer.text}
                              id={`${question.id} + ${answer.id}`}
                              component={CheckFormField}
                              className="takeSurveyRadio"
                            />
                          </div>
                        );
                      })}
                    </div>
                  );
                default:
              }
            })}
            <Row className="mt-5 mx-auto shadow-sm page-section rounded-lg">
              <Col md="auto">
                <Button
                  disabled={isSubmitting}
                  variant="link"
                  onClick={handlePrevious}
                  className={Number(count) + 1 === 1 ? "d-none" : "d-inline"}
                >
                  {loading && (
                    <Spinner
                      className="mr-2 mb-1"
                      as="span"
                      variant="light"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  {t("previous")}
                </Button>
              </Col>
              <Col className="align-self-center">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>
                      {t("page")}
                      {` ${Number(count) + 1} / ${totalPages}`}
                    </Tooltip>
                  }
                >
                  <ProgressBar
                    variant="primary"
                    now={Number(count) + 1 === totalPages ? "100" : procent}
                    className="process-bar"
                  />
                </OverlayTrigger>
              </Col>
              <Col md="auto">
                <Button disabled={isSubmitting} variant="primary" type="submit">
                  {loading && (
                    <Spinner
                      className="mr-2 mb-1"
                      as="span"
                      variant="light"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  {t(Number(count) + 1 === totalPages ? "finish" : "next")}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  ) : (
    <PageLoader />
  );
};

export default inject(`surveyStore`, `reportsStore`)(observer(TakeSurveys));
