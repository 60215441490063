import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import { ROUTES } from "../../../constants";
import { inject } from "mobx-react";

import forgotPasswordFormStyles from "./ForgotPasswordForm.module.scss";
import { Button, Spinner } from "react-bootstrap";

import * as yup from "yup";
import { Formik, Form, Field } from "formik";
import InputFormField from "../../fields/InputFormField";

const schema = yup.object({
  email: yup.string().email().required("Please fill in your account email"),
});

const ForgotPasswordForm = ({ authStore, history }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    setLoading(true);
    authStore
      .requestPasswordReset(e.email)
      .then(() => {
        history.push(ROUTES.succesForgot);
      })
      .then(() => setLoading(false));
  };

  return (
    <Formik
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{ email: "" }}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        await handleSubmit(values);
        setSubmitting(false);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className="mt-3">
            <Field
              label={t("login-screen.email")}
              name="email"
              placeholder={t("login-screen.email-placeholder")}
              type="email"
              component={InputFormField}
            />
          </div>
          <div className="mt-3 small text-right">
            <p className="text-muted">
              {" "}
              {t("forgotten-password-screen.remember")}{" "}
              <Link
                to={ROUTES.login}
                className={forgotPasswordFormStyles.forgotSpan}
              >
                {t("login-screen.title")}
              </Link>
            </p>
          </div>
          <Button
            variant="primary"
            disabled={isSubmitting}
            className="mt-4"
            type="submit"
            block
          >
            {loading && (
              <Spinner
                className="mr-2 mb-1"
                as="span"
                variant="light"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            {t("forgotten-password-screen.cta")}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default inject(`authStore`)(withRouter(ForgotPasswordForm));
