import React, { useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { Modal, Button, Form } from "react-bootstrap";

const NewTaskModal = ({ shown, close, handleCreate }) => {
  const { t } = useTranslation();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const handleChange = (event, setter) => {
    setter(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    handleCreate({
      title,
      description,
    });

    close();
  };

  return (
    <section
      id="add-kanban-card"
      className={classNames({ "d-none": !shown, "modal-wrapper": true })}
    >
      <Modal.Dialog>
        <Modal.Header closeButton onHide={close}>
          <Modal.Title>{t("kanban-screen.new-modal.modal-title")}</Modal.Title>
        </Modal.Header>

        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Form.Group>
              <Form.Label>
                {t("kanban-screen.new-modal.field-title")}
              </Form.Label>
              <Form.Control
                required
                type="text"
                onChange={(e) => handleChange(e, setTitle)}
                placeholder={t("kanban-screen.none-modal.title")}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                {t("kanban-screen.new-modal.field-description")}
              </Form.Label>
              <Form.Control
                as="textarea"
                rows="3"
                required
                onChange={(e) => handleChange(e, setDescription)}
                placeholder={t("kanban-screen.none-modal.desc")}
              />
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button onClick={close} variant="link">
              {t("cancel")}
            </Button>
            <Button type="submit" variant="primary">
              {t("save")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Dialog>
    </section>
  );
};

export default NewTaskModal;
