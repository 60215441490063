import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";

import { Container, Table, Form, Button } from "react-bootstrap";

import PageSection from "../../components/PageSection";

const GroupCategories = ({ surveyAdminStore, groupCategories, close }) => {
  const { t } = useTranslation();
  const [select, setSelect] = useState("");
  const [phase, setPhase] = useState(null);

  useEffect(() => {
    surveyAdminStore
      .setPhaseCategories()
      .then((e) => setPhase(e))
      .catch((e) => alert(e.message));
  }, []);

  const filterPhase =
    groupCategories &&
    groupCategories.filter((u) => {
      return (
        u.phaseCategory &&
        u.phaseCategory.name.toLowerCase().includes(select.toLowerCase())
      );
    });

  const handleDelete = (groupCategoryId) => {
    surveyAdminStore
      .deleteGroupCategory(groupCategoryId)
      .then(() => close())
      .catch((e) => alert(e.message));
  };

  return (
    <Container className="pt-4">
      <PageSection title={t("questions-survey.filters-title")} className="p-2">
        <div className="row cols-9">
          <div className="col">
            <Form.Label>{t("group-cat-survey.title")}</Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => setSelect(e.target.value)}
              id="search"
            >
              <option value="">{t("management-survey.all")}</option>
              {phase &&
                phase.map((a, key) => <option key={key}>{a.name}</option>)}
            </Form.Control>
          </div>
        </div>
      </PageSection>
      <div className="text-right">
        <Link to={`/surveys/group-categories/new`}>
          {t("group-cat-survey.cta")}
        </Link>
      </div>

      <PageSection title={t("group-cat-survey.all-title")} className="p-2">
        <Table className="mb-0" responsive>
          <thead>
            <tr>
              <th>{t("group-cat-survey.labels.name")}</th>
              <th>{t("group-cat-survey.labels.phase")}</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filterPhase &&
              filterPhase.map((group, key) => (
                <tr key={key}>
                  <td>
                    <div>{group.name}</div>
                  </td>
                  <td>
                    <div>{group.phaseCategory && group.phaseCategory.name}</div>
                  </td>
                  <td>
                    <Link to={`/surveys/group-categories/${group.id}`}>
                      {t("edit")}
                    </Link>
                  </td>
                  <td className="text-right">
                    <Button
                      variant="link"
                      className="p-0 text-danger"
                      onClick={() => {
                        window.confirm(t("sure")) && handleDelete(group.id);
                      }}
                    >
                      {t("delete")}
                    </Button>
                    {/* <p className="text-muted">Can't be deleted</p> */}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </PageSection>
    </Container>
  );
};

export default inject(`surveyAdminStore`)(observer(GroupCategories));
