import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Modal, Spinner, Button } from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";

import BootstrapForm from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { PERMISSIONS } from "../../../constants/index";

import * as yup from "yup";
import { Formik, Form, Field } from "formik";
import InputFormField from "../../../components/fields/InputFormField";
import CheckFormField from "../../../components/fields/CheckFormField";
import SelectFormField from "../../../components/fields/SelectFormField";

const schema = yup.object({
  firstName: yup.string().required("Please fill in a first name"),
  email: yup.string().email().required("Please fill in the Startup email"),
  password: yup.string(),
  confirmedPassword: yup.string().when("password", {
    is: (val) => (val && val.length > 0 ? true : false),
    then: yup
      .string()
      .oneOf([yup.ref("password")], "Both password need to be the same"),
  }),
});

const UserDetailModal = ({
  userStore,
  id,
  startupStore,
  organizationStore,
  authStore,
  close,
}) => {
  const { t } = useTranslation();
  const [user, setUser] = useState(null);
  const [userValues, setUserValues] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [archiving, setArchiving] = useState(false);
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const [startupOptions, setStartupOptions] = useState([]);
  const [rolesOptions, setRolesOptions] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const hasPermission = (permission) => {
    return (
      authStore.user && authStore.user.permissions.find((p) => p === permission)
    );
  };

  useEffect(() => {
    userStore
      .getById(id)
      .then((user) => {
        const oldValues = {
          firstName: user.firstName && user.firstName,
          lastName: user.lastName && user.lastName,
          email: user.email && user.email,
          enabled: user.enabled && user.enabled,
          startupUuid: user.startup === null ? "none" : user.startup.uuid,
          organizationUuid:
            user.organization === null ? "none" : user.organization.uuid,
          role: user.roleId && user.roleId,
          password: "",
          confirmedPassword: "",
        };
        setUserValues(oldValues);
        setUser(user);
      })
      .catch((e) => alert(e.message));

    startupStore
      .setStartups()
      .then(getStartupsOptions)
      .catch((e) => alert(e.message));
    userStore
      .setRoles()
      .then(getRolesOptions)
      .catch((e) => alert(e.message));
    organizationStore
      .setOrganizations()
      .then(getOrganizationOptions)
      .catch((e) => alert(e.message));
  }, []);

  const getStartupsOptions = () => {
    const startupOptions = [{ label: "none", value: "none" }];
    startupStore.startups &&
      startupStore.startups.map((u) => {
        startupOptions.push({ label: u.name, value: u.uuid });
      });
    setStartupOptions(startupOptions);
  };

  const getOrganizationOptions = () => {
    const organizationOptions = [{ label: "none", value: "none" }];
    organizationStore.organizations &&
      organizationStore.organizations.map((u) => {
        organizationOptions.push({ label: u.name, value: u.uuid });
      });
    setOrganizationOptions(organizationOptions);
  };

  const getRolesOptions = () => {
    const roleOptions = [];
    userStore.roles &&
      userStore.roles.map((u) => {
        roleOptions.push({ label: u.name, value: u.id });
      });
    setRolesOptions(roleOptions);
  };

  const handleDeleteUser = () => {
    setArchiving(true);

    userStore
      .deleteUser(id)
      .then(setArchiving(false))
      .then(() => close());
  };

  return (
    <section className="modal-wrapper">
      <Modal.Dialog size="lg">
        {userValues ? (
          <Formik
            validationSchema={schema}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={userValues}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              setUpdating(true);
              let startupValue;
              if (
                values.startupUuid === "none" ||
                values.startupUuid === undefined
              ) {
                startupValue = null;
              } else {
                startupValue = values.startupUuid;
              }

              let organizationValue;
              if (
                values.organizationUuid === "none" ||
                values.organizationUuid === undefined
              ) {
                organizationValue = null;
              } else {
                organizationValue = values.organizationUuid;
              }

              const newValues = {
                email: values.email,
                firstName: values.firstName,
                lastName: values.lastName,
                isEnabled: values.enabled,
                startupUuid: startupValue,
                organizationUuid: organizationValue,
                roleId: Number(values.role),
                password: values.password,
              };

              userStore.updateUser(user.uuid, newValues).then(() => close());
              setUpdating(false);
              setSubmitting(false);
            }}
          >
            {({ values, isSubmitting }) => (
              <Form>
                <Modal.Header closeButton onHide={() => close()}>
                  <Modal.Title>{user && user.name}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <div className="row row-cols-2 mb-3">
                    <div className="col">
                      <Field
                        label={t("user-screen.new-modal.label-firstName")}
                        name="firstName"
                        placeholder="Jane"
                        component={InputFormField}
                      />
                    </div>
                    <div className="col">
                      <Field
                        label={t("user-screen.new-modal.label-lastName")}
                        name="lastName"
                        placeholder="Doe"
                        component={InputFormField}
                      />
                    </div>
                  </div>
                  <div className="row row-cols-2 mb-3">
                    <div className="col">
                      <Field
                        label={t("startup-admin-screen.new-modal.field-email")}
                        name="email"
                        placeholder={t(
                          "startup-admin-screen.new-modal.field-email-placeholder"
                        )}
                        component={InputFormField}
                      />
                    </div>
                    <div className="col">
                      <div class="input-group mb-3">
                        <Field
                          name="password"
                          label={t("user-screen.new-modal.label-password")}
                          type={showPassword ? "text" : "password"}
                          component={InputFormField}
                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-link"
                            type="button"
                            id="button-addon2"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? (
                              <svg
                                width="1em"
                                height="1em"
                                viewBox="0 0 16 16"
                                class="bi bi-eye"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.134 13.134 0 0 0 1.66 2.043C4.12 11.332 5.88 12.5 8 12.5c2.12 0 3.879-1.168 5.168-2.457A13.134 13.134 0 0 0 14.828 8a13.133 13.133 0 0 0-1.66-2.043C11.879 4.668 10.119 3.5 8 3.5c-2.12 0-3.879 1.168-5.168 2.457A13.133 13.133 0 0 0 1.172 8z"
                                />
                                <path
                                  fill-rule="evenodd"
                                  d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                                />
                              </svg>
                            ) : (
                              <svg
                                width="1em"
                                height="1em"
                                viewBox="0 0 16 16"
                                class="bi bi-eye-slash"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                                <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299l.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                                <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709z" />
                                <path
                                  fill-rule="evenodd"
                                  d="M13.646 14.354l-12-12 .708-.708 12 12-.708.708z"
                                />
                              </svg>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row row-cols-2">
                    <div className="col"></div>
                    <div className="col">
                      <div class="input-group mb-3">
                        <Field
                          name="confirmedPassword"
                          label={t(
                            "profile-screen.reset-password.confirm-label"
                          )}
                          type={showPassword1 ? "text" : "password"}
                          component={InputFormField}
                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-link"
                            type="button"
                            id="button-addon2"
                            onClick={() => setShowPassword1(!showPassword1)}
                          >
                            {showPassword1 ? (
                              <svg
                                width="1em"
                                height="1em"
                                viewBox="0 0 16 16"
                                class="bi bi-eye"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.134 13.134 0 0 0 1.66 2.043C4.12 11.332 5.88 12.5 8 12.5c2.12 0 3.879-1.168 5.168-2.457A13.134 13.134 0 0 0 14.828 8a13.133 13.133 0 0 0-1.66-2.043C11.879 4.668 10.119 3.5 8 3.5c-2.12 0-3.879 1.168-5.168 2.457A13.133 13.133 0 0 0 1.172 8z"
                                />
                                <path
                                  fill-rule="evenodd"
                                  d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                                />
                              </svg>
                            ) : (
                              <svg
                                width="1em"
                                height="1em"
                                viewBox="0 0 16 16"
                                class="bi bi-eye-slash"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                                <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299l.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                                <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709z" />
                                <path
                                  fill-rule="evenodd"
                                  d="M13.646 14.354l-12-12 .708-.708 12 12-.708.708z"
                                />
                              </svg>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row row-cols-2 mb-3">
                    {hasPermission(PERMISSIONS.USERS_LIST_ALL) ? (
                      <>
                        <div className="col">
                          <Field
                            label={t("user-screen.new-modal.label-role")}
                            name="role"
                            options={rolesOptions}
                            placeholder={t(
                              "startup-admin-screen.edit-modal.field-country-placeholder"
                            )}
                            component={SelectFormField}
                          />
                        </div>
                        {values.role === "2" || values.role === 2 ? (
                          <>
                            {(values.startupUuid = undefined)}
                            <div className="col">
                              <Field
                                label={t("user-screen.new-modal.label-org")}
                                name="organizationUuid"
                                options={organizationOptions}
                                placeholder={t(
                                  "startup-admin-screen.edit-modal.field-country-placeholder"
                                )}
                                component={SelectFormField}
                              />
                            </div>
                          </>
                        ) : values.role === "3" || values.role === 3 ? (
                          <>
                            {(values.organizationUuid = undefined)}
                            <div className="col">
                              <Field
                                label={t("user-screen.new-modal.label-startup")}
                                name="startupUuid"
                                options={startupOptions}
                                placeholder={t(
                                  "startup-admin-screen.edit-modal.field-country-placeholder"
                                )}
                                component={SelectFormField}
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            {(values.organizationUuid = undefined)}
                            {(values.startupUuid = undefined)}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="col">
                          <Field
                            label={t("user-screen.new-modal.label-role")}
                            name="role"
                            options={rolesOptions}
                            disabled
                            placeholder={t(
                              "startup-admin-screen.edit-modal.field-country-placeholder"
                            )}
                            component={SelectFormField}
                          />
                        </div>
                        {values.role === "2" || values.role === 2 ? (
                          <>
                            {(values.startupUuid = undefined)}
                            <div className="col">
                              <Field
                                label={t("user-screen.new-modal.label-org")}
                                name="organizationUuid"
                                options={organizationOptions}
                                disabled
                                placeholder={t(
                                  "startup-admin-screen.edit-modal.field-country-placeholder"
                                )}
                                component={SelectFormField}
                              />
                            </div>
                          </>
                        ) : values.role === "3" || values.role === 3 ? (
                          <>
                            {(values.organizationUuid = undefined)}
                            <div className="col">
                              <Field
                                label={t("user-screen.new-modal.label-startup")}
                                name="startupUuid"
                                options={startupOptions}
                                disabled
                                placeholder={t(
                                  "startup-admin-screen.edit-modal.field-country-placeholder"
                                )}
                                component={SelectFormField}
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            {(values.organizationUuid = undefined)}
                            {(values.startupUuid = undefined)}
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <div className="row row-cols-2 mb-3">
                    <div className="col" />
                    <div className="col mt-2">
                      <BootstrapForm.Label> </BootstrapForm.Label>
                      <div className="p-2 bg-light-green rounded-lg border">
                        <Field
                          type="checkbox"
                          name="enabled"
                          label={t("user-screen.new-modal.label-active")}
                          component={CheckFormField}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="text-right">
                    <div className=" text-muted">
                      {user && (
                        <Link to={`/users/${user.uuid}/permissions`}>
                          {t("user-screen.new-modal.advanced")}
                        </Link>
                      )}
                    </div>
                    <Button
                      onClick={() => {
                        window.confirm(t("sure")) && handleDeleteUser();
                      }}
                      variant="link"
                      className="text-danger p-0 btn-sm"
                    >
                      {archiving ? (
                        <Spinner
                          animation="border"
                          size="sm"
                          variant="danger"
                        />
                      ) : (
                        ""
                      )}
                      {t("delete")}
                    </Button>
                  </div>
                </Modal.Body>

                <Modal.Footer className="mt-4">
                  <Button onClick={() => close()} variant="link">
                    {t("cancel")}
                  </Button>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="primary"
                  >
                    {updating ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      t("save")
                    )}
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        ) : (
          <div className="d-flex justify-content-center align-items center p-5">
            <Spinner variant="primary" animation="border" />
          </div>
        )}
      </Modal.Dialog>
    </section>
  );
};

export default inject(
  "userStore",
  "startupStore",
  "organizationStore",
  "authStore"
)(withRouter(observer(UserDetailModal)));
