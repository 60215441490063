import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Spinner, Button, Alert } from "react-bootstrap";

import PageSection from "../../../../components/PageSection";

import * as yup from "yup";
import { Formik, Form, Field, FieldArray } from "formik";
import InputFormField from "../../../../components/fields/InputFormField";
import ReactSelectFormField from "../../../../components/fields/ReactSelectFormField";

const schema = yup.object({});

const SurveyQuestionsNew = ({ surveyStore, surveyAdminStore, id, close }) => {
  const [updating, setUpdating] = useState(false);
  const [archiving, setArchiving] = useState(false);
  const { t } = useTranslation();
  const [questionOptions, setQuestionOptions] = useState(null);

  useEffect(() => {
    surveyAdminStore
      .setAllQuestions()
      .then(getQuestionOptions)
      .catch((e) => alert(e.message));
  }, []);

  const getQuestionOptions = () => {
    const questionOptions = [];
    surveyAdminStore.allQuestions &&
      surveyAdminStore.allQuestions.map((u) => {
        questionOptions.push({ label: u.text, value: u.id });
      });
    setQuestionOptions(questionOptions);
  };

  return (
    <Container className="pt-4">
      <Formik
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{
          questions: "",
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          setUpdating(true);

          values.questions.map((o) => {
            const newSurveyQuestions = {
              page: o.page,
              position: o.position,
              question: Number(o.question),
              survey: Number(id),
            };
            surveyAdminStore
              .createSurveyQuestions(newSurveyQuestions)
              .then(() => close());
          });

          if (values.questions.length === 0) {
            close();
          }
          setUpdating(false);
          setSubmitting(false);
        }}
      >
        {({ values, isSubmitting }) => (
          <Form>
            <PageSection
              title={t("questions-survey.new.title")}
              className="p-2"
            >
              <div className="row">
                <div className="col-6">
                  {t("questions-survey.new.question")}
                </div>
                <div className="col-2">{t("questions-survey.new.page")}</div>
                <div className="col-2">{t("questions-survey.new.pos")}</div>
              </div>
              <FieldArray
                name="questions"
                render={(arrayHelpers) => (
                  <div>
                    {values.questions &&
                      values.questions.map((q, index) => (
                        <div key={index} className="row">
                          <div className="col-6 pt-4">
                            <Field
                              name={`questions.${index}.question`}
                              options={questionOptions}
                              onClick={(e) => console.log("chosen")}
                              component={ReactSelectFormField}
                            />
                          </div>
                          <div className="col-2">
                            <Field
                              type="number"
                              min="0"
                              name={`questions.${index}.page`}
                              component={InputFormField}
                            />
                          </div>
                          <div className="col-2">
                            <Field
                              type="number"
                              min="0"
                              name={`questions.${index}.position`}
                              component={InputFormField}
                            />
                          </div>
                          <div className="col pt-4">
                            <Button
                              variant="outline-secondary"
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              -
                            </Button>
                          </div>
                        </div>
                      ))}
                    <Button
                      variant="outline-primary"
                      className="mt-4 w-100"
                      onClick={() => arrayHelpers.push("")}
                    >
                      +
                    </Button>
                  </div>
                )}
              />
            </PageSection>
            <div className="text-right mb-3">
              <Button onClick={() => close()} variant="link">
                {t("cancel")}
              </Button>
              <Button type="submit" disabled={isSubmitting} variant="primary">
                {updating ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  t("save")
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default inject(
  "surveyStore",
  "surveyAdminStore"
)(withRouter(observer(SurveyQuestionsNew)));
