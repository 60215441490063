import React from "react";

import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const GrowthReportChart = ({ dataSnapshot1, dataSnapshot2 }) => {
  return (
    <ResponsiveContainer aspect={16 / 14}>
      <LineChart margin={{ top: 10, right: 15, bottom: 5, left: 15 }}>
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <YAxis dataKey="value" interval={1} width={20} />
        <XAxis
          interval={0}
          angle={-35}
          textAnchor="end"
          dataKey="category"
          type="category"
          allowDuplicatedCategory={false}
          allowDataOverflow={true}
          height={300}
        />
        <Tooltip />
        <Legend verticalAlign="top" height={45} />
        {[dataSnapshot1, dataSnapshot2].map((snapshots, key) => {
          const data = [];

          snapshots.map((line) => {
            data.push({ category: line.phase, value: line.positiveKPIs });
          });

          return (
            <Line
              dataKey="value"
              data={data}
              name={key === 0 ? "Momentopname 1" : "Momentopname 2"}
              key={Math.floor(Math.random() * Math.floor(100))}
              stroke={`#${key === 0 ? "27A3CA" : "8DC53C"}`}
            />
          );
        })}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default GrowthReportChart;
