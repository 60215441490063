import React from "react";
import Form from "react-bootstrap/Form";

const InputFormField = ({ field, form: { errors }, ...props }) => {
  return (
    <div>
      <Form.Label>{props.label}</Form.Label>
      <Form.Text className="text-muted">{props.description}</Form.Text>
      <Form.Control {...field} {...props} />
      {errors[field.name] && (
        <div className="small text-danger">{errors[field.name]}</div>
      )}
    </div>
  );
};

export default InputFormField;
