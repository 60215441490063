import React from "react";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";

const SelectFormField = ({ field, form: { errors }, options, ...props }) => {
  const { t } = useTranslation();
  return (
    <div>
      <Form.Label>{props.label}</Form.Label>
      {errors[field.name] && (
        <div className="small text-danger">{errors[field.name]}</div>
      )}
      <Form.Text className="text-muted">{props.description}</Form.Text>
      <Form.Control
        as="select"
        {...field}
        {...props}
        className="custom-select"
        // defaultValue="Choose an option..."
      >
        <option hidden>{t("placeholderSelectOnly")}</option>
        {options.map((op) => (
          <option key={op.value} value={op.value}>
            {op.label}
          </option>
        ))}
      </Form.Control>
    </div>
  );
};

export default SelectFormField;
