import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Spinner, Button, Alert } from "react-bootstrap";

import PageSection from "../../../../components/PageSection";

import * as yup from "yup";
import { Formik, Form, Field, FieldArray } from "formik";
import InputFormField from "../../../../components/fields/InputFormField";
import SelectFormField from "../../../../components/fields/SelectFormField";

const schema = yup.object({
  identifier: yup
    .string()
    .required("Please fill in the answer group identifier"),
  name: yup.string().required("Please fill in the answer group name"),
});

const AnswerEdit = ({ surveyAdminStore, id, close }) => {
  const [updating, setUpdating] = useState(false);
  const [archiving, setArchiving] = useState(false);
  const [answer, setAnswer] = useState(null);
  const [answerOptions, setAnswerOptions] = useState(null);
  const { t } = useTranslation();
  const answerGroupTypeOptions = [
    { label: "MULTIPLE_CHOICE", value: "MULTIPLE_CHOICE" },
    { label: "SINGLE_CHOICE", value: "SINGLE_CHOICE" },
  ];

  useEffect(() => {
    surveyAdminStore
      .getAnswerById(id)
      .then((answer) => setAnswer(answer))
      .catch((e) => alert(e.message));
    surveyAdminStore
      .setAnswersOptions()
      .then((options) => setAnswerOptions(options))
      .catch((e) => {
        alert(e.message);
      });
  }, []);

  const filteredAnswerOptions =
    answerOptions &&
    answerOptions.filter((f) => {
      return f.groupId === Number(id);
    });

  const handleDeleteAnswerOption = (optionId) => {
    setArchiving(true);
    surveyAdminStore.deleteAnswerOption(optionId).then(setArchiving(false));
    setArchiving(false);
  };

  return (
    <Container className="pt-4">
      {answer && filteredAnswerOptions ? (
        <Formik
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            identifier: answer.identifier,
            name: answer.name,
            type: answer.type,
            options: filteredAnswerOptions,
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            setUpdating(true);
            const answerGroupValues = {
              identifier: values.identifier,
              name: values.name,
              type: values.type,
            };
            surveyAdminStore.updateAnswerGroup(id, answerGroupValues);

            values.options.map((o) => {
              const newAnswerOption = {
                identifier: o.identifier,
                text: o.text,
                groupId: id,
              };
              if (filteredAnswerOptions.includes(o)) {
                surveyAdminStore
                  .updateAnswersOptions(o.id, newAnswerOption)
                  .then(() => close());
              } else {
                surveyAdminStore
                  .createAnswersOptions(newAnswerOption)
                  .then(() => close());
              }
            });

            if (values.options.length === 0) {
              close();
            }

            setUpdating(false);
            setSubmitting(false);
          }}
        >
          {({ values, isSubmitting }) => (
            <Form>
              <PageSection
                title={answer && answer.name + " " + "Details"}
                className="p-2"
              >
                <Field
                  label={t("answers-survey.labels.id")}
                  name="identifier"
                  component={InputFormField}
                />
                <Field
                  label={t("answers-survey.labels.name")}
                  name="name"
                  component={InputFormField}
                />
                <Field
                  label={t("answers-survey.labels.type")}
                  options={answerGroupTypeOptions}
                  name="type"
                  component={SelectFormField}
                />
              </PageSection>

              <PageSection
                title={
                  answer && answer.name + " " + t("answers-survey.titleAo")
                }
                className="p-2"
              >
                <div className="row">
                  <div className="col">{t("answers-survey.labels.id")}</div>
                  <div className="col">{t("answers-survey.labels.name")}</div>
                  <div className="col"></div>
                </div>
                <FieldArray
                  name="options"
                  render={(arrayHelpers) => (
                    <div>
                      {values.options &&
                        values.options.map((option, index) => (
                          <div key={index} className="row">
                            <div className="col">
                              <Field
                                name={`options.${index}.identifier`}
                                component={InputFormField}
                              />
                            </div>
                            <div className="col">
                              <Field
                                name={`options.${index}.text`}
                                component={InputFormField}
                              />
                            </div>
                            <div className="col pt-4 ">
                              <Button
                                variant="outline-secondary"
                                onClick={() => {
                                  arrayHelpers.remove(index);
                                  handleDeleteAnswerOption(
                                    values.options[index].id
                                  );
                                }}
                              >
                                -
                              </Button>
                            </div>
                          </div>
                        ))}
                      <Button
                        variant="outline-primary"
                        className="mt-4 w-100"
                        onClick={() => arrayHelpers.push("")}
                      >
                        +
                      </Button>
                    </div>
                  )}
                />
              </PageSection>
              <div className="text-right mb-3">
                <Button onClick={() => close()} variant="link">
                  {t("cancel")}
                </Button>
                <Button type="submit" disabled={isSubmitting} variant="primary">
                  {updating ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    t("save")
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <div className="d-flex justify-content-center align-items center p-5">
          <Spinner variant="primary" animation="border" />
        </div>
      )}
    </Container>
  );
};

export default inject("surveyAdminStore")(withRouter(observer(AnswerEdit)));
