import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  responseType: "json",
});

api.defaults.headers.common["Content-Type"] = "application/json";

class ApiError extends Error {
  identifier = null;
  messages = null;

  constructor(identifier, message, messages) {
    super(message);
    this.identifier = identifier;
    this.messages = message;
  }
}

const successHandler = (_response) => {
  return _response.data.data;
};

const errorHandler = (_error) => {
  if (_error.response) {
    const { error } = _error.response.data;

    throw new ApiError(
      error.identifier || "unknown_error",
      error.title || "An unknown error occurred.",
      error.messages || []
    );
  }
  throw _error;
};

const reponseHandlers = [successHandler, errorHandler];

export default api;
export { reponseHandlers, ApiError };
