import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Modal, Spinner, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

import * as yup from "yup";
import { Formik, Form, Field } from "formik";
import InputFormField from "../../../../components/fields/InputFormField";
import CheckFormField from "../../../../components/fields/CheckFormField";
import SelectFormField from "../../../../components/fields/SelectFormField";

const schema = yup.object({
  text: yup.string().required("Please fill in a question"),
  answerGroupId: yup.string().required("Please select an answer"),
});

const QuestionNew = ({ surveyAdminStore, close }) => {
  const { t } = useTranslation();
  const [updating, setUpdating] = useState(false);
  const [groupOptions, setGroupOptions] = useState([]);
  const [answerOptions, setAnswerOptions] = useState([]);

  useEffect(() => {
    surveyAdminStore
      .setGroupCategories()
      .then(getGroupOptions)
      .catch((e) => alert(e.message));
    surveyAdminStore
      .setAnswers()
      .then(getAnswers)
      .catch((e) => alert(e.message));
  }, []);

  const getGroupOptions = () => {
    const groupOptions = [];
    surveyAdminStore.groupCategories &&
      surveyAdminStore.groupCategories.map((u) => {
        groupOptions.push({ label: u.name, value: u.id });
      });
    setGroupOptions(groupOptions);
  };

  const getAnswers = () => {
    const answers = [];
    surveyAdminStore.answers &&
      surveyAdminStore.answers.map((u) => {
        answers.push({ label: u.name, value: u.id });
      });
    setAnswerOptions(answers);
  };

  return (
    <section className="modal-wrapper">
      <Modal.Dialog size="lg">
        <Formik
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            text: "",
            tooltip: "",
            kpi: "",
            algo: "",
            filter: "",
            hasother: "",
            answerGroupId: "",
            groupCategoryId: "",
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            setUpdating(true);
            const newValues = {
              text: values.text,
              tooltip: values.tooltip,
              kpi: values.kpi,
              hasOther: values.hasother,
              answerGroup: values.answerGroupId,
              groupCategory: values.groupCategoryId,
              classification: "UNIVERSAL",
            };
            surveyAdminStore
              .createAllQuestions(newValues)
              .then(() => close())
              .catch((e) => alert(e.message));
            setUpdating(false);
            setSubmitting(false);
          }}
        >
          {({ values, isSubmitting }) => (
            <Form>
              <Modal.Header closeButton onHide={() => close()}>
                <Modal.Title>{t("questions-survey.edit.title")}</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <div className="row mb-3">
                  <div className="col">
                    <Field
                      label={t("questions-survey.edit.label-question")}
                      name="text"
                      placeholder="Question text"
                      component={InputFormField}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <Field
                      label={t("questions-survey.edit.label-tooltip")}
                      name="tooltip"
                      component={InputFormField}
                    />
                  </div>
                </div>
                <div className="row row-cols-4 mb-3">
                  <div className="col">
                    <Field
                      label={t("questions-survey.edit.label-kpi")}
                      name="kpi"
                      component={CheckFormField}
                    />
                  </div>
                  <div className="col">
                    <Field
                      label={t("questions-survey.edit.label-algo")}
                      name="algo"
                      component={CheckFormField}
                    />
                  </div>
                  <div className="col">
                    <Field
                      label={t("questions-survey.edit.label-filter")}
                      name="filter"
                      component={CheckFormField}
                    />
                  </div>
                  <div className="col">
                    <Field
                      label={t("questions-survey.edit.label-other")}
                      name="hasother"
                      component={CheckFormField}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <Field
                      label={t("questions-survey.edit.label-answer")}
                      name="answerGroupId"
                      options={answerOptions}
                      component={SelectFormField}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <Field
                      label={t("questions-survey.edit.label-group")}
                      name="groupCategoryId"
                      options={groupOptions}
                      component={SelectFormField}
                    />
                  </div>
                </div>
              </Modal.Body>

              <Modal.Footer className="mt-4">
                <Button onClick={() => close()} variant="link">
                  {t("cancel")}
                </Button>
                <Button type="submit" disabled={isSubmitting} variant="primary">
                  {updating ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    t("save")
                  )}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal.Dialog>
    </section>
  );
};

export default inject("surveyAdminStore")(withRouter(observer(QuestionNew)));
