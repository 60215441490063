import React, { useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import { useTranslation } from "react-i18next";
import { Switch } from "react-router-dom";
import { ROUTES, PERMISSIONS } from "../../constants/index";
import SecondaryMenu from "../../components/nav/SecondaryMenu";
import ProtectedRoute from "../../components/ProtectedRoute";

import SurveyDetail from "./components/SurveyDetail";
import Management from "./Management";
import Questions from "./Questions";
import PhaseCategories from "./PhaseCategories";
import GroupCategories from "./GroupCategories";

import { withRouter } from "react-router-dom";
import { observer, inject } from "mobx-react";
import SurveyEdit from "./components/admin/SurveyEdit";
import SurveyNew from "./components/admin/SurveyNew";
import SurveyQuestionsNew from "./components/admin/SurveyQuestionsNew";
import AnswerNew from "./components/admin/AnswerNew";
import AnswerGAnswersNew from "./components/admin/AnswerGAnswersNew";
import AnswerEdit from "./components/admin/AnswerEdit";
import GroupNew from "./components/admin/GroupNew";
import GroupEdit from "./components/admin/GroupEdit";
import PhaseNew from "./components/admin/PhaseNew";
import PhaseEdit from "./components/admin/PhaseEdit";
import QuestionsNew from "./components/admin/QuestionsNew";
import QuestionsEdit from "./components/admin/QuestionsEdit";
import KpiAdjustments from "./components/admin/KpiAdjustments";
import Answers from "./Answers";

const StartSurvey = ({
  authStore,
  surveyStore,
  textStore,
  history,
  surveyAdminStore,
}) => {
  const { t } = useTranslation();
  const hasPermission = (permission) => {
    return (
      authStore.user && authStore.user.permissions.find((p) => p === permission)
    );
  };

  const [surveys, setSurveys] = useState(null);
  const [currentSurvey, setCurrentSurvey] = useState(null);
  const [answers, setAnswers] = useState(null);
  const [groupCategories, setGroupCategories] = useState(null);
  const [phaseCategories, setPhaseCategories] = useState(null);
  const [questions, setQuestions] = useState(null);

  const setTheCurrentSurvey = () => {
    const survey =
      authStore.user && authStore.user.startup && authStore.user.startup.survey;
    setCurrentSurvey(survey);
  };

  const setTheSurveys = () => {
    surveyStore
      .setSurveys()
      .then((survey) => setSurveys(survey))
      .catch((e) => {
        alert(e.message);
      });
  };

  const setTheAnswers = () => {
    surveyAdminStore
      .setAnswers()
      .then((answer) => setAnswers(answer))
      .catch((e) => {
        alert(e.message);
      });
  };

  const setTheGroupCategories = () => {
    surveyAdminStore
      .setGroupCategories()
      .then((group) => setGroupCategories(group))
      .catch((e) => {
        alert(e.message);
      });
  };

  const setThePhaseCategories = () => {
    surveyAdminStore
      .setPhaseCategories()
      .then((phase) => setPhaseCategories(phase))
      .catch((e) => {
        alert(e.message);
      });
  };

  const setTheQuestions = () => {
    surveyAdminStore
      .setAllQuestions()
      .then((q) => setQuestions(q))
      .catch((e) => {
        alert(e.message);
      });
  };

  useEffect(() => {
    setTheCurrentSurvey();
    setTheSurveys();
    setTheAnswers();
    setTheGroupCategories();
    setThePhaseCategories();
    setTheQuestions();
    textStore.setTexts().then(getText);
  }, []);

  const getText = textStore.texts.find((p) => p.page === "SURVEYS");

  return (
    <>
      <PageHeader
        title={t("menu-items.surveys")}
        explainer={getText && getText.text}
        link={getText && getText.link}
        actions={
          <>
            {hasPermission(PERMISSIONS.SURVEYS_TAKE_DREAM_STARTUP) && (
              <SecondaryMenu
                items={[
                  {
                    path: "/surveys/dream",
                    title: t("menu-items.under-menu.current-checkup"),
                  },
                  // { path: "/surveys/team", title: "Team Surveys" },
                ]}
              />
            )}
            {hasPermission(PERMISSIONS.SURVEYS_LIST_ALL) &&
              !hasPermission(PERMISSIONS.SURVEYS_DELETE_ALL) && (
                <SecondaryMenu
                  items={[
                    {
                      path: "/surveys/management",
                      title: t("menu-items.under-menu.management"),
                    },
                  ]}
                />
              )}
            {hasPermission(PERMISSIONS.SURVEYS_DELETE_ALL) && (
              <SecondaryMenu
                items={[
                  {
                    path: "/surveys/management",
                    title: t("menu-items.under-menu.management"),
                  },
                  {
                    path: "/surveys/questions",
                    title: t("menu-items.under-menu.questions"),
                  },
                  {
                    path: "/surveys/answer-groups",
                    title: t("menu-items.under-menu.answer-groups"),
                  },
                  {
                    path: "/surveys/group-categories",
                    title: t("menu-items.under-menu.group-cat"),
                  },
                  {
                    path: "/surveys/phase-categories",
                    title: t("menu-items.under-menu.phase-cat"),
                  },
                ]}
              />
            )}
          </>
        }
        textLink={`/s/text/7`}
        append
      />
      <Switch>
        {/* <ProtectedRoute
          embedded
          startup
          path={ROUTES.teamSurveys}
          exact
          strict
          component={() => <SurveyDetail survey={currentSurvey && currentSurvey} type={currentSurvey && currentSurvey.type} />}
        /> */}
        <ProtectedRoute
          embedded
          startup
          path={ROUTES.dreamSurveys}
          exact
          strict
          component={() => (
            <SurveyDetail
              survey={currentSurvey && currentSurvey}
              type={currentSurvey && currentSurvey.type}
            />
          )}
        />

        <ProtectedRoute
          embedded
          path="/surveys/management"
          exact
          component={() => (
            <Management
              surveys={surveys}
              close={async () => {
                await history.push("/surveys/management");
                console.log("fk");
                setTheSurveys();
              }}
            />
          )}
        />
        <ProtectedRoute
          embedded
          path="/surveys/questions"
          exact
          component={() => <Questions questions={questions} />}
        />
        <ProtectedRoute
          embedded
          path="/surveys/answer-groups"
          exact
          component={() => (
            <Answers
              answers={answers}
              close={async () => {
                await history.push("/surveys/answer-groups");
                setTheAnswers();
              }}
            />
          )}
        />
        <ProtectedRoute
          embedded
          path="/surveys/group-categories"
          exact
          component={() => (
            <GroupCategories
              groupCategories={groupCategories}
              close={async () => {
                await history.push("/surveys/group-categories");
                setTheGroupCategories();
              }}
            />
          )}
        />
        <ProtectedRoute
          embedded
          path="/surveys/phase-categories"
          exact
          component={() => (
            <PhaseCategories
              phaseCategories={phaseCategories}
              close={async () => {
                await history.push("/surveys/phase-categories");
                setThePhaseCategories();
              }}
            />
          )}
        />

        <ProtectedRoute
          embedded
          path={`/surveys/management/new`}
          exact
          strict
          component={() => (
            <SurveyNew
              close={async () => {
                await history.push("/surveys/management");
                setTheSurveys();
              }}
            />
          )}
        />

        <ProtectedRoute
          embedded
          path={`/surveys/management/new-questions/:id`}
          exact
          strict
          component={({ match }) => (
            <SurveyQuestionsNew
              id={match.params.id}
              close={async () => {
                await history.push("/surveys/management");
                setTheSurveys();
              }}
            />
          )}
        />

        <ProtectedRoute
          embedded
          path={`/surveys/management/:id`}
          exact
          strict
          component={({ match }) => (
            <SurveyEdit
              id={match.params.id}
              close={async () => {
                await history.push("/surveys/management");
                setTheSurveys();
              }}
            />
          )}
        />

        <ProtectedRoute
          embedded
          path={`/surveys/answer-group/new`}
          exact
          strict
          component={() => (
            <AnswerNew
              close={async () => {
                await history.push("/surveys/answer-groups");
                setTheAnswers();
              }}
            />
          )}
        />

        <ProtectedRoute
          embedded
          path={`/surveys/answer-group/new-options/:id`}
          exact
          strict
          component={({ match }) => (
            <AnswerGAnswersNew
              id={match.params.id}
              close={async () => {
                await history.push("/surveys/answer-groups");
                setTheAnswers();
              }}
            />
          )}
        />

        <ProtectedRoute
          embedded
          path={`/surveys/answer-group/:id`}
          exact
          strict
          component={({ match }) => (
            <AnswerEdit
              id={match.params.id}
              close={async () => {
                await history.push("/surveys/answer-groups");
                setTheAnswers();
              }}
            />
          )}
        />

        <ProtectedRoute
          embedded
          path={`/surveys/group-categories/new`}
          exact
          strict
          component={() => (
            <GroupNew
              close={async () => {
                await history.push("/surveys/group-categories");
                setTheGroupCategories();
              }}
            />
          )}
        />

        <ProtectedRoute
          embedded
          path={`/surveys/group-categories/:id`}
          exact
          strict
          component={({ match }) => (
            <GroupEdit
              id={match.params.id}
              close={async () => {
                await history.push("/surveys/group-categories");
                setTheGroupCategories();
              }}
            />
          )}
        />

        <ProtectedRoute
          embedded
          path={`/surveys/phase-categories/new`}
          exact
          strict
          component={() => (
            <PhaseNew
              close={async () => {
                await history.push("/surveys/phase-categories");
                setThePhaseCategories();
              }}
            />
          )}
        />

        <ProtectedRoute
          embedded
          path={`/surveys/phase-categories/:id`}
          exact
          strict
          component={({ match }) => (
            <PhaseEdit
              id={match.params.id}
              close={async () => {
                await history.push("/surveys/phase-categories");
                setThePhaseCategories();
              }}
            />
          )}
        />

        <ProtectedRoute
          embedded
          path={`/surveys/questions/new`}
          exact
          strict
          component={() => (
            <QuestionsNew
              close={async () => {
                await history.push("/surveys/questions");
                setTheQuestions();
              }}
            />
          )}
        />

        <ProtectedRoute
          embedded
          path={`/surveys/questions/:id`}
          exact
          strict
          component={({ match }) => (
            <QuestionsEdit
              id={match.params.id}
              close={async () => {
                await history.push("/surveys/questions");
                setTheQuestions();
              }}
            />
          )}
        />

        <ProtectedRoute
          embedded
          path={`/surveys/questions/:id/kpi-adjustments`}
          exact
          strict
          component={({ match }) => (
            <KpiAdjustments
              id={match.params.id}
              close={async () => {
                await history.push("/surveys/questions");
                setTheQuestions();
              }}
            />
          )}
        />
      </Switch>
    </>
  );
};

export default inject(
  `authStore`,
  "surveyStore",
  "surveyAdminStore",
  "textStore"
)(withRouter(observer(StartSurvey)));
