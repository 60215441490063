import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Modal, Spinner, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

import * as yup from "yup";
import { Formik, Form, Field } from "formik";
import InputFormField from "../../../../components/fields/InputFormField";

const schema = yup.object({
  name: yup.string().required("Please fill in a group name"),
});

const PhaseNew = ({ surveyAdminStore, close }) => {
  const { t } = useTranslation();
  const [updating, setUpdating] = useState(false);

  useEffect(() => {}, []);

  return (
    <section className="modal-wrapper">
      <Modal.Dialog size="lg">
        <Formik
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            name: "",
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            setUpdating(true);
            surveyAdminStore
              .createPhaseCategory(values)
              .then(() => close())
              .catch((e) => alert(e.message));
            setUpdating(false);
            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Modal.Header closeButton onHide={() => close()}>
                <Modal.Title>{t("phase-cat-survey.new.title")}</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <div className="row mb-3">
                  <div className="col">
                    <Field
                      label={t("phase-cat-survey.name")}
                      name="name"
                      placeholder="Phase Name"
                      component={InputFormField}
                    />
                  </div>
                </div>
              </Modal.Body>

              <Modal.Footer className="mt-4">
                <Button onClick={() => close()} variant="link">
                  {t("cancel")}
                </Button>
                <Button type="submit" disabled={isSubmitting} variant="primary">
                  {updating ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    t("save")
                  )}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal.Dialog>
    </section>
  );
};

export default inject("surveyAdminStore")(withRouter(observer(PhaseNew)));
