import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";

import { Container, Table, Form, Button } from "react-bootstrap";

import PageSection from "../../components/PageSection";

const Answers = ({ surveyAdminStore, answers, close }) => {
  const { t } = useTranslation();
  const [select, setSelect] = useState("");
  useEffect(() => {}, []);

  const filterType =
    answers &&
    answers.filter((u) => {
      return u.type.toLowerCase().includes(select.toLowerCase());
    });

  const handleDelete = (answerGroupId) => {
    surveyAdminStore
      .deleteAnswerGroup(answerGroupId)
      .then(() => close())
      .catch((e) => alert(e.message));
  };

  return (
    <Container className="pt-4">
      <PageSection title={t("questions-survey.filters-title")} className="p-2">
        <div className="row cols-9">
          <div className="col">
            <Form.Label>{t("management-survey.label")}</Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => setSelect(e.target.value)}
              id="search"
            >
              <option value="">{t("management-survey.all")}</option>
              <option>{t("answers-survey.multi")}</option>
              <option>{t("answers-survey.single")}</option>
              <option>{t("answers-survey.yes")}</option>
              <option>{t("answers-survey.text")}</option>
            </Form.Control>
          </div>
        </div>
      </PageSection>

      <div className="text-right">
        <Link to={`/surveys/answer-group/new`}>{t("answers-survey.cta")}</Link>
      </div>

      <PageSection title={t("answers-survey.title")} className="p-2">
        <Table className="mb-0" responsive>
          <thead>
            <tr>
              <th>{t("answers-survey.labels.id")}</th>
              <th>{t("answers-survey.labels.name")}</th>
              <th>{t("answers-survey.labels.type")}</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filterType &&
              filterType.map((answer, key) => (
                <tr key={key}>
                  <td>
                    <div>{answer.identifier}</div>
                  </td>
                  <td>
                    <div>{answer.name}</div>
                  </td>
                  <td>
                    <div>{answer.type}</div>
                  </td>
                  {answer.type === "YES_NO" || answer.type === "TEXT" ? (
                    <td></td>
                  ) : (
                    <td>
                      <Link to={`/surveys/answer-group/${answer.id}`}>
                        {t("details")}
                      </Link>
                    </td>
                  )}
                  <td className="text-right">
                    <Button
                      variant="link"
                      className="p-0 text-danger"
                      onClick={() => {
                        window.confirm(t("sure")) && handleDelete(answer.id);
                      }}
                    >
                      {t("delete")}
                    </Button>
                    {/* <p className="text-muted">Can't be deleted</p> */}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </PageSection>
    </Container>
  );
};

export default inject(`surveyAdminStore`)(observer(Answers));
