import React, { Suspense } from "react";
import { Route, Switch, withRouter } from "react-router-dom";

import { ROUTES, PERMISSIONS } from "../constants/index";
import ProtectedRoute from "../components/ProtectedRoute";
import PageLoader from "../components/PageLoader";

import Login from "./auth/Login";
import Sidebar from "../components/nav/sidebar/Sidebar";
import Home from "./dashboards/StartupDashboard";
import Surveys from "./surveys/Surveys";
import Profile from "./profile/Profile";
import Kanban from "./kanban/Kanban";
import Reset from "./auth/ResetPassword";
import TakeSurveys from "./surveys/TakeSurvey";
import Reports from "./reports/_index";
import Results from "./results/Results";
import ResultDetail from "./results/ResultDetail";
import Startups from "./startups/Startups";
import Users from "./users/Users";
import Permissions from "./permissions/Permissions";
import Organizations from "./organizations/Organizations";
import PageHeaderTextEdit from "../components/PageHeaderTextEdit";

const App = () => {
  return (
    <Suspense fallback={PageLoader()}>
      <main>
        <nav
          id="primary"
          className={`nav${window.location.pathname.replace(/\//g, "-")}`}
        >
          <Sidebar />
        </nav>
        <section
          id="page"
          className={`page${window.location.pathname.replace(/\//g, "-")}`}
        >
          <Switch>
            <Route path={ROUTES.login} component={Login} />
            <Route path={ROUTES.resetPassword} component={Reset} />

            <ProtectedRoute
              path={ROUTES.dashboard}
              exact
              strict
              component={Home}
            />

            <ProtectedRoute
              permission={PERMISSIONS.SURVEYS_TAKE_DREAM_STARTUP}
              path={ROUTES.takeSurvey}
              render={({ match }) => <TakeSurveys id={match.params.id} />}
            />

            <ProtectedRoute
              permission={PERMISSIONS.SURVEYS_LIST_ORGANIZATION}
              path={ROUTES.surveys}
              strict
              component={Surveys}
            />

            <ProtectedRoute
              permission={PERMISSIONS.RESULTS_LIST_MINE}
              path={ROUTES.results}
              exact
              strict
              component={Results}
            />

            <ProtectedRoute
              permission={PERMISSIONS.RESULTS_LIST_MINE}
              path={ROUTES.resultDetail}
              render={({ match }) => <ResultDetail id={match.params.id} />}
            />

            <ProtectedRoute
              permission={PERMISSIONS.REPORTS_GENERATE_KPI_STARTUP}
              path={ROUTES.reports}
              component={Reports}
            />

            <ProtectedRoute
              permission={PERMISSIONS.KANBAN_LIST_STARTUP}
              path={ROUTES.kanban}
              exact
              strict
              component={Kanban}
            />

            <ProtectedRoute
              permission={PERMISSIONS.STARTUPS_LIST_ORGANIZATION}
              path={ROUTES.startups}
              strict
              component={Startups}
            />

            <ProtectedRoute
              permission={PERMISSIONS.PERMISSIONS_UPDATE_ORGANIZATION}
              path={ROUTES.permissions}
              exact
              strict
              render={({ match }) => <Permissions id={match.params.id} />}
            />

            <ProtectedRoute
              permission={PERMISSIONS.USERS_LIST_ORGANIZATION}
              path={ROUTES.users}
              strict
              component={Users}
            />

            <ProtectedRoute
              permission={PERMISSIONS.ORGANIZATIONS_LIST_ALL}
              path={ROUTES.organizations}
              strict
              component={Organizations}
            />

            <ProtectedRoute path={ROUTES.profile} strict component={Profile} />
            <ProtectedRoute
              path={`/text/:id`}
              exact
              strict
              component={({ match }) => (
                <PageHeaderTextEdit
                  id={match.params.id}
                  title="Dashboard"
                  close="/"
                />
              )}
            />
            <ProtectedRoute
              path={`/k/text/:id`}
              exact
              strict
              component={({ match }) => (
                <PageHeaderTextEdit
                  id={match.params.id}
                  title="Kanban"
                  close="/kanban"
                />
              )}
            />
            <ProtectedRoute
              path={`/s/text/:id`}
              exact
              strict
              component={({ match }) => (
                <PageHeaderTextEdit
                  id={match.params.id}
                  title="Surveys"
                  close="/surveys/management"
                />
              )}
            />
            <ProtectedRoute
              path={`/rs/text/:id`}
              exact
              strict
              component={({ match }) => (
                <PageHeaderTextEdit
                  id={match.params.id}
                  title="Snapshots"
                  close="/results"
                />
              )}
            />
            <ProtectedRoute
              path={`/rp/text/:id`}
              exact
              strict
              component={({ match }) => (
                <PageHeaderTextEdit
                  id={match.params.id}
                  title="Reports"
                  close="/reports"
                />
              )}
            />
            <ProtectedRoute
              path={`/o/text/:id`}
              exact
              strict
              component={({ match }) => (
                <PageHeaderTextEdit
                  id={match.params.id}
                  title="Organizations"
                  close="/organizations"
                />
              )}
            />
            <ProtectedRoute
              path={`/st/text/:id`}
              exact
              strict
              component={({ match }) => (
                <PageHeaderTextEdit
                  id={match.params.id}
                  title="Startups"
                  close="/startups"
                />
              )}
            />
            <ProtectedRoute
              path={`/u/text/:id`}
              exact
              strict
              component={({ match }) => (
                <PageHeaderTextEdit
                  id={match.params.id}
                  title="Users"
                  close="/users"
                />
              )}
            />
            <ProtectedRoute
              path={`/p/text/:id`}
              exact
              strict
              component={({ match }) => (
                <PageHeaderTextEdit
                  id={match.params.id}
                  title="Profile"
                  close="/profile"
                />
              )}
            />
          </Switch>
        </section>
      </main>
    </Suspense>
  );
};

export default withRouter(App);
