import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import SecondaryMenu from "../../components/nav/SecondaryMenu";

import { ROUTES } from "../../constants/index";
import PageHeader from "../../components/PageHeader";

import { Container, Button } from "react-bootstrap";
import { Switch, Route } from "react-router-dom";
import OverviewReports from "./KPIReports";
import CompareReports from "./GrowthReports";
import download from "../../assets/icons/download.svg";

const Reports = ({ textStore }) => {
  const { t } = useTranslation();

  useEffect(() => {
    textStore.setTexts().then(getText);
  }, []);

  const getText = textStore.texts.find((p) => p.page === "REPORTS");

  const actions = (
    <div className="row cols-col-2 w-100">
      <div className="col">
        <SecondaryMenu
          items={[
            { path: ROUTES.reports, title: t("reports-screen.kpi-reports") },
            {
              path: ROUTES.growthReports,
              title: t("reports-screen.growth-reports"),
            },
          ]}
        />
      </div>
    </div>
  );

  return (
    <>
      <PageHeader
        title={t("reports-screen.title")}
        explainer={getText && getText.text}
        link={getText && getText.link}
        actions={actions}
        textLink={`/rp/text/4`}
        append
      />

      <Container className="pt-4">
        <Switch>
          <Route
            path={ROUTES.growthReports}
            exact
            strict
            component={CompareReports}
          />
          <Route
            path={ROUTES.reports}
            exact
            strict
            component={OverviewReports}
          />
        </Switch>
      </Container>
    </>
  );
};

export default inject(`textStore`)(observer(Reports));
