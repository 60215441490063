import React from "react";
import { inject } from "mobx-react";
import { withRouter, Link } from "react-router-dom";
import { ROUTES } from "../../constants";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import NavSVG from "../NavSVG";
import { Button, Spinner } from "react-bootstrap";

import * as yup from "yup";
import { Formik, Form, Field } from "formik";
import InputFormField from "../fields/InputFormField";

const schema = yup.object({
  email: yup.string().email().required("Please fill in your account email"),
  password: yup.string().required("Please fill in your password"),
});

const LoginForm = ({ authStore, history }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [serverError, setServerError] = useState(null);

  const handleSubmit = (e) => {
    setLoading(true);

    return authStore
      .authenticate(e.email, e.password)
      .then(() => history.push(ROUTES.dashboard))
      .catch((e) => {
        if (e.identifier === "invalid_credentials")
          setServerError("Het e-mailadres of wachtwoord is ongeldig.");
        else setServerError(e.message);
      })
      .then(() => setLoading(false));
  };

  return (
    <Formik
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{ email: "", password: "" }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        handleSubmit(values).then(() => setSubmitting(false));
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          {serverError && <p className="small text-danger">{serverError}</p>}
          <div>
            <Field
              label={t("login-screen.email")}
              name="email"
              placeholder={t("login-screen.email-placeholder")}
              type="email"
              component={InputFormField}
            />
          </div>
          <div className="password-field-wrapper mt-3">
            <Field
              label={t("login-screen.password")}
              name="password"
              type={showPassword ? "text" : "password"}
              component={InputFormField}
            />
            <button
              type="button"
              className="toggle-password-visibility"
              onClick={() => setShowPassword(!showPassword)}
            >
              <NavSVG icon={showPassword ? "visible" : "visible-off"} />
            </button>
          </div>
          <div className="mt-3 small text-right">
            <Link to={ROUTES.passwordForgot}>
              <span className="text-muted">{t("login-screen.forgot")}</span>
            </Link>
          </div>
          <Button
            variant="primary"
            disabled={isSubmitting}
            className="mt-4"
            type="submit"
            block
          >
            {loading && (
              <Spinner
                className="mr-2 mb-1"
                as="span"
                variant="light"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            {t("login-screen.title")}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default inject(`authStore`)(withRouter(LoginForm));
