import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import moment from "moment";

import Select from "react-select";
import {
  Table,
  OverlayTrigger,
  Tooltip,
  Button,
  Spinner,
} from "react-bootstrap";

import emptyImg from "../../assets/empty_data.svg";
import NavSVG from "../../components/NavSVG";

import PageSection from "../../components/PageSection";
import { PERMISSIONS } from "../../constants/index";

const OverviewReports = ({
  reportsStore,
  authStore,
  startupStore,
  organizationStore,
}) => {
  const { t } = useTranslation();

  const [options, setOptions] = useState(null);
  const [startupOptions, setStartupOptions] = useState(null);
  const [data, setData] = useState(null);
  const [snapshotId, setSnapshotId] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const [showOmittedLinesWarning, setShowOmittedLinesWarning] = useState(false);
  const [organizationOptions, setOrganizationOptions] = useState(null);

  const hasPermission = (permission) => {
    return (
      authStore.user && authStore.user.permissions.find((p) => p === permission)
    );
  };

  const getOrganizationOptions = async () => {
    let newOrganizationOptions = [];
    await organizationStore.setOrganizations().catch((e) => alert(e.message));

    organizationStore.organizations &&
      organizationStore.organizations.map((o) => {
        newOrganizationOptions.push({
          label: o.name,
          value: o.uuid,
        });
      });

    setOrganizationOptions(newOrganizationOptions);
  };

  const getStartupOptions = async (organizationId) => {
    const newStartupOptions = [];

    await startupStore.setStartups().catch((e) => {
      alert(e.message);
    });

    const allStartups = startupStore.startups;
    const filteredStartups = allStartups
      .map((item) => ({
        ...item,
        organizations: item.organizations.filter(
          (org) => org.uuid === organizationId
        ),
      }))
      .filter((startup) => startup.organizations.length > 0);

    filteredStartups.forEach((startup) => {
      newStartupOptions.push({
        label: startup.name,
        value: startup.uuid,
        id: startup.uuid,
      });
    });

    setStartupOptions(newStartupOptions);
  };

  let getOptions = async (e) => {
    let newOptions = [];

    const snapshots = await reportsStore.findAllByCompleted();
    let filteredSnapshots;

    if (e === undefined) {
      filteredSnapshots = snapshots.filter(
        (test) => test.createdBy.uuid === authStore.user.uuid
      );
    } else {
      filteredSnapshots = snapshots.filter((s) => s.startup.uuid === e.id);
    }

    filteredSnapshots.forEach((snapshot) => {
      const dateString = moment(snapshot.date).format(
        "MMMM Do YYYY, h:mm:ss a"
      );

      newOptions.push({
        label: `${snapshot.survey.name}, ${t(
          "reports-screen.conducted"
        )} on ${dateString} by ${snapshot.createdBy.name}`,
        value: snapshot.id,
        id: snapshot.id,
      });
    });

    setOptions(newOptions);
  };

  const getAnswerForSnapshotLine = (line) => {
    switch (line.question.answerGroup.type) {
      case "MULTIPLE_CHOICE":
        const textAnswers = line.multipleSelectedAnswers.map((a) => a.text);
        return textAnswers.join(", ");
      case "SINGLE_CHOICE":
        return line.selectedAnswer.text;
      case "TEXT":
        return line.textResponse;
      case "YES_NO":
        if (line.selectedAnswer.text === "TRUE") return t("yes");
        else return t("no");
      default:
        return "N/A";
    }
  };

  const generateReport = async (snapshot) => {
    let newData = [];
    const evaluatedLines = snapshot.answers.filter((line) => line.question.kpi);

    if (evaluatedLines.length !== snapshot.answers)
      setShowOmittedLinesWarning(true);

    evaluatedLines.forEach((line) => {
      let record = {};

      record = {
        id: line.id,
        question: line.question.text,
        targetOperator: line.targetOperator,
        delta: line.delta,
        result: getAnswerForSnapshotLine(line),
        group: line.question.groupCategory.name,
        phase: line.question.groupCategory.phaseCategory.name,
      };

      if (line.targetValue) {
        record.target = line.targetValue;
      } else if (line.targetQuestion) {
        const target = snapshot.answers.find(
          (l) => l.question.id === line.targetQuestion
        );

        record.target = getAnswerForSnapshotLine(line);
        record.targetSource = target.question.text;
      }

      newData.push(record);
    });

    setSnapshotId(snapshot.id);
    setData(newData);
  };

  const downloadPDF = async (snapshotId) => {
    await reportsStore.getByPrintId(snapshotId);
  };

  const resetReport = () => {
    setShowOmittedLinesWarning(false);
    setData([]);
  };

  const handleSelection = async (e) => {
    resetReport();

    const snapshot = await reportsStore.findById(e.id);

    generateReport(snapshot);
  };

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  useEffect(() => {
    hasPermission(PERMISSIONS.REPORTS_GENERATE_KPI_ALL)
      ? getOrganizationOptions()
      : hasPermission(PERMISSIONS.REPORTS_GENERATE_KPI_ORGANIZATION) &&
        !hasPermission(PERMISSIONS.REPORTS_GENERATE_KPI_ALL)
      ? getStartupOptions(authStore.user.organization.uuid)
      : getOptions();
  }, []);

  return (
    <>
      {hasPermission(PERMISSIONS.REPORTS_GENERATE_KPI_ALL) && (
        <PageSection title={t("menu-items.organizations")}>
          <label htmlFor="startup" className="sr-only">
            {t("growth-reports-screen.survey")}
          </label>
          <Select
            id="organization"
            name="organization"
            placeholder={t("placeholderOrgSelect")}
            options={organizationOptions}
            onChange={(e) => {
              setStartupOptions(null);
              setOptions(null);
              setShowOmittedLinesWarning(false);
              setData(null);
              getStartupOptions(e.value);
            }}
          />
        </PageSection>
      )}
      {hasPermission(PERMISSIONS.REPORTS_GENERATE_KPI_ORGANIZATION) &&
        startupOptions !== null && (
          <PageSection title={t("menu-items.startups")}>
            <label htmlFor="startup" className="sr-only">
              {t("growth-reports-screen.survey")}
            </label>
            <Select
              id="startup"
              name="startup"
              placeholder={t("placeholderStartupSelect")}
              options={startupOptions}
              onChange={(e) => getOptions(e)}
            />
          </PageSection>
        )}

      {options !== null && (
        <PageSection title={t("reports-screen.snapshot")}>
          <label htmlFor="snapshot" className="sr-only">
            {t("reports-screen.snapshot")}
          </label>
          <Select
            id="snapshot"
            name="snapshot"
            onChange={handleSelection}
            options={options}
            placeholder={t("reports-screen.start-by-selecting")}
          />
        </PageSection>
      )}

      {data !== null ? (
        <PageSection
          loading={!data.length}
          title={t("reports-screen.report")}
          className="p-2"
        >
          <div className="text-right col pt-3">
            {snapshotId !== null ? (
              <Button
                variant="link"
                className="p-0"
                onClick={async () => {
                  setDownloading(true);
                  downloadPDF(snapshotId);
                  await delay(10000);
                  setDownloading(false);
                }}
              >
                {downloading === true ? (
                  <span className="text-muted">
                    <Spinner
                      variant="primary"
                      animation="border"
                      size="sm"
                      className="ml-3 mr-1"
                    />
                  </span>
                ) : (
                  t("print")
                )}
              </Button>
            ) : (
              <Button
                variant="link"
                className="p-0"
                disabled
                onClick={() => downloadPDF()}
              >
                {" "}
                {t("print")}
              </Button>
            )}
          </div>
          <Table className="mb-0" responsive>
            <thead>
              <tr>
                <th>{t("reports-screen.phase")}</th>
                <th>{t("reports-screen.group")}</th>
                <th>{t("reports-screen.question")}</th>
                <th>{t("reports-screen.current")}</th>
                <th>{t("reports-screen.target")}</th>
                <th>{t("reports-screen.delta")}</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((record, key) => (
                  <tr key={key}>
                    <td>
                      <div className="text-muted">{record.phase}</div>
                    </td>
                    <td>
                      <div className="text-muted">{record.group}</div>
                    </td>
                    <td>
                      <div>{record.question}</div>
                    </td>
                    <td>
                      <p className="text-nowrap m-0">{record.result}</p>
                    </td>
                    <td>
                      <p className="text-nowrap m-0">
                        {t(`reports-screen.${record.targetOperator}`)}
                        {record.target}
                      </p>
                      {record.targetSource && (
                        <>
                          <OverlayTrigger
                            popperConfig={{
                              modifiers: {
                                preventOverflow: {
                                  enabled: false,
                                },
                              },
                            }}
                            key={record.id}
                            placement="bottom"
                            overlay={
                              <Tooltip
                                id={`tooltip-question-based-${record.id}`}
                              >
                                {record.targetSource}
                              </Tooltip>
                            }
                          >
                            <div className="small mt-1 text-muted cursor-default">
                              {t("reports-screen.based-on-answer")}
                            </div>
                          </OverlayTrigger>
                        </>
                      )}
                    </td>
                    <td className="text-center">
                      <NavSVG
                        icon={record.delta === true ? "thumbsUp" : "thumbsDown"}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </PageSection>
      ) : (
        <div className="text-center py-5">
          <p className="display-4 w-75 mx-auto text-black-50">
            {t("reports-screen.start-by-selecting")}
          </p>
          <p className="w-75 mx-auto text-black-50">
            {t("reports-screen.kpi-report-description")}
          </p>
          <img
            src={emptyImg}
            alt="No snapshots"
            className="img-fluid w-75 mx-auto mt-5"
          />
        </div>
      )}
    </>
  );
};

export default inject(
  `reportsStore`,
  "authStore",
  "startupStore",
  "organizationStore"
)(observer(OverviewReports));
