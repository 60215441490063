export default {
  DASHBOARD_SHOW_STARTUP: "DASHBOARD_SHOW_STARTUP",
  DASHBOARD_SHOW_ORGANIZATION: "DASHBOARD_SHOW_ORG",
  DASHBOARD_SHOW_ALL: "DASHBOARD_SHOW_ALL",

  SURVEYS_TAKE_DREAM_STARTUP: "SURVEYS_TAKE_DREAM_STARTUP",
  SURVEYS_TAKE_TEAM_STARTUP: "SURVEYS_TAKE_TEAM_STARTUP",
  SURVEYS_TAKE_DREAM_ORGANIZATION: "SURVEYS_TAKE_DREAM_ORG",
  SURVEYS_TAKE_TEAM_ORGANIZATION: "SURVEYS_TAKE_TEAM_ORG",
  SURVEYS_LIST_ALL: "SURVEYS_LIST_ALL",
  // SURVEYS_LIST_ORGANIZATION: "SURVEYS_LIST_ORG",
  SURVEYS_CREATE_ALL: "SURVEYS_CREATE_ALL",
  SURVEYS_UPDATE_ALL: "SURVEYS_UPDATE_ALL",
  SURVEYS_DELETE_ALL: "SURVEYS_DELETE_ALL",

  QUESTIONS_LIST_ALL: "QUESTIONS_LIST_ALL",
  QUESTIONS_CREATE_ALL: "QUESTIONS_CREATE_ALL",
  QUESTIONS_UPDATE_ALL: "QUESTIONS_UPDATE_ALL",
  QUESTIONS_DELETE_ALL: "QUESTIONS_DELETE_ALL",

  CATEGORIES_LIST_ALL: "CATEGORIES_LIST_ALL",
  CATEGORIES_CREATE_ALL: "CATEGORIES_CREATE_ALL",
  CATEGORIES_UPDATE_ALL: "CATEGORIES_UPDATE_ALL",
  CATEGORIES_DELETE_ALL: "CATEGORIES_DELETE_ALL",

  RESULTS_LIST_MINE: "RESULTS_LIST_MINE",
  RESULTS_LIST_STARTUP: "RESULTS_LIST_STARTUP",
  RESULTS_DELETE_STARTUP: "RESULTS_DELETE_STARTUP",
  RESULTS_LIST_ORGANIZATION: "RESULTS_LIST_ORG",
  RESULTS_DELETE_ORGANIZATION: "RESULTS_DELETE_ORG",
  RESULTS_LIST_ALL: "RESULTS_LIST_ALL",
  RESULTS_DELETE_ALL: "RESULTS_DELETE_ALL",

  REPORTS_GENERATE_KPI_STARTUP: "REPORTS_GENERATE_KPI_STARTUP",
  REPORTS_GENERATE_GROWTH_STARTUP: "REPORTS_GENERATE_GROWTH_STARTUP",
  REPORTS_GENERATE_KPI_ORGANIZATION: "REPORTS_GENERATE_KPI_ORG",
  REPORTS_GENERATE_GROWTH_ORGANIZATION: "REPORTS_GENERATE_GROWTH_ORG",
  REPORTS_GENERATE_KPI_ALL: "REPORTS_GENERATE_KPI_ALL",
  REPORTS_GENERATE_GROWTH_ALL: "REPORTS_GENERATE_GROWTH_ALL",

  KANBAN_LIST_STARTUP: "KANBAN_LIST_STARTUP",
  KANBAN_CREATE_STARTUP: "KANBAN_CREATE_STARTUP",
  KANBAN_UPDATE_STARTUP: "KANBAN_UPDATE_STARTUP",
  KANBAN_ARCHIVE_STARTUP: "KANBAN_ARCHIVE_STARTUP",
  KANBAN_LIST_ORGANIZATION: "KANBAN_LIST_ORG",
  KANBAN_CREATE_ORGANIZATION: "KANBAN_CREATE_ORG",
  KANBAN_UPDATE_ORGANIZATION: "KANBAN_UPDATE_ORG",
  KANBAN_ARCHIVE_ORGANIZATION: "KANBAN_ARCHIVE_ORG",
  KANBAN_LIST_ALL: "KANBAN_LIST_ALL",
  KANBAN_CREATE_ALL: "KANBAN_CREATE_ALL",
  KANBAN_UPDATE_ALL: "KANBAN_UPDATE_ALL",
  KANBAN_ARCHIVE_ALL: "KANBAN_ARCHIVE_ALL",

  USERS_LIST_ORGANIZATION: "USERS_LIST_ORG",
  USERS_CREATE_ORGANIZATION: "USERS_CREATE_ORG",
  USERS_UPDATE_ORGANIZATION: "USERS_UPDATE_ORG",
  USERS_DELETE_ORGANIZATION: "USERS_DELETE_ORG",
  USERS_LIST_ALL: "USERS_LIST_ALL",
  USERS_CREATE_ALL: "USERS_CREATE_ALL",
  USERS_UPDATE_ALL: "USERS_UPDATE_ALL",
  USERS_DELETE_ALL: "USERS_DELETE_ALL",

  STARTUPS_LIST_ORGANIZATION: "STARTUPS_LIST_ORG",
  STARTUPS_CREATE_ORGANIZATION: "STARTUPS_CREATE_ORG",
  STARTUPS_UPDATE_ORGANIZATION: "STARTUPS_UPDATE_ORG",
  STARTUPS_LIST_ALL: "STARTUPS_LIST_ALL",
  STARTUPS_CREATE_ALL: "STARTUPS_CREATE_ALL",
  STARTUPS_UPDATE_ALL: "STARTUPS_UPDATE_ALL",
  STARTUPS_DELETE_ALL: "STARTUPS_DELETE_ALL",

  ORGANIZATIONS_LIST_ALL: "ORGANIZATIONS_LIST_ALL",
  ORGANIZATIONS_CREATE_ALL: "ORGANIZATIONS_CREATE_ALL",
  ORGANIZATIONS_UPDATE_ALL: "ORGANIZATIONS_UPDATE_ALL",
  ORGANIZATIONS_DELETE_ALL: "ORGANIZATIONS_DELETE_ALL",

  PERMISSIONS_UPDATE_ALL: "PERMISSIONS_UPDATE_ALL",
  PERMISSIONS_UPDATE_MINE: "PERMISSIONS_UPDATE_MINE",
};
