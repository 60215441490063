import { decorate, observable, action } from "mobx";
import Service from "../services/Service";

class OrganizationStore {
  organizations = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.organizationService = new Service(`organizations`);
  }

  getById = (id) => {
    return this.organizationService.getById(id);
  };

  updateOrganization = (id, props) => {
    return this.organizationService.replace(id, props);
  };

  createOrganization = (props) => {
    return this.organizationService.create(props);
  };

  deleteOrganization = (id) => {
    return this.organizationService.delete(id);
  };

  setOrganizations = () => {
    return this.organizationService.getAll().then((data) => {
      this.organizations = data;
    });
  };
}

decorate(OrganizationStore, {
  organizations: observable,
  createOrganization: action,
  updateOrganization: action,
});

export default OrganizationStore;
