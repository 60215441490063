import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import { Link, Switch } from "react-router-dom";
import { Container, Table, Form, Alert, Button } from "react-bootstrap";

import PageHeader from "../../components/PageHeader";
import PageSection from "../../components/PageSection";

import ProtectedRoute from "../../components/ProtectedRoute";

import EditStartupModal from "./components/EditStartupModal";
import NewStartupModal from "./components/NewStartupModal";
import { ROUTES } from "../../constants";

const Startups = ({ startupStore, textStore, organizationStore, history }) => {
  const { t } = useTranslation();

  const [search, setSearch] = useState("");
  const [select, setSelect] = useState("");

  const setTheStartups = () => {
    startupStore.setStartups().catch((e) => {
      alert(e.message);
    });
  };

  useEffect(() => {
    setTheStartups();

    organizationStore.setOrganizations().catch((e) => alert(e.message));
    textStore.setTexts().then(getText);
  }, []);

  const organizations =
    organizationStore.organizations && organizationStore.organizations;

  const filteredStartups =
    startupStore.startups &&
    startupStore.startups.filter((f) => {
      return f.name.toLowerCase().includes(search.toLowerCase());
    });

  const filterOrganization =
    select !== ""
      ? filteredStartups
          .map((item) => ({
            ...item,
            organizations: item.organizations.filter(
              (org) => org.name === select
            ),
          }))
          .filter((startup) => startup.organizations.length > 0)
      : filteredStartups;

  const handleArchive = (startupId) => {
    // startupStore
    //   .archiveStartup(startupId)
    //   .then(() => setTheStartups())
    //   .catch((e) => alert(e.message));
    console.log(startupId, "archived");
  };

  const getText = textStore.texts.find((p) => p.page === "STARTUPS");

  return (
    <>
      <PageHeader
        title={t("startup-admin-screen.title")}
        explainer={getText && getText.text}
        link={getText && getText.link}
        actions={
          <>
            <Link to="/startups/new" className="btn btn-primary">
              {t("startup-admin-screen.new")}
            </Link>
          </>
        }
        textLink={`/st/text/6`}
      />

      <Container className="pt-4">
        <Alert variant="warning">{t("warning")}</Alert>
        <PageSection title={t("startup-admin-screen.search-title")}>
          <div className="search flex-grow-1">
            <Form.Label htmlFor="search" className="sr-only">
              {t("startup-admin-screen.search-title")}
            </Form.Label>
            <Form.Control
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder={t("startup-admin-screen.search-placeholder")}
              id="search"
            />
          </div>
        </PageSection>
        <PageSection title={t("filter")}>
          <div className="search flex-grow-1">
            <Form.Label htmlFor="search" className="sr-only">
              {t("startup-admin-screen.search-title")}
            </Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => setSelect(e.target.value)}
              id="search"
            >
              <option value="">All</option>
              {organizations &&
                organizations.map((a, key) => (
                  <option key={key}>{a.name}</option>
                ))}
            </Form.Control>
          </div>
        </PageSection>
        <PageSection
          title={t("startup-admin-screen.startups-title")}
          className="p-2"
        >
          <Table className="mb-0" responsive>
            <thead>
              <tr>
                <th>{t("startup-admin-screen.startup-name")}</th>
                <th>{t("menu-items.organizations")}</th>
                <th>{t("startup-admin-screen.startup-website")}</th>
                <th>{t("startup-admin-screen.startup-survey")}</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filterOrganization.map((startup, key) => (
                <tr key={key}>
                  <td>
                    <div>{startup.name}</div>
                  </td>
                  <td>
                    {startup.organizations.length > 0
                      ? startup.organizations.map((org) => {
                          return <div>{org.name}</div>;
                        })
                      : t("not")}
                  </td>
                  <td>
                    <a href={`https://${startup.website}`} target="_blank">
                      {startup.website}
                    </a>
                  </td>
                  <td>
                    <div>{startup.survey && startup.survey.name}</div>
                  </td>
                  <td>
                    <Link
                      to={`/startups/${startup.uuid}`}
                      className="btn btn-light btn-sm"
                    >
                      {t("edit")}
                    </Link>
                  </td>
                  <td className="text-right">
                    <Button
                      variant="link"
                      className="p-0 btn-sm text-danger"
                      onClick={() => {
                        window.confirm(t("sure")) &&
                          handleArchive(startup.uuid);
                      }}
                    >
                      {t("kanban-screen.edit-modal.archive")}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </PageSection>
      </Container>

      <Switch>
        <ProtectedRoute
          embedded
          path="/startups/new"
          exact
          strict
          render={() => (
            <NewStartupModal
              close={async () => {
                await history.push(ROUTES.startups);
                setTheStartups();
              }}
            />
          )}
        />
        <ProtectedRoute
          embedded
          path="/startups/:id"
          exact
          strict
          render={({ match }) => (
            <EditStartupModal
              id={match.params.id}
              close={async () => {
                await history.push(ROUTES.startups);
                setTheStartups();
              }}
            />
          )}
        />
      </Switch>
    </>
  );
};

export default inject(
  `startupStore`,
  "organizationStore",
  "textStore"
)(observer(Startups));
