import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import moment from "moment";
import { PERMISSIONS } from "../../../constants/index";

import { Modal, Button, Form, Row, Col, Spinner } from "react-bootstrap";

const EditTaskModal = ({ kanbanStore, authStore, shown, close, task }) => {
  const { t } = useTranslation();

  const [updating, setUpdating] = useState(false);
  const [archiving, setArchiving] = useState(false);

  const [title, setTitle] = useState(task.title);
  const [description, setDescription] = useState(task.description);

  const hasPermission = (permission) => {
    return (
      authStore.user && authStore.user.permissions.find((p) => p === permission)
    );
  };

  const handleChange = (event, setter) => {
    setter(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setUpdating(true);

    const params = Object.assign(task, { title, description });

    kanbanStore.updateTask(task.id, params).then(() => {
      close();
    });
  };

  const handleArchiveCard = () => {
    setArchiving(true);

    kanbanStore.deleteTask(task.id).then(() => {
      close();
    });
  };

  const status = () => {
    switch (task.status) {
      case "IN_PROGRESS":
        return t("kanban-screen.inProgress");
      case "DONE":
        return t("kanban-screen.done");
      default:
        return t("kanban-screen.todo");
    }
  };

  const loader = (
    <Modal.Body>
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </Modal.Body>
  );

  const form = (
    <Form onSubmit={handleSubmit}>
      <Modal.Body>
        <Form.Group>
          <Form.Label className="sr-only">
            {t("kanban-screen.edit-modal.field-title")}
          </Form.Label>
          <Form.Control
            size="lg"
            required
            type="text"
            value={title}
            onChange={(e) => handleChange(e, setTitle)}
            placeholder={t("kanban-screen.edit-modal.placeholder-title")}
          />
        </Form.Group>

        <Row>
          <Col md="8">
            <Form.Group>
              <Form.Label className="sr-only">
                {t("kanban-screen.edit-modal.field-description")}
              </Form.Label>
              <Form.Control
                as="textarea"
                rows="3"
                required
                value={description}
                onChange={(e) => handleChange(e, setDescription)}
                placeholder={t("kanban-screen.edit-modal.placeholder-desc")}
              />
            </Form.Group>

            <h3 className="mt-4">{t("kanban-screen.edit-modal.activity")}</h3>
            <div className="small text-muted">
              {task.history.map((h) => (
                <p>
                  {t("kanban-screen.edit-modal.history-update")} {h.title && h.title}{" "}
                  {t("kanban-screen.edit-modal.history-desc")} {h.description && h.description}{" "}
                  {t("kanban-screen.edit-modal.history-at")}{" "}
                  {moment(h.at && h.at).format(" DD/MM/YYYY")}{" "}
                  {t("kanban-screen.edit-modal.history-pos")} {h.position && h.positio}{" "}
                  {t("kanban-screen.edit-modal.history-table")} {h.status && h.status}{" "}
                  {t("kanban-screen.edit-modal.history-by")} {h.createdBy && h.createdBy.name}.
                </p>
              ))}
            </div>
          </Col>
          <Col md="4">
            <p className="font-weight-bold">{status()}</p>
            <>
              {hasPermission(PERMISSIONS.KANBAN_LIST_ORGANIZATION) && (
                <button
                  type="button"
                  onClick={() => {
                    window.confirm(t("sure")) && handleArchiveCard();
                  }}
                  className="btn btn-link text-danger p-0 btn-sm"
                >
                  {archiving ? (
                    <Spinner animation="border" size="sm" variant="danger" />
                  ) : (
                    ""
                  )}
                  {t("kanban-screen.edit-modal.archive")}
                </button>
              )}
            </>

            <hr />
            <div className="small text-muted">
              <ul className="list-unstyled">
                <li>
                  {t("kanban-screen.edit-modal.created-at")}
                  {moment(task.createdAt).format(" DD/MM/YYYY")}
                </li>
                <li>
                  {t("kanban-screen.edit-modal.updated-at")}
                  {moment(task.history[0].createdAt).format(" DD/MM/YYYY")}
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={close} variant="link">
          {t("cancel")}
        </Button>
        <Button type="submit" variant="primary">
          {updating ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            t("save")
          )}
        </Button>
      </Modal.Footer>
    </Form>
  );

  return (
    <section
      id="edit-kanban-card"
      className={classNames({ "d-none": !shown, "modal-wrapper": true })}
    >
      <Modal.Dialog size="lg">{task != null ? form : loader}</Modal.Dialog>
    </section>
  );
};

export default inject(`kanbanStore`, "authStore")(observer(EditTaskModal));
