import React from "react";
import Form from "react-bootstrap/Form";

const TextAreaFormField = ({ field, form: { errors }, ...props }) => {
  return (
    <div>
      <Form.Label>{props.label}</Form.Label>
      {errors[field.name] && (
        <div className="small text-danger">{errors[field.name]}</div>
      )}
      <Form.Text className="text-muted">{props.description}</Form.Text>
      <Form.Control as="textarea" {...field} {...props} />
    </div>
  );
};

export default TextAreaFormField;
