import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import { Container, Row, Col, Badge, Button } from "react-bootstrap";
import bg from "../../../assets/bg_checkup.png";

import PageSection from "../../../components/PageSection";

const SurveysPage = ({ survey, type }) => {
  const { t } = useTranslation();

  const [hasAnswers, setHasAnswers] = useState(false);

  let getData = () => {
    survey && localStorage.getItem(`values for ${survey.id}`)
      ? setHasAnswers(true)
      : setHasAnswers(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleClickDelete = (id) => {
    localStorage.removeItem(`values for ${id}`);
    localStorage.removeItem(`snapshotId for ${id}`);
    localStorage.removeItem(`count for ${id}`);
    localStorage.removeItem(`offset for ${id}`);
    localStorage.removeItem(`procent for ${id}`);
    setHasAnswers(false);
  };

  return (
    <Container>
      <PageSection
        loading={survey === undefined}
        className={`mt-4 p-4 border-0 survey-box survey-box--${type} text-light`}
      >
        {(survey && survey.questionCount === 0) || survey === null ? (
          <div className="d-flex justify-content-center align-items-center p-4">
            {t("surveyblock.empty")}
          </div>
        ) : (
          <>
            <img src={bg} alt="Background" />
            <Badge pill variant="light" className="question-count">
              {survey && survey.questionCount}{" "}
              {t("surveyblock.nmbrOfQuestions")}
            </Badge>
            <Row className="h-100">
              <Col className="align-self-end">
                <p className="mb-0">{t("surveyblock.ready-to-start")}</p>
                <p className="display-3">{survey && survey.name}</p>
                <p className="m-0 w-75">{survey && survey.description}</p>
              </Col>
              <Col md="auto" className="align-self-end">
                {survey && (
                  <>
                    {hasAnswers === true ? (
                      <Row>
                        <Col md="auto">
                          <Button
                            variant="outline-light"
                            onClick={() => handleClickDelete(survey.id)}
                          >
                            {t("surveyblock.ctaDelete")}
                          </Button>
                        </Col>
                        <Col md="auto">
                          <Link
                            to={`/surveys/${survey.id}/take`}
                            className="btn btn-outline-light"
                          >
                            {t("surveyblock.ctaGo")}
                          </Link>
                        </Col>
                      </Row>
                    ) : (
                      <>
                        <Col md="auto">
                          <Link
                            to={`/surveys/${survey.id}/take`}
                            className="btn btn-outline-light"
                          >
                            {t("surveyblock.cta")}
                          </Link>
                        </Col>
                      </>
                    )}
                  </>
                )}
              </Col>
            </Row>
          </>
        )}
      </PageSection>
    </Container>
  );
};

export default observer(SurveysPage);
