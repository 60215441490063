import React from "react";
import { FieldProps } from "formik";
import Select, { Option, ReactSelectProps } from "react-select";
import { useTranslation } from "react-i18next";

const ReactSelectFormField = ({
  field,
  form,
  options,
  FieldProps,
  ReactSelectProps,
}) => {
  const { t } = useTranslation();

  return (
    <Select
      options={options}
      name={field.name}
      placeholder={t("placeholderSelect")}
      value={
        options ? options.find((option) => option.value === field.value) : ""
      }
      onChange={(option: Option) =>
        form.setFieldValue(field.name, option.value)
      }
      onBlur={field.onBlur}
    />
  );
};

export default ReactSelectFormField;
