import React from "react";
import { Route } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { ROUTES } from "../constants";
import { Redirect } from "react-router-dom";

import PageLoader from "./PageLoader";
import PageUnauthorized from "./PageUnauthorized";

const ProtectedRoute = ({
  startup,
  permission,
  authStore,
  render,
  embedded,
  component: Component,
  ...rest
}) => {
  if (authStore.user === null) return <Redirect to={ROUTES.login} />;
  else if (
    authStore.user === undefined ||
    authStore.activeStartupUuid === undefined
  ) {
    return <Route {...rest} render={PageLoader} />;
  }

  if (permission && !authStore.user.permissions.find((p) => p === permission)) {
    const pageUnauthorized = (
      <PageUnauthorized
        embedded={embedded}
        reason="It seems like you don't have permission to access this page."
      />
    );
    return <Route {...rest} render={() => pageUnauthorized} />;
  }
  if (startup && !authStore.activeStartupUuid) {
    const pageUnauthorized = (
      <PageUnauthorized
        embedded={embedded}
        reason="Only members of a startup can access this page."
      />
    );
    return <Route {...rest} render={() => pageUnauthorized} />;
  }

  return (
    <Route
      {...rest}
      render={render || ((props) => <Component {...rest} {...props} />)}
    />
  );
};

export default inject("authStore")(observer(ProtectedRoute));
