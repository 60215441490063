import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import { Link, Switch } from "react-router-dom";
import { Container, Form, Alert } from "react-bootstrap";
import classNames from "classnames";

import PageHeader from "../../components/PageHeader";
import PageSection from "../../components/PageSection";
import ProtectedRoute from "../../components/ProtectedRoute";

import NewOrganizationModal from "./components/NewOrganizationModal";
import OrganizationDetailModal from "./components/OrganizationDetailModal";

import { ROUTES } from "../../constants";

const Organizations = ({ organizationStore, textStore, history }) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");

  const setTheOrganizations = () => {
    organizationStore.setOrganizations().catch((e) => {
      alert(e.message);
    });
  };

  useEffect(() => {
    setTheOrganizations();
    textStore.setTexts().then(getText);
  }, []);

  const getText = textStore.texts.find((p) => p.page === "ORGANIZATIONS");

  const filteredOrganizations =
    organizationStore.organizations &&
    organizationStore.organizations.filter((u) => {
      return u.name.toLowerCase().includes(search.toLowerCase());
    });

  return (
    <>
      <PageHeader
        title={t("menu-items.organizations")}
        explainer={getText && getText.text}
        link={getText && getText.link}
        actions={
          <>
            <Link to="/organizations/new" className="btn btn-primary">
              {t("organizations-screen.cta")}
            </Link>
          </>
        }
        textLink={`/o/text/2`}
      />
      <Container className="pt-4">
        <PageSection title={t("organizations-screen.title-search")}>
          <div className="search flex-grow-1">
            <Form.Label htmlFor="search" className="sr-only">
              {t("startup-admin-screen.search-title")}
            </Form.Label>
            <Form.Control
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder={t("organizations-screen.placeholder-search")}
              id="search"
            />
          </div>
        </PageSection>
        <Alert variant="warning">
          {t("organizations-screen.placeholder-warning")}
        </Alert>
        <PageSection title={t("menu-items.organizations")}>
          {filteredOrganizations &&
            filteredOrganizations.map((organization, key) => (
              <div
                key={key}
                className={
                  "d-flex align-items-center py-3 " +
                  classNames({ "border-top": key > 0 })
                }
              >
                <div className="details p-1 d-flex flex-column flex-md-row">
                  <div className="name pr-md-3">{organization.name}</div>
                </div>
                <div className="p-1 actions flex-grow-1 text-right">
                  <Link
                    to={`/organizations/${organization.uuid}`}
                    className="btn btn-light btn-sm"
                  >
                    {t("details")}
                  </Link>
                </div>
              </div>
            ))}
        </PageSection>
      </Container>

      <Switch>
        <ProtectedRoute
          embedded
          path="/organizations/new"
          exact
          strict
          render={() => (
            <NewOrganizationModal
              close={async () => {
                await history.push(ROUTES.organizations);
                setTheOrganizations();
              }}
            />
          )}
        />
        <ProtectedRoute
          embedded
          path="/organizations/:id"
          exact
          strict
          render={({ match }) => (
            <OrganizationDetailModal
              id={match.params.id}
              close={async () => {
                await history.push(ROUTES.organizations);
                setTheOrganizations();
              }}
            />
          )}
        />
      </Switch>
    </>
  );
};

export default inject(
  "organizationStore",
  "textStore"
)(observer(Organizations));
