import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { Modal, Spinner, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

import * as yup from "yup";
import { Formik, Form, Field } from "formik";
import InputFormField from "../../../components/fields/InputFormField";

const schema = yup.object({
  name: yup.string().required("Please fill in a Organizationn name"),
});

const NewOrganizationModal = ({ organizationStore, close }) => {
  const { t } = useTranslation();
  const [updating, setUpdating] = useState(false);

  return (
    <section className="modal-wrapper">
      <Modal.Dialog size="lg">
        <Formik
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            name: "",
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            setUpdating(true);
            await organizationStore
              .createOrganization(values)
              .then(() => close());
            setUpdating(false);
            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Modal.Header closeButton onHide={() => close()}>
                <Modal.Title>
                  {t("organizations-screen.new-modal.title")}
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <div className="mb-3">
                  <Field
                    label={t("organizations-screen.new-modal.label-name")}
                    name="name"
                    placeholder={t("organizations-screen.new-modal.label-name")}
                    component={InputFormField}
                  />
                </div>
              </Modal.Body>
              <Modal.Footer className="mt-4">
                <Button onClick={() => close()} variant="link">
                  {t("cancel")}
                </Button>
                <Button type="submit" disabled={isSubmitting} variant="primary">
                  {updating ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    t("save")
                  )}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal.Dialog>
    </section>
  );
};

export default inject("organizationStore")(
  withRouter(observer(NewOrganizationModal))
);
