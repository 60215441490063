export const Landen = [ 
        {label: "Belguim", value: "BE" },
        {label: "Netherlands", value: "NL"},
        {label: "Bulgaria", value: "BG"},
        {label: "Cyprus", value: "CY"},
        {label: "Denmark", value: "DK"},
        {label: "Germany", value: "DE"},
        {label: "Estonia", value: "EE"},
        {label: "Finland", value: "FI"},
        {label: "France", value: "FR"},
        {label: "Greece", value: "GR"},
        {label: "Hungrary", value: "HU"},
        {label: "Ireland", value: "IE"},
        {label: "Italy", value: "IT"},
        {label: "Croatia", value: "HR"},
        {label: "Latvia", value: "LV"},
        {label: "Lithuania", value: "LT"},
        {label: "Luxembourg", value: "LU"},
        {label: "Malta", value: "MT"},
        {label: "Austria", value: "AT"},
        {label: "Poland", value: "PL"},
        {label: "Portugal", value: "PT"},
        {label: "Romania", value: "RO"},
        {label: "Slovenia", value: "SI"},
        {label: "Slovakia", value: "SK"},
        {label: "Spain", value: "ES"},
        {label: "Czech Republic", value: "CZ"},
        {label: "Sweden", value: "SE"},
        {label: "US", value: "US"},
        {label: "Israel", value: "IL"}
    ];