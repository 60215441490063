import React, { useState, useEffect, useRef } from "react";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { Row, Col, Spinner } from "react-bootstrap";

import emptyImg from "../../assets/empty_data.svg";

import Select from "react-select";

import PageSection from "../../components/PageSection";
import GrowthReportTable from "./components/GrowthReportTable";
import GrowthReportChart from "./components/GrowthReportChart";

import { PERMISSIONS } from "../../constants/index";

const CompareReports = ({
  reportsStore,
  surveyStore,
  startupStore,
  authStore,
  organizationStore,
}) => {
  const { t } = useTranslation();

  const [surveyOptions, setSurveyOptions] = useState(null);
  const [snapshotOptions, setSnapshotOptions] = useState(null);
  const [startupOptions, setStartupOptions] = useState(null);
  const [loadingSnapshots, setLoadingSnapshots] = useState(true);
  const [snapshot1Id, setSnapshot1Id] = useState(null);
  const [dataSnapshot1, setDataSnapshot1] = useState(null);
  const [dataSnapshot2, setDataSnapshot2] = useState([]);
  const [loadingDataSnapshot2, setLoadingDataSnapshot2] = useState(false);
  const [selectedStartup, setSelectedStartup] = useState(null);
  const [organizationOptions, setOrganizationOptions] = useState(null);
  const snapshot2Selector = useRef();

  const hasPermission = (permission) => {
    return (
      authStore.user && authStore.user.permissions.find((p) => p === permission)
    );
  };

  const getOrganizationOptions = async () => {
    let newOrganizationOptions = [];
    await organizationStore.setOrganizations().catch((e) => alert(e.message));

    organizationStore.organizations &&
      organizationStore.organizations.map((o) => {
        newOrganizationOptions.push({
          label: o.name,
          value: o.uuid,
        });
      });

    setOrganizationOptions(newOrganizationOptions);
  };

  const getStartupOptions = async (organizationId) => {
    const newStartupOptions = [];

    await startupStore.setStartups().catch((e) => {
      alert(e.message);
    });

    const allStartups = startupStore.startups;
    const filteredStartups = allStartups
      .map((item) => ({
        ...item,
        organizations: item.organizations.filter(
          (org) => org.uuid === organizationId
        ),
      }))
      .filter((startup) => startup.organizations.length > 0);
    filteredStartups.forEach((startup) => {
      newStartupOptions.push({
        label: startup.name,
        value: startup.uuid,
        id: startup.uuid,
      });
    });

    setStartupOptions(newStartupOptions);
  };

  const getSurveyOptions = async () => {
    const allSurveys = await surveyStore.findAll();

    let newSurveyOptions = [];

    allSurveys.forEach((survey) => {
      newSurveyOptions.push({
        label: survey.name,
        value: survey.id,
        id: survey.id,
      });
    });

    setSurveyOptions(newSurveyOptions);
  };

  const getSnapshotOptions = async (surveyId) => {
    setLoadingSnapshots(true);

    const allSnapshots = await reportsStore.findAllByCompleted();
    let newSnapshotOptions = [];

    let filteredSnapshots;

    if (selectedStartup === null) {
      filteredSnapshots = allSnapshots.filter(
        (test) => test.createdBy.uuid === authStore.user.uuid
      );
    } else {
      filteredSnapshots = allSnapshots.filter(
        (s) => s.startup.uuid === selectedStartup.id
      );
    }

    filteredSnapshots
      .filter((s) => s.survey.id === surveyId)
      .forEach((snapshot) => {
        const dateString = moment(snapshot.date).format(
          "MMMM Do YYYY, h:mm:ss a"
        );

        newSnapshotOptions.push({
          label: `${snapshot.survey.name}, ${t("reports-screen.conducted")} ${t(
            "on"
          )} ${dateString} ${t("by")} ${snapshot.createdBy.name}`,
          value: snapshot.id,
          id: snapshot.id,
        });
      });

    setSnapshotOptions(newSnapshotOptions);
    setLoadingSnapshots(false);
  };

  const calculate = async (snapshotId) => {
    const data = [];

    const snapshot = await reportsStore.findById(snapshotId);

    const allPhases = [];
    const allGroups = [];

    snapshot.answers.map((line) => {
      const groupCategory = line.question.groupCategory;

      if (!allGroups.find((g) => g.id === groupCategory.id))
        allGroups.push(groupCategory);
      else return;

      if (!allPhases.find((p) => p.id === groupCategory.phaseCategory.id))
        allPhases.push(groupCategory.phaseCategory);
    });

    allPhases.map((phase) => {
      const groups = [];

      allGroups
        .filter((g) => g.phaseCategory.id === phase.id)
        .map((group) => {
          let positiveKPIs = 0,
            negativeKPIs = 0;

          const lines = snapshot.answers.filter(
            (l) => l.question.groupCategory.id === group.id
          );

          lines.map((l) => {
            if (l.delta === true) positiveKPIs++;
            else if (l.delta === false) negativeKPIs++;
          });

          groups.push({
            name: group.name,
            positiveKPIs,
            negativeKPIs,
            allKPIs: positiveKPIs + negativeKPIs,
          });
        });

      let positiveKPIs = 0,
        negativeKPIs = 0;

      groups.map((g) => {
        positiveKPIs += g.positiveKPIs;
        negativeKPIs += g.negativeKPIs;
      });

      data.push({
        phase: phase.name,
        groups,
        positiveKPIs,
        negativeKPIs,
        allKPIs: positiveKPIs + negativeKPIs,
      });
    });

    return data;
  };

  useEffect(() => {
    hasPermission(PERMISSIONS.REPORTS_GENERATE_GROWTH_ALL)
      ? getOrganizationOptions()
      : hasPermission(PERMISSIONS.REPORTS_GENERATE_GROWTH_ORGANIZATION) &&
        !hasPermission(PERMISSIONS.REPORTS_GENERATE_GROWTH_ALL)
      ? getStartupOptions(authStore.user.organization.uuid)
      : getSurveyOptions();
  }, []);

  return (
    <>
      {hasPermission(PERMISSIONS.REPORTS_GENERATE_GROWTH_ALL) && (
        <PageSection title={t("menu-items.organizations")}>
          <label htmlFor="startup" className="sr-only">
            {t("growth-reports-screen.survey")}
          </label>
          <Select
            id="organization"
            name="organization"
            placeholder={t("placeholderOrgSelect")}
            options={organizationOptions}
            onChange={(e) => {
              setSurveyOptions(null);
              setStartupOptions(null);
              setSnapshotOptions(null);
              setDataSnapshot1(null);
              setSnapshot1Id(null);
              getStartupOptions(e.value);
            }}
          />
        </PageSection>
      )}
      {hasPermission(PERMISSIONS.REPORTS_GENERATE_GROWTH_ORGANIZATION) &&
        startupOptions !== null && (
          <PageSection title={t("menu-items.startups")}>
            <label htmlFor="startup" className="sr-only">
              {t("growth-reports-screen.survey")}
            </label>
            <Select
              id="startup"
              name="startup"
              placeholder={t("placeholderStartupSelect")}
              options={startupOptions}
              onChange={(e) => {
                setSelectedStartup(e);
                getSurveyOptions();
              }}
            />
          </PageSection>
        )}
      {surveyOptions !== null && (
        <PageSection title={t("growth-reports-screen.survey")}>
          <label htmlFor="survey" className="sr-only">
            {t("growth-reports-screen.survey")}
          </label>
          <Select
            id="survey"
            name="survey"
            onChange={(o) => {
              setDataSnapshot1(null);
              setSnapshot1Id(null);
              setSnapshotOptions([]); // indicate loading
              getSnapshotOptions(o.id);
            }}
            options={surveyOptions}
            placeholder={t("growth-reports-screen.start-by-selecting-survey")}
          />
        </PageSection>
      )}

      {snapshotOptions !== null && (
        <Row>
          <Col xs="12" md="6">
            <PageSection
              loading={loadingSnapshots}
              title={t("growth-reports-screen.snapshot")}
            >
              <label htmlFor="snapshot-1" className="sr-only">
                {t("growth-reports-screen.snapshot")}
              </label>
              <Select
                id="snapshot-1"
                name="snapshot-1"
                onChange={(o) => {
                  setDataSnapshot1([]);
                  calculate(o.id).then((d) => {
                    setDataSnapshot1(d);
                    setSnapshot1Id(o.id);
                  });
                }}
                options={snapshotOptions}
                placeholder={t(
                  "growth-reports-screen.continue-by-selecting-snapshot"
                )}
              />
            </PageSection>
          </Col>
          <Col xs="12" md="6">
            <PageSection
              loading={loadingSnapshots}
              title={`${t("growth-reports-screen.snapshot")} 2 (${t(
                "optional"
              )})`}
            >
              <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                  <label htmlFor="snapshot-2" className="sr-only">
                    {t("growth-reports-screen.snapshot")}
                  </label>
                  <Select
                    ref={snapshot2Selector}
                    isDisabled={!snapshot1Id}
                    id="snapshot-2"
                    name="snapshot-2"
                    isClearable={true}
                    onChange={(o) => {
                      setDataSnapshot2([]);
                      if (o === null) return;
                      else {
                        setLoadingDataSnapshot2(true);
                        calculate(o.id).then((d) => {
                          setDataSnapshot2(d);
                          setLoadingDataSnapshot2(false);
                        });
                      }
                    }}
                    options={
                      snapshot1Id
                        ? snapshotOptions.filter((o) => o.id !== snapshot1Id)
                        : []
                    }
                    placeholder={t(
                      "growth-reports-screen.continue-by-selecting-snapshot"
                    )}
                  />
                </div>
                {loadingDataSnapshot2 && (
                  <div class="pl-2">
                    <Spinner variant="primary" animation="border" />
                  </div>
                )}
              </div>
            </PageSection>
          </Col>
        </Row>
      )}
      {dataSnapshot1 !== null ? (
        <>
          <GrowthReportTable {...{ dataSnapshot1, dataSnapshot2 }} />

          {dataSnapshot1.length > 0 && dataSnapshot2.length > 0 && (
            <GrowthReportChart {...{ dataSnapshot1, dataSnapshot2 }} />
          )}
        </>
      ) : (
        <div className="text-center py-5">
          <p className="display-4 w-75 mx-auto text-black-50">
            {t("growth-reports-screen.start-by-selecting-survey")}
          </p>
          <p className="w-75 mx-auto text-black-50">
            {t("growth-reports-screen.growth-report-description")}
          </p>
          <img
            src={emptyImg}
            alt="No snapshots"
            className="img-fluid w-75 mx-auto mt-5"
          />
        </div>
      )}
    </>
  );
};

export default inject(
  `reportsStore`,
  `surveyStore`,
  "startupStore",
  "authStore",
  "organizationStore"
)(observer(CompareReports));
