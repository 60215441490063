import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import bg from "../assets/bg_checkup.png";
import surveyBlockStyles from "./SurveyBlock.module.scss";
import { Row, Col, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { inject } from "mobx-react";

const SurveyBlock = ({ survey, bgcolor, surveyStore }) => {
  const { t } = useTranslation();
  //
  const [hasAnswers, setHasAnswers] = useState(false);

  const getQuestions = async () => {
    await surveyStore.findById(survey.id);
    localStorage.getItem(`values for ${survey.id}`)
      ? setHasAnswers(true)
      : setHasAnswers(false);
  };

  useEffect(() => {
    if (survey && survey.id !== undefined) {
      getQuestions();
    }
  }, []);

  const handleClickDelete = () => {
    localStorage.removeItem(`values for ${survey.id}`);
    localStorage.removeItem(`snapshotId for ${survey.id}`);
    localStorage.removeItem(`count for ${survey.id}`);
    localStorage.removeItem(`offset for ${survey.id}`);
    localStorage.removeItem(`procent for ${survey.id}`);
    setHasAnswers(false);
  };

  return (
    <Card
      style={{ background: `${bgcolor}`, width: "22rem", border: "none" }}
      text={"white"}
      className="shadow-sm"
    >
      <Card.Img
        src={bg}
        className={surveyBlockStyles.img}
        alt="Card image"
        height="160"
      />
      <Card.ImgOverlay>
        {survey && survey.id !== undefined && survey.questionCount !== 0 ? (
          <>
            <Row className={surveyBlockStyles.header}>
              <Col>
                <h3>{survey.name} </h3>
              </Col>
              <Col md="auto">
                <span className="badge badge-pill badge-light">
                  {survey.questionCount} {t("surveyblock.nmbrOfQuestions")}
                </span>
              </Col>
            </Row>
            <>
              {hasAnswers === true ? (
                <Row className={surveyBlockStyles.btonBlockMore}>
                  <Col md="auto">
                    <Button variant="outline-light" onClick={handleClickDelete}>
                      {t("surveyblock.ctaDelete")}
                    </Button>
                  </Col>
                  <Col md="auto">
                    <Link
                      to={`/surveys/${survey.id}/take`}
                      className="btn btn-outline-light"
                    >
                      {t("surveyblock.ctaGo")}
                    </Link>
                  </Col>
                </Row>
              ) : (
                <Row className={surveyBlockStyles.btonBlock}>
                  <Col md="auto">
                    <Link
                      to={`/surveys/${survey.id}/take`}
                      className="btn btn-outline-light"
                    >
                      {t("surveyblock.cta")}
                    </Link>
                  </Col>
                </Row>
              )}
            </>
          </>
        ) : (
          <Row className={surveyBlockStyles.header}>
            <Col>
              <h3>{t("surveyblock.empty")}</h3>
            </Col>
          </Row>
        )}
      </Card.ImgOverlay>
    </Card>
  );
};

export default inject(`surveyStore`)(SurveyBlock);
