import { decorate, observable, action } from "mobx";
import Service from "../services/Service";

class StartupStore {
  startups = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.startupService = new Service(`startups`);
  }

  getById = (id) => {
    return this.startupService.getById(id);
  };

  updateStartup = (id, props) => {
    return this.startupService.replace(id, props);
  };

  createStartup = (props) => {
    return this.startupService.create(props);
  };

  deleteStartup = (id) => {
    return this.startupService.delete(id);
  };

  setStartups = () => {
    return this.startupService.getAll().then((data) => {
      this.startups = data;
    });
  };
}

decorate(StartupStore, {
  startups: observable,
  createStartup: action,
  deleteStartup: action,
  updateStartup: action,
});

export default StartupStore;
