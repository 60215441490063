import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Spinner, Button, Alert } from "react-bootstrap";

import PageSection from "../../../../components/PageSection";

import * as yup from "yup";
import { Formik, Form, Field, FieldArray } from "formik";
import InputFormField from "../../../../components/fields/InputFormField";
import CheckFormField from "../../../../components/fields/CheckFormField";
import SelectFormField from "../../../../components/fields/SelectFormField";
import ReactSelectFormField from "../../../../components/fields/ReactSelectFormField";

const schema = yup.object({
  name: yup.string().required("Please fill in the survey name"),
  type: yup.string().required("Please select a survey type"),
});

const SurveyNew = ({ surveyStore, surveyAdminStore, close, history }) => {
  const [updating, setUpdating] = useState(false);
  const [archiving, setArchiving] = useState(false);
  const { t } = useTranslation();
  const surveyTypeOptions = [
    { label: "STARTUP", value: "STARTUP" },
    { label: "TEAM", value: "TEAM" },
  ];
  const surveyStartupOptions = [
    { label: "Test", value: "test" },
    { label: "pLiz tell me what needs to be here", value: "TEAM" },
  ];

  return (
    <Container className="pt-4">
      <Formik
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{
          name: "",
          type: "",
          surveyCategory: "",
          active: true,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          setUpdating(true);
          const surveyValues = {
            name: values.name,
            type: values.type,
            isActive: values.active,
          };
          surveyStore
            .createSurvey(surveyValues)
            .then((r) =>
              history.push(`/surveys/management/new-questions/${r.id}`)
            );
          setUpdating(false);
          setSubmitting(false);
        }}
      >
        {({ values, isSubmitting }) => (
          <Form>
            <PageSection
              title={t("management-survey.new.title")}
              className="p-2"
            >
              <Field
                label={t("management-survey.new.label-name")}
                name="name"
                component={InputFormField}
              />
              <Field
                label={t("management-survey.new.label-type")}
                options={surveyTypeOptions}
                name="type"
                component={SelectFormField}
              />
              {values.type === "STARTUP" || values.type === "" ? (
                <Field
                  label={t("management-survey.new.label-cat")}
                  options={surveyStartupOptions}
                  disabled
                  name="surveyCategory"
                  component={SelectFormField}
                />
              ) : (
                <Field
                  label={t("management-survey.new.label-cat")}
                  options={surveyStartupOptions}
                  name="surveyCategory"
                  component={SelectFormField}
                />
              )}

              {values.active === true ? (
                <Field
                  label={t("management-survey.new.label-active")}
                  name="active"
                  value="active"
                  checked
                  component={CheckFormField}
                />
              ) : (
                <Field
                  label={t("management-survey.new.label-active")}
                  name="active"
                  value="active"
                  component={CheckFormField}
                />
              )}
            </PageSection>
            <div className="text-right mb-3">
              <Button onClick={() => close()} variant="link">
                {t("cancel")}
              </Button>
              <Button type="submit" disabled={isSubmitting} variant="primary">
                {updating ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  t("next")
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default inject(
  "surveyStore",
  "surveyAdminStore"
)(withRouter(observer(SurveyNew)));
