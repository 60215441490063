import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import SurveyBlock from "../../components/SurveyBlock";
import { Container, Row, Col, Table, Spinner } from "react-bootstrap";

import { observer, inject } from "mobx-react";
import PageHeader from "../../components/PageHeader.jsx";
import { PERMISSIONS } from "../../constants/index";
import { useTranslation } from "react-i18next";
import PageSection from "../../components/PageSection";

import moment from "moment";
import Select from "react-select";
import NavSVG from "../../components/NavSVG";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const StartupDashboard = ({
  authStore,
  reportsStore,
  startupStore,
  organizationStore,
  textStore,
}) => {
  const actions = "";

  const hasPermission = (permission) => {
    return (
      authStore.user && authStore.user.permissions.find((p) => p === permission)
    );
  };

  const [currentSurvey, setCurrentSurvey] = useState(null);
  const [latestSnapshot, setLatestSnapshot] = useState(null);
  const [latestSnapshotAnswers, setLatestSnapshotAnswers] = useState(null);
  const [latestSnapshotId, setLatestSnapshotId] = useState(null);

  const [dataSnapshotKPI, setDataSnapshotKPI] = useState(null);
  const [data, setData] = useState(null);
  const [noData, setNoData] = useState(false);
  const { t } = useTranslation();

  const [organizationOptions, setOrganizationOptions] = useState(null);
  const [startupOptions, setStartupOptions] = useState(null);

  const getOrganizationOptions = async () => {
    let newOrganizationOptions = [];
    await organizationStore.setOrganizations().catch((e) => alert(e.message));

    organizationStore.organizations &&
      organizationStore.organizations.map((o) => {
        newOrganizationOptions.push({
          label: o.name,
          value: o.uuid,
        });
      });

    setOrganizationOptions(newOrganizationOptions);
  };

  const getStartupOptions = async (organizationId) => {
    const newStartupOptions = [];

    await startupStore.setStartups().catch((e) => {
      alert(e.message);
    });

    const allStartups = startupStore.startups;
    const filteredStartups = allStartups
      .map((item) => ({
        ...item,
        organizations: item.organizations.filter(
          (org) => org.uuid === organizationId
        ),
      }))
      .filter((startup) => startup.organizations.length > 0);

    filteredStartups.forEach((startup) => {
      newStartupOptions.push({
        label: startup.name,
        value: startup.uuid,
        id: startup.uuid,
      });
    });

    setStartupOptions(newStartupOptions);
  };

  const latestSnapshotFunc = async (e) => {
    const allSnapshots = await reportsStore.findAllByCompleted();
    let newSnapshots = undefined;

    if (e === undefined) {
      newSnapshots = allSnapshots.filter(
        (test) => test.createdBy.uuid === authStore.user.uuid
      );
    } else {
      newSnapshots = allSnapshots.filter((s) => s.startup.uuid === e.id);
    }

    if (newSnapshots.length === 0) {
      setNoData(true);
    } else {
      setNoData(false);
      const secondLatest = newSnapshots.slice(-1);
      setLatestSnapshotId(secondLatest[0].id);
      setLatestSnapshot(secondLatest[0]);
      const snapshot = await reportsStore.findById(secondLatest[0].id);
      let newData = [];
      snapshot.answers.forEach((line) => {
        let answer;

        switch (line.question.answerGroup.type) {
          case "MULTIPLE_CHOICE":
            let answers = [];
            line.multipleSelectedAnswers.map((a) => answers.push(a.text));
            answer = answers.join(", ");
            break;
          case "SINGLE_CHOICE":
            answer = line.selectedAnswer.text;
            break;
          case "TEXT":
            answer = line.textResponse;
            break;
          case "YES_NO":
            answer = line.selectedAnswer.text === true ? t("Yes") : t("No");
            break;
          default:
            answer = "N/A";
        }
        newData.push({
          id: line.id,
          question: line.question.text,
          answer,
        });
      });

      calculate(secondLatest[0].id).then((d) => {
        berekend(d);
      });
      generateReport(secondLatest[0].id);
      setLatestSnapshotAnswers(newData);
    }
  };

  const berekend = (d) => {
    const data = [];
    d.map((snapshot, key) => {
      data.push({ category: snapshot.phase, KPIs: snapshot.positiveKPIs });
    });

    setData(data);
  };

  const getAnswerForSnapshotLine = (line) => {
    switch (line.question.answerGroup.type) {
      case "MULTIPLE_CHOICE":
        const textAnswers = line.multipleSelectedAnswers.map((a) => a.text);
        return textAnswers.join(", ");
      case "SINGLE_CHOICE":
        return line.selectedAnswer.text;
      case "TEXT":
        return line.textResponse;
      case "YES_NO":
        if (line.selectedAnswer.text === "TRUE") return t("Yes");
        else return t("No");
      default:
        return "N/A";
    }
  };

  const generateReport = async (snapshotId) => {
    let newData = [];
    const snapshot = await reportsStore.findById(snapshotId);
    const evaluatedLines = snapshot.answers.filter((line) => line.question.kpi);

    evaluatedLines.forEach((line) => {
      let record = {};

      record = {
        id: line.id,
        question: line.question.text,
        targetOperator: line.targetOperator,
        delta: line.delta,
        result: getAnswerForSnapshotLine(line),
        group: line.question.groupCategory.name,
        phase: line.question.groupCategory.phaseCategory.name,
      };

      if (line.targetValue) {
        record.target = line.targetValue;
      } else if (line.targetQuestion) {
        const target = snapshot.answers.find(
          (l) => l.question.id === line.targetQuestion
        );

        record.target = getAnswerForSnapshotLine(line);
        record.targetSource = target.question.text;
      }

      newData.push(record);
    });

    setDataSnapshotKPI(newData);
  };

  const calculate = async (snapshotId) => {
    const data = [];

    const snapshot = await reportsStore.findById(snapshotId);

    const allPhases = [];
    const allGroups = [];

    snapshot.answers.map((line) => {
      const groupCategory = line.question.groupCategory;

      if (!allGroups.find((g) => g.id === groupCategory.id))
        allGroups.push(groupCategory);
      else return;

      if (!allPhases.find((p) => p.id === groupCategory.phaseCategory.id))
        allPhases.push(groupCategory.phaseCategory);
    });

    allPhases.map((phase) => {
      const groups = [];

      allGroups
        .filter((g) => g.phaseCategory.id === phase.id)
        .map((group) => {
          let positiveKPIs = 0,
            negativeKPIs = 0;

          const lines = snapshot.answers.filter(
            (l) => l.question.groupCategory.id === group.id
          );

          lines.map((l) => {
            if (l.delta === true) positiveKPIs++;
            else if (l.delta === false) negativeKPIs++;
          });

          groups.push({
            name: group.name,
            positiveKPIs,
            negativeKPIs,
            allKPIs: positiveKPIs + negativeKPIs,
          });
        });

      let positiveKPIs = 0,
        negativeKPIs = 0;

      groups.map((g) => {
        positiveKPIs += g.positiveKPIs;
        negativeKPIs += g.negativeKPIs;
      });

      data.push({
        phase: phase.name,
        groups,
        positiveKPIs,
        negativeKPIs,
        allKPIs: positiveKPIs + negativeKPIs,
      });
    });

    return data;
  };

  useEffect(() => {
    const survey =
      authStore.user && authStore.user.startup && authStore.user.startup.survey;
    setCurrentSurvey(survey);

    textStore.setTexts().then(getText);

    hasPermission(PERMISSIONS.DASHBOARD_SHOW_ALL)
      ? getOrganizationOptions()
      : hasPermission(PERMISSIONS.DASHBOARD_SHOW_ORGANIZATION) &&
        !hasPermission(PERMISSIONS.DASHBOARD_SHOW_ALL)
      ? getStartupOptions(authStore.user.organization.uuid)
      : latestSnapshotFunc();
  }, []);

  const getText = textStore.texts.find((p) => p.page === "DASHBOARD");

  return (
    <>
      <PageHeader
        title={t("menu-items.dashboard")}
        explainer={getText && getText.text}
        link={getText && getText.link}
        actions={actions}
        textLink={`/text/1`}
      />

      <Container>
        <div className="pt-5">
          {hasPermission(PERMISSIONS.DASHBOARD_SHOW_ORGANIZATION) && (
            <>
              {hasPermission(PERMISSIONS.DASHBOARD_SHOW_ALL) && (
                <PageSection title={t("menu-items.organizations")}>
                  <label htmlFor="startup" className="sr-only">
                    {t("growth-reports-screen.survey")}
                  </label>
                  <Select
                    id="organization"
                    name="organization"
                    placeholder={t("placeholderOrgSelect")}
                    options={organizationOptions}
                    onChange={(e) => {
                      setStartupOptions(null);
                      getStartupOptions(e.value);
                    }}
                  />
                </PageSection>
              )}

              {startupOptions !== null && (
                <PageSection title={t("menu-items.startups")}>
                  <label htmlFor="startup" className="sr-only">
                    {t("growth-reports-screen.survey")}
                  </label>
                  <Select
                    id="startup"
                    name="startup"
                    placeholder={t("placeholderStartupSelect")}
                    options={startupOptions}
                    onChange={(e) => latestSnapshotFunc(e)}
                  />
                </PageSection>
              )}
            </>
          )}
          {hasPermission(PERMISSIONS.DASHBOARD_SHOW_STARTUP) &&
          latestSnapshotAnswers !== null ? (
            <>
              <Row className="mt-5">
                <Col>
                  <h2>{t("home-screen.title-checkup")}</h2>
                  {authStore.user.startup ? (
                    <SurveyBlock
                      survey={currentSurvey && currentSurvey}
                      bgcolor="#03A6CC"
                    />
                  ) : (
                    <p className="text-muted small">
                      {t("home-screen.no-checkup")}
                    </p>
                  )}
                </Col>
                <Col>
                  <h2>{t("home-screen.title-growth")}</h2>
                  <PageSection title="" className="p-2">
                    <p className="text-muted small text-center">
                      {t("home-screen.tip-growth")}
                    </p>
                    {data && (
                      <BarChart
                        width={400}
                        height={300}
                        data={data}
                        margin={{
                          top: 5,
                          right: 30,
                          left: -20,
                          bottom: 5,
                        }}
                        barSize={20}
                      >
                        <XAxis dataKey="category" tick={false} />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Bar dataKey="KPIs" fill="#82ca9d" />
                      </BarChart>
                    )}
                  </PageSection>
                </Col>
              </Row>

              <Row className={"mt-5"}>
                <Col>
                  <h2>{t("home-screen.title-snapshot")}</h2>
                  <PageSection title="" className="p-2">
                    <div className="row">
                      <div className="col">
                        <p className="text-muted small">
                          {latestSnapshot && latestSnapshot.survey.name} -{" "}
                          {latestSnapshot &&
                            latestSnapshot.survey.questionCount}{" "}
                          {t("questions")}
                        </p>
                      </div>

                      <div className="col text-right">
                        <p className="text-muted small">
                          {latestSnapshot &&
                            moment(latestSnapshot.date).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )}
                        </p>
                      </div>
                    </div>
                    <Table className="mb-0" responsive>
                      <thead>
                        <tr>
                          <th>{t("result-detail-screen.nmr")}</th>
                          <th>{t("result-detail-screen.question")}</th>
                          <th>{t("result-detail-screen.answer")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {latestSnapshotAnswers &&
                          latestSnapshotAnswers.slice(0, 3).map((line, key) => (
                            <tr key={key}>
                              <td>{key + 1}</td>
                              <td>{line.question}</td>
                              <td>{line.answer}</td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                    <Row>
                      <Col></Col>
                      <Col>
                        <Link
                          to={`results/${latestSnapshotId && latestSnapshotId}`}
                        >
                          {t("home-screen.all-answers")}
                        </Link>
                      </Col>
                      <Col></Col>
                    </Row>
                  </PageSection>
                </Col>
                <Col>
                  <h2>{t("home-screen.title-kpi-report")}</h2>
                  {dataSnapshotKPI !== null && (
                    <PageSection
                      loading={!dataSnapshotKPI.length}
                      title=""
                      className="p-2"
                    >
                      <div className="row">
                        <div className="col">
                          <p className="text-muted small">
                            {latestSnapshot && latestSnapshot.survey.name}
                          </p>
                        </div>

                        <div className="col text-right">
                          <p className="text-muted small">
                            {latestSnapshot &&
                              moment(latestSnapshot.date).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )}
                          </p>
                        </div>
                      </div>
                      <Table className="mb-0" responsive>
                        <thead>
                          <tr>
                            <th>{t("reports-screen.question")}</th>
                            <th>{t("reports-screen.current")}</th>
                            <th>{t("reports-screen.delta")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataSnapshotKPI &&
                            dataSnapshotKPI.slice(0, 3).map((record, key) => (
                              <tr key={key}>
                                <td>
                                  <div>{record.question}</div>
                                </td>
                                <td>
                                  <p className="text-nowrap m-0">
                                    {record.result}
                                  </p>
                                </td>
                                <td className="text-center">
                                  <NavSVG
                                    icon={
                                      record.delta === true
                                        ? "thumbsUp"
                                        : "thumbsDown"
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </PageSection>
                  )}
                </Col>
              </Row>
            </>
          ) : noData === true ? (
            <p>{t("home-screen.no-snapshot")}</p>
          ) : (
            <Spinner
              variant="primary"
              animation="border"
              size="sm"
              className="ml-3 mr-1"
            />
          )}
        </div>
      </Container>
    </>
  );
};

export default inject(
  "authStore",
  "reportsStore",
  "startupStore",
  "organizationStore",
  "textStore"
)(observer(StartupDashboard));
