import React from "react";
import { useTranslation } from "react-i18next";

const IncompatibleSectionWarning = ({ show }) => {
  const { t } = useTranslation();

  return (
    <>
      {show && (
        <div className="bg-white px-5 pt-4 text-danger">
          <b>⚠ {t("permissions.warning.first")}</b>
          <p className="small m-0 text-muted">
            {t("permissions.warning.desc")}
          </p>
        </div>
      )}
    </>
  );
};

export default IncompatibleSectionWarning;
