import React from "react";

import { Spinner } from "react-bootstrap";

const PageSection = ({ title, loading, children, className }) => {
  return (
    <>
      <h2 className="mb-3 text-muted">{title}</h2>
      <div className={"page-section shadow-sm rounded-lg " + className}>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center p-4">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          children
        )}
      </div>
    </>
  );
};

export default PageSection;
