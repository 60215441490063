import { reponseHandlers } from "../api";
import Service from "./Service";

class UserService extends Service {
  constructor() {
    super("users");
  }

  getAllStartups = async (id) => {
    return window.api
      .get(`${this.entity}/${id}/startup`)
      .then(...reponseHandlers);
  };
}

export default UserService;
