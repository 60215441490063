import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import { Container, Row, Col } from "react-bootstrap";
import Select from "react-select";

import PageHeader from "../../components/PageHeader.jsx";
import PageSection from "../../components/PageSection.jsx";
import ProtectedRoute from "../../components/ProtectedRoute";

import { Link, Switch } from "react-router-dom";
import { ROUTES } from "../../constants";

import ProfileEdit from "./components/ProfileEdit";
import ProfileChangePassword from "./components/ProfileChangePassword";

const Profile = ({ authStore, textStore, history }) => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const languageOptions = [
    { label: "Nederlands (Dutch)", value: "nl", id: 0 },
    { label: "English (English)", value: "en", id: 1 },
  ];

  const handleLanguageChange = ({ value }) => {
    changeLanguage(value);
  };

  const user = authStore.user;
  const startup = authStore.startup;

  useEffect(() => {
    textStore.setTexts().then(getText);
  }, []);

  const getText = textStore.texts.find((p) => p.page === "PROFILE");

  return (
    <>
      <PageHeader
        title={t("profile-screen.title")}
        explainer={getText && getText.text}
        link={getText && getText.link}
        textLink={`/p/text/3`}
      />

      <Container className="my-5 pb-5">
        <PageSection
          loading={user == null}
          title={t("profile-screen.profile-data")}
        >
          <p className="display-3">{user && user.name}</p>

          <Row>
            <Col md="7">
              <p>{user && user.email}</p>
              <div className="small text-muted mt-4">
                <ul className="list-unstyled">
                  <li>
                    {t("profile-screen.member")}{" "}
                    {startup && (startup !== null || startup !== undefined)
                      ? "1"
                      : "0"}{" "}
                    {t("profile-screen.member-startups")}
                  </li>
                </ul>
              </div>
            </Col>
            <Col md="5">
              <Link
                className="btn btn-light block rounded-lg w-100"
                to={`/profile/${user.uuid}`}
              >
                {t("profile-screen.edit-profile")}
              </Link>

              <Link
                className="btn btn-light block rounded-lg w-100 mt-2"
                to={`/profile/reset-password/${user.uuid}`}
              >
                {t("profile-screen.change-password")}
              </Link>
            </Col>
          </Row>
        </PageSection>

        {startup && (startup !== null || startup !== undefined) ? (
          <PageSection loading={startup === undefined} title="Your Startup">
            <div className="p-3 d-flex border rounded-lg align-items-center justify-content-between">
              <p className="m-0">{startup.name}</p>
            </div>
          </PageSection>
        ) : (
          ""
        )}

        <PageSection title={t("profile-screen.settings")}>
          <p className="mb-2 font-weight-bold small text-muted">
            {t("profile-screen.language")}
          </p>
          <Select
            onChange={handleLanguageChange}
            value={languageOptions.find((e) => e.value === i18n.language)}
            options={languageOptions}
            placeholder={t("profile-screen.language")}
          />
        </PageSection>
      </Container>

      <Switch>
        <ProtectedRoute
          embedded
          exact
          path="/profile/:id"
          strict
          render={({ match }) => (
            <ProfileEdit
              id={match.params.id}
              close={async () => {
                await history.push(ROUTES.profile);
                authStore.setUser(match.params.id);
              }}
            />
          )}
        />
        <ProtectedRoute
          embedded
          exact
          path="/profile/reset-password/:id"
          strict
          render={({ match }) => (
            <ProfileChangePassword
              id={match.params.id}
              close={async () => {
                await history.push(ROUTES.profile);
                authStore.setUser(match.params.id);
              }}
            />
          )}
        />
      </Switch>
    </>
  );
};

export default inject(`authStore`, "textStore")(observer(Profile));
