import { reponseHandlers } from "../api";

class AuthService {
  login = (data) => {
    return window.api.post("auth/authenticate", data).then(...reponseHandlers);
  };

  requestPasswordReset = (data) => {
    return window.api
      .post("auth/password/forgotten", data)
      .then(...reponseHandlers);
  };

  resetPassword = (data) => {
    return window.api
      .post("auth/password/reset", data)
      .then(...reponseHandlers);
  };
}

export default AuthService;
