import React from "react";
import { useTranslation } from "react-i18next";
import { Accordion, Table, Spinner } from "react-bootstrap";

import PageSection from "../../../components/PageSection";
import NavSVG from "../../../components/NavSVG";

const GrowthReportTable = ({ dataSnapshot1, dataSnapshot2 }) => {
  const { t } = useTranslation();

  return (
    <PageSection
      title={t("growth-reports-screen.report-table")}
      loading={dataSnapshot1.length === 0}
      className="p-2"
    >
      <Table className="mb-0" responsive>
        <thead>
          {dataSnapshot2.length > 0 && (
            <tr>
              <th className="bg-white"></th>
              <th className="bg-white text-center" colSpan="2">
                {t("growth-reports-screen.snapshot")} 1
              </th>
              <th className="bg-white text-center" colSpan="2">
                {t("growth-reports-screen.snapshot")} 2
              </th>
              <th className="bg-white"></th>
            </tr>
          )}
          <tr>
            <th>{t("growth-reports-screen.phase")}</th>
            <th className="text-center">
              <NavSVG icon="Check" />
            </th>
            <th className="text-center">
              <NavSVG icon="Cross" />
            </th>
            {dataSnapshot2.length > 0 && (
              <>
                <th className="text-center">
                  <NavSVG icon="Check" />
                </th>
                <th className="text-center">
                  <NavSVG icon="Cross" />
                </th>
              </>
            )}
            <th className="text-center">
              {t("growth-reports-screen.nmbrOfKpis")}
            </th>
          </tr>
        </thead>
        {dataSnapshot1.map((d1line, phaseKey) => (
          <Accordion as="tbody" key={`phase_${phaseKey}`}>
            <Accordion.Toggle
              tabIndex="0"
              role="button"
              as={"tr"}
              eventKey={`phase_${phaseKey}`}
            >
              <td>{d1line.phase}</td>
              <td className="text-center text-success">
                {d1line.positiveKPIs}
              </td>
              <td className="text-center text-danger">{d1line.negativeKPIs}</td>
              {dataSnapshot2.length > 0 && (
                <>
                  <td className="text-center text-success">
                    {dataSnapshot2[phaseKey].positiveKPIs}
                  </td>
                  <td className="text-center text-danger">
                    {dataSnapshot2[phaseKey].negativeKPIs}
                  </td>
                </>
              )}
              <td className="text-center">{d1line.allKPIs}</td>
            </Accordion.Toggle>
            {d1line.groups.map((group, groupKey) => (
              <Accordion.Collapse
                className="table-row-collapse"
                eventKey={`phase_${phaseKey}`}
                key={groupKey}
              >
                <>
                  <td>
                    <span className="hide-on-change">↳ {group.name}</span>
                    <Spinner
                      className="show-on-change ml-2"
                      variant="primary"
                      animation="border"
                      size="sm"
                    />
                  </td>
                  <td className="text-center text-success hide-on-change">
                    {group.positiveKPIs}
                  </td>
                  <td className="text-center text-danger hide-on-change">
                    {group.negativeKPIs}
                  </td>
                  {dataSnapshot2.length > 0 && (
                    <>
                      <td className="text-center text-success hide-on-change">
                        {dataSnapshot2[phaseKey].groups[groupKey].positiveKPIs}
                      </td>
                      <td className="text-center text-danger hide-on-change">
                        {dataSnapshot2[phaseKey].groups[groupKey].negativeKPIs}
                      </td>
                    </>
                  )}
                  <td className="hide-on-change text-center">
                    {group.allKPIs}
                  </td>
                </>
              </Accordion.Collapse>
            ))}
          </Accordion>
        ))}
      </Table>
    </PageSection>
  );
};

export default GrowthReportTable;
