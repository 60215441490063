import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Modal, Spinner, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";

import BootstrapForm from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";

import * as yup from "yup";
import { Formik, Form, Field } from "formik";
import InputFormField from "../../../components/fields/InputFormField";
import CheckFormField from "../../../components/fields/CheckFormField";
import SelectFormField from "../../../components/fields/SelectFormField";

const schema = yup.object({
  firstName: yup.string().required("Please fill in a first name"),
});

const ProfileEdit = ({
  userStore,
  id,
  startupStore,
  organizationStore,
  close,
}) => {
  const { t } = useTranslation();
  const [user, setUser] = useState(null);
  const [userValues, setUserValues] = useState(null);
  const [updating, setUpdating] = useState(false);

  const [organizationOptions, setOrganizationOptions] = useState([]);
  const [startupOptions, setStartupOptions] = useState([]);
  const [rolesOptions, setRolesOptions] = useState([]);

  useEffect(() => {
    userStore
      .getById(id)
      .then((user) => {
        const oldValues = {
          firstName: user.firstName && user.firstName,
          lastName: user.lastName && user.lastName,
          email: user.email && user.email,
          enabled: user.enabled && user.enabled,
          startupUuid: user.startup === null ? "none" : user.startup.uuid,
          organizationUuid:
            user.organization === null ? "none" : user.organization.uuid,
          role: user.roleId && user.roleId,
          password: "",
        };
        setUserValues(oldValues);
        setUser(user);
      })
      .catch((e) => alert(e.message));

    startupStore
      .setStartups()
      .then(getStartupsOptions)
      .catch((e) => alert(e.message));
    userStore
      .setRoles()
      .then(getRolesOptions)
      .catch((e) => alert(e.message));
    organizationStore
      .setOrganizations()
      .then(getOrganizationOptions)
      .catch((e) => alert(e.message));
  }, []);

  const getStartupsOptions = () => {
    const startupOptions = [{ label: "none", value: "none" }];
    startupStore.startups &&
      startupStore.startups.map((u) => {
        startupOptions.push({ label: u.name, value: u.uuid });
      });
    setStartupOptions(startupOptions);
  };

  const getOrganizationOptions = () => {
    const organizationOptions = [{ label: "none", value: "none" }];
    organizationStore.organizations &&
      organizationStore.organizations.map((u) => {
        organizationOptions.push({ label: u.name, value: u.uuid });
      });
    setOrganizationOptions(organizationOptions);
  };

  const getRolesOptions = () => {
    const roleOptions = [];
    userStore.roles &&
      userStore.roles.map((u) => {
        roleOptions.push({ label: u.name, value: u.id });
      });
    setRolesOptions(roleOptions);
  };

  return (
    <section className="modal-wrapper">
      <Modal.Dialog size="lg">
        {userValues ? (
          <Formik
            validationSchema={schema}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={userValues}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              setUpdating(true);
              let startupValue;
              if (
                values.startupUuid === "none" ||
                values.startupUuid === undefined
              ) {
                startupValue = null;
              } else {
                startupValue = values.startupUuid;
              }

              let organizationValue;
              if (
                values.organizationUuid === "none" ||
                values.organizationUuid === undefined
              ) {
                organizationValue = null;
              } else {
                organizationValue = values.organizationUuid;
              }

              const newValues = {
                email: values.email,
                firstName: values.firstName,
                lastName: values.lastName,
                isEnabled: values.enabled,
                startupUuid: startupValue,
                organizationUuid: organizationValue,
                roleId: Number(values.role),
                password: values.password,
              };

              userStore.updateUser(user.uuid, newValues).then(() => close());
              setUpdating(false);
              setSubmitting(false);
            }}
          >
            {({ values, isSubmitting }) => (
              <Form>
                <Modal.Header closeButton onHide={() => close()}>
                  <Modal.Title>{user && user.name}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <div className="row row-cols-2 mb-3">
                    <div className="col">
                      <Field
                        label={t(
                          "profile-screen.edit-profile-details.label-firstName"
                        )}
                        name="firstName"
                        placeholder="Jane"
                        component={InputFormField}
                      />
                    </div>
                    <div className="col">
                      <Field
                        label={t(
                          "profile-screen.edit-profile-details.label-lastName"
                        )}
                        name="lastName"
                        placeholder="Doe"
                        component={InputFormField}
                      />
                    </div>
                  </div>
                  <div className="row row-cols-2 mb-3">
                    <div className="col">
                      <Field
                        label={t("startup-admin-screen.new-modal.field-email")}
                        name="email"
                        disabled
                        placeholder={t(
                          "startup-admin-screen.new-modal.field-email-placeholder"
                        )}
                        component={InputFormField}
                      />
                    </div>
                    <div className="col mt-2">
                      <BootstrapForm.Label> </BootstrapForm.Label>
                      <div className="p-2 bg-light-green rounded-lg border">
                        <Field
                          type="checkbox"
                          name="enabled"
                          disabled
                          label={t(
                            "profile-screen.edit-profile-details.label-active"
                          )}
                          component={CheckFormField}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row row-cols-2 mb-3">
                    <div className="col">
                      <Field
                        label="Role"
                        name="role"
                        options={rolesOptions}
                        disabled
                        placeholder={t(
                          "startup-admin-screen.edit-modal.field-country-placeholder"
                        )}
                        component={SelectFormField}
                      />
                    </div>
                    {values.role === "2" || values.role === 2 ? (
                      <>
                        {(values.startupUuid = undefined)}
                        <div className="col">
                          <Field
                            label={t(
                              "profile-screen.edit-profile-details.label-org"
                            )}
                            name="organizationUuid"
                            options={organizationOptions}
                            disabled
                            placeholder={t(
                              "startup-admin-screen.edit-modal.field-country-placeholder"
                            )}
                            component={SelectFormField}
                          />
                        </div>
                      </>
                    ) : values.role === "3" || values.role === 3 ? (
                      <>
                        {(values.organizationUuid = undefined)}
                        <div className="col">
                          <Field
                            label="Startup"
                            name="startupUuid"
                            options={startupOptions}
                            disabled
                            placeholder={t(
                              "startup-admin-screen.edit-modal.field-country-placeholder"
                            )}
                            component={SelectFormField}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        {(values.organizationUuid = undefined)}
                        {(values.startupUuid = undefined)}
                      </>
                    )}
                  </div>
                </Modal.Body>

                <Modal.Footer className="mt-4">
                  <Button onClick={() => close()} variant="link">
                    {t("cancel")}
                  </Button>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="primary"
                  >
                    {updating ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      t("save")
                    )}
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        ) : (
          <div className="d-flex justify-content-center align-items center p-5">
            <Spinner variant="primary" animation="border" />
          </div>
        )}
      </Modal.Dialog>
    </section>
  );
};

export default inject(
  "userStore",
  "startupStore",
  "organizationStore"
)(withRouter(observer(ProfileEdit)));
