import React from "react";

const PageUnauthorized = ({ reason, embedded }) => (
  <div
    className={
      "w-100 d-flex justify-content-center align-items-center text-center " +
      (embedded ? "pt-5" : "vh-100")
    }
  >
    <div>
      <h1 className="text-danger">Insufficient permissions</h1>
      <p className="text-black-50 w-75 small mx-auto">{reason}</p>
    </div>
  </div>
);

export default PageUnauthorized;
