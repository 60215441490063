import { PERMISSIONS } from "../../constants";

const sections = {
  Dashboard: [
    {
      title: "Startup",
      explainer: "The user sees the dashboard for her startup.",
      adds: [PERMISSIONS.DASHBOARD_SHOW_STARTUP],
    },
    {
      title: "Organization",
      explainer: "The user sees the dashboard for her organization.",
      adds: [
        PERMISSIONS.DASHBOARD_SHOW_STARTUP,
        PERMISSIONS.DASHBOARD_SHOW_ORGANIZATION,
      ],
    },
    {
      title: "Everything",
      explainer: "The user sees all dashboard components.",
      adds: [
        PERMISSIONS.DASHBOARD_SHOW_STARTUP,
        PERMISSIONS.DASHBOARD_SHOW_ORGANIZATION,
        PERMISSIONS.DASHBOARD_SHOW_ALL,
      ],
    },
  ],
  Surveys: [
    {
      title: "To fill in",
      explainer: "The user can complete surveys for his startups.",
      adds: [
        PERMISSIONS.SURVEYS_TAKE_DREAM_STARTUP,
        PERMISSIONS.SURVEYS_TAKE_TEAM_STARTUP,
      ],
    },
    {
      title: "To see",
      explainer: "The user can view an overview of all surveys.",
      adds: [PERMISSIONS.SURVEYS_LIST_ALL],
    },
    {
      title: "To edit",
      explainer: "The user can edit surveys.",
      adds: [
        PERMISSIONS.SURVEYS_LIST_ALL,
        PERMISSIONS.SURVEYS_CREATE_ALL,
        PERMISSIONS.SURVEYS_UPDATE_ALL,
        PERMISSIONS.SURVEYS_DELETE_ALL,
      ],
    },
  ],
  Questions: [
    {
      title: "To edit",
      explainer: "The user can edit questions.",
      adds: [
        PERMISSIONS.QUESTIONS_LIST_ALL,
        PERMISSIONS.QUESTIONS_CREATE_ALL,
        PERMISSIONS.QUESTIONS_UPDATE_ALL,
        PERMISSIONS.QUESTIONS_DELETE_ALL,
      ],
    },
  ],
  Categories: [
    {
      title: "To edit",
      explainer: "The user can edit the categories.",
      adds: [
        PERMISSIONS.CATEGORIES_LIST_ALL,
        PERMISSIONS.CATEGORIES_CREATE_ALL,
        PERMISSIONS.CATEGORIES_UPDATE_ALL,
        PERMISSIONS.CATEGORIES_DELETE_ALL,
      ],
    },
  ],
  Results: [
    {
      title: "View personal",
      explainer: "The user can view her own results.",
      adds: [PERMISSIONS.RESULTS_LIST_MINE],
    },
    {
      title: "View startup",
      explainer: "The user can view all the results of her startup.",
      adds: [PERMISSIONS.RESULTS_LIST_STARTUP],
    },
    {
      title: "Manage organization",
      explainer:
        "The user can view and delete all results of her organization.",
      adds: [
        PERMISSIONS.RESULTS_LIST_STARTUP,
        PERMISSIONS.RESULTS_LIST_ORGANIZATION,
        PERMISSIONS.RESULTS_DELETE_ORGANIZATION,
      ],
    },
    {
      title: "Manage everything",
      explainer: "The user can view and delete all results.",
      adds: [PERMISSIONS.RESULTS_LIST_ALL, PERMISSIONS.RESULTS_DELETE_ALL],
    },
  ],
  Reports: [
    {
      title: "Startup",
      explainer: "The user can generate all reports of her startup.",
      adds: [
        PERMISSIONS.RESULTS_LIST_STARTUP,
        PERMISSIONS.REPORTS_GENERATE_KPI_STARTUP,
        PERMISSIONS.REPORTS_GENERATE_GROWTH_STARTUP,
      ],
    },
    {
      title: "Organization",
      explainer: "The user can generate all reports of her organization.",
      adds: [
        PERMISSIONS.RESULTS_LIST_ORGANIZATION,
        PERMISSIONS.REPORTS_GENERATE_KPI_ORGANIZATION,
        PERMISSIONS.REPORTS_GENERATE_GROWTH_ORGANIZATION,
      ],
    },
    {
      title: "Everything",
      explainer: "The user can generate all reports.",
      adds: [
        PERMISSIONS.RESULTS_LIST_ORGANIZATION,
        PERMISSIONS.REPORTS_GENERATE_KPI_ALL,
        PERMISSIONS.REPORTS_GENERATE_GROWTH_ALL,
      ],
    },
  ],
  Kanban: [
    {
      title: "Startup",
      explainer: "The user can use the kanban board within her startup.",
      adds: [
        PERMISSIONS.KANBAN_LIST_STARTUP,
        PERMISSIONS.KANBAN_CREATE_STARTUP,
        PERMISSIONS.KANBAN_UPDATE_STARTUP,
        PERMISSIONS.KANBAN_ARCHIVE_STARTUP,
      ],
    },
    {
      title: "Organization",
      explainer: "The user can use all kanban boards within her organization.",
      adds: [
        PERMISSIONS.KANBAN_LIST_ORGANIZATION,
        PERMISSIONS.KANBAN_CREATE_ORGANIZATION,
        PERMISSIONS.KANBAN_UPDATE_ORGANIZATION,
        PERMISSIONS.KANBAN_ARCHIVE_ORGANIZATION,
      ],
    },
    {
      title: "Everything",
      explainer: "The user can use all kanban boards.",
      adds: [
        PERMISSIONS.KANBAN_LIST_ALL,
        PERMISSIONS.KANBAN_CREATE_ALL,
        PERMISSIONS.KANBAN_UPDATE_ALL,
        PERMISSIONS.KANBAN_ARCHIVE_ALL,
      ],
    },
  ],
  Users: [
    {
      title: "Organization",
      explainer: "The user can manage all users of his organization.",
      adds: [
        PERMISSIONS.USERS_LIST_ORGANIZATION,
        PERMISSIONS.USERS_CREATE_ORGANIZATION,
        PERMISSIONS.USERS_UPDATE_ORGANIZATION,
        PERMISSIONS.USERS_DELETE_ORGANIZATION,
      ],
    },
    {
      title: "Everything",
      explainer: "The user can manage all users.",
      adds: [
        PERMISSIONS.USERS_LIST_ALL,
        PERMISSIONS.USERS_CREATE_ALL,
        PERMISSIONS.USERS_UPDATE_ALL,
        PERMISSIONS.USERS_DELETE_ALL,
      ],
    },
  ],
  Permissions: [
    {
      title: "Everything",
      explainer: "The user can manage all rights of other users.",
      adds: [PERMISSIONS.PERMISSIONS_UPDATE_ALL],
    },
    {
      title: "Everything and himself",
      explainer:
        "The user can manage all rights of the user, including himself.",
      adds: [PERMISSIONS.PERMISSIONS_UPDATE_MINE],
    },
  ],
};

export default sections;
