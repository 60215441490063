import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Modal, Spinner, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

import * as yup from "yup";
import { Formik, Form, Field } from "formik";
import InputFormField from "../../../components/fields/InputFormField";

const schema = yup.object({
  name: yup.string().required("Please fill in a Organization name"),
});

const OrganizationDetailModal = ({ organizationStore, id, close }) => {
  const { t } = useTranslation();
  const [updating, setUpdating] = useState(false);
  const [organization, setOrganization] = useState(null);
  const [archiving, setArchiving] = useState(false);

  useEffect(() => {
    organizationStore
      .getById(id)
      .then((organization) => {
        setOrganization(organization);
      })
      .catch((e) => alert(e.message));
  }, []);

  const handleDeleteOrganization = () => {
    setArchiving(true);

    organizationStore
      .deleteOrganization(id)
      .then(setArchiving(false))
      .then(() => close());
  };

  return (
    organization && (
      <section className="modal-wrapper">
        <Modal.Dialog size="lg">
          <Formik
            validationSchema={schema}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{ name: organization.name }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              setUpdating(true);
              await organizationStore
                .updateOrganization(id, values)
                .then(() => close());
              setUpdating(false);
              setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Modal.Header closeButton onHide={() => close()}>
                  <Modal.Title>
                    {t("organizations-screen.edit-modal.title")}
                  </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <div className="mb-3">
                    <Field
                      label={t("organizations-screen.edit-modal.label-name")}
                      name="name"
                      placeholder={t(
                        "organizations-screen.edit-modal.placeholder"
                      )}
                      component={InputFormField}
                    />
                  </div>
                  <div className="text-right">
                    <Button
                      onClick={() => {
                        window.confirm(t("sure")) && handleDeleteOrganization();
                      }}
                      variant="link"
                      className="text-danger p-0 btn-sm"
                    >
                      {archiving ? (
                        <Spinner
                          animation="border"
                          size="sm"
                          variant="danger"
                        />
                      ) : (
                        ""
                      )}
                      {t("kanban-screen.edit-modal.archive")}
                    </Button>
                  </div>
                </Modal.Body>

                <Modal.Footer className="mt-4">
                  <Button onClick={() => close()} variant="link">
                    {t("cancel")}
                  </Button>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="primary"
                  >
                    {updating ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      t("save")
                    )}
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Dialog>
      </section>
    )
  );
};

export default inject("organizationStore")(
  withRouter(observer(OrganizationDetailModal))
);
