import { Provider } from "mobx-react";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./containers/App";
import * as serviceWorker from "./serviceWorker";
import Store from "./store";

import api from "./api";
import "./containers/i18n";
import "./styles/main.scss";

window.api = api;

const store = new Store();

ReactDOM.render(
  <Provider {...store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,

  document.getElementById(`root`)
);
serviceWorker.register();
