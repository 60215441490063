import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Spinner, Button, Alert } from "react-bootstrap";

import PageSection from "../../../../components/PageSection";

import { Formik, Form, Field, FieldArray } from "formik";
import InputFormField from "../../../../components/fields/InputFormField";

const KpiAdjustments = ({ surveyAdminStore, id, close }) => {
  const [updating, setUpdating] = useState(false);
  const [questionTitle, setQuestionTitle] = useState(null);
  const [allKpiAdjustments, setAllKpiAdjustments] = useState(null);
  const [kpiAnswersNotJetInDb, setKpiAnswersNotJetInDb] = useState(null);
  const [show, setShow] = useState(false);
  const [question, setQuestion] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    surveyAdminStore
      .getAllQuestionsById(id)
      .then((q) => {
        setQuestionTitle(q.text);
        setKpiAnswersNotJetInDb(q.answerGroup.answers);
        setQuestion(q);
      })
      .catch((e) => alert(e.message));
    surveyAdminStore
      .setKpiAdjustments()
      .then((q) => setAllKpiAdjustments(q))
      .catch((e) => {
        alert(e.message);
      });
  }, []);

  const filteredKpiAdjustments =
    allKpiAdjustments &&
    allKpiAdjustments.filter((f) => {
      return f.question.id === Number(id);
    });

  return (
    <Container className="pt-4">
      {show ? (
        <Alert variant="danger" onClose={() => setShow(false)} dismissible>
          <p>{t("kpi-adjustments.warning")}</p>
        </Alert>
      ) : (
        <></>
      )}
      {filteredKpiAdjustments ? (
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            options:
              filteredKpiAdjustments.length === 0
                ? kpiAnswersNotJetInDb
                : filteredKpiAdjustments,
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            setUpdating(true);
            let tel = 0;

            values.options.map((o) => {
              if (o.value) {
                tel = tel + 1;
              }
            });

            if (filteredKpiAdjustments.length === 0) {
              if (kpiAnswersNotJetInDb.length === values.options.length) {
                // set the kpi value of the question true
                const newValues = {
                  text: question.text,
                  tooltip: question.tooltip,
                  kpi: true,
                  hasOther: question.hasOther,
                  answerGroup: { id: question.answerGroup.id },
                  groupCategory: { id: question.groupCategory.id },
                  classification: "UNIVERSAL",
                };
                surveyAdminStore.updateAllQuestions(question.id, newValues);

                // save the values
                values.options.map((o) => {
                  const newValues = {
                    answer: o.id,
                    question: Number(id),
                    value: o.value,
                  };
                  surveyAdminStore
                    .createKpiAdjustments(newValues)
                    .then(() => close());
                });
              } else {
                setShow(true);
              }
            } else {
              if (filteredKpiAdjustments.length === tel) {
                values.options.map((o) => {
                  const newValues = {
                    answer: { id: o.id },
                    question: { id: Number(id) },
                    value: o.value,
                  };
                  surveyAdminStore
                    .updateKpiAdjustments(o.id, newValues)
                    .then(() => close());
                });
              } else {
                setShow(true);
              }
            }
            setUpdating(false);
            setSubmitting(false);
          }}
        >
          {({ values, isSubmitting }) => (
            <Form>
              <PageSection
                title={
                  questionTitle &&
                  t("kpi-adjustments.title") + " " + questionTitle
                }
                className="p-2"
              >
                <div className="row">
                  <div className="col">{t("kpi-adjustments.label-ao")}</div>
                  <div className="col">{t("kpi-adjustments.label-kpi")}</div>
                </div>
                <FieldArray
                  name="options"
                  render={(arrayHelpers) => (
                    <div>
                      {values.options && filteredKpiAdjustments.length !== 0
                        ? values.options.map((option, index) => (
                            <div key={index} className="row">
                              <div className="col">
                                <Field
                                  disabled
                                  name={`options.${index}.answer.text`}
                                  component={InputFormField}
                                />
                              </div>
                              <div className="col">
                                <Field
                                  type="number"
                                  min="-1"
                                  name={`options.${index}.value`}
                                  component={InputFormField}
                                />
                              </div>
                            </div>
                          ))
                        : values.options.map((option, index) => (
                            <div key={index} className="row">
                              <div className="col">
                                <Field
                                  disabled
                                  name={`options.${index}.text`}
                                  component={InputFormField}
                                />
                              </div>
                              <div className="col">
                                <Field
                                  type="number"
                                  min="-1"
                                  name={`options.${index}.value`}
                                  component={InputFormField}
                                />
                              </div>
                            </div>
                          ))}
                    </div>
                  )}
                />
              </PageSection>
              <div className="text-right mb-3">
                <Button onClick={() => close()} variant="link">
                  {t("cancel")}
                </Button>
                <Button type="submit" disabled={isSubmitting} variant="primary">
                  {updating ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    t("save")
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <div className="d-flex justify-content-center align-items center p-5">
          <Spinner variant="primary" animation="border" />
        </div>
      )}
    </Container>
  );
};

export default inject("surveyAdminStore")(withRouter(observer(KpiAdjustments)));
