import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { PERMISSIONS } from "../../../constants";

import { Modal, Button, Form } from "react-bootstrap";

const CustomPermissionsModal = ({
  user,
  shown,
  section,
  updatePermissions,
  close,
}) => {
  const { t } = useTranslation();

  const [selectedPermissions, setSelectedPermissions] = useState([]);

  useEffect(() => {
    if (user) {
      const intialSelected = user.permissions.filter((p) =>
        p.startsWith(section.toUpperCase())
      );
      setSelectedPermissions(intialSelected);
    }
  }, [shown, user, section]);

  const handleSave = () => {
    updatePermissions(section, selectedPermissions);
    close();
  };

  const options = () => {
    const allPermissions = Object.values(PERMISSIONS);
    return allPermissions.filter((p) => p.startsWith(section.toUpperCase()));
  };

  const removeOption = (option) => {
    let permissions = selectedPermissions;
    const index = permissions.indexOf(option);
    if (index > -1) {
      permissions.splice(index, 1);
    }

    setSelectedPermissions(
      permissions.length > 0 ? [...new Set(permissions)] : [...new Set([])]
    );
  };

  const addOption = (option) => {
    setSelectedPermissions([...new Set([option, ...selectedPermissions])]);
  };

  return (
    <section
      id="custom-permissions"
      className={classNames({ "d-none": !shown, "modal-wrapper": true })}
    >
      <Modal.Dialog>
        <Modal.Header closeButton onHide={close}>
          <Modal.Title>{t("permissions.custom.title")}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <ul className="list-unstyled">
            {options().map((o, key) => (
              <li key={key}>
                <div className="mb-1">
                  <Form.Check
                    checked={selectedPermissions.includes(o)}
                    onChange={() => {
                      if (selectedPermissions.includes(o)) removeOption(o);
                      else addOption(o);
                    }}
                    custom
                    type="checkbox"
                    id={`cpm_p_${key}`}
                    label={o}
                  />
                </div>
              </li>
            ))}
          </ul>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={close} variant="link">
            {t("cancel")}
          </Button>
          <Button onClick={handleSave} type="submit" variant="primary">
            {t("save")}
          </Button>
        </Modal.Footer>
      </Modal.Dialog>
    </section>
  );
};

export default CustomPermissionsModal;
