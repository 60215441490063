import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Spinner, Button, Alert } from "react-bootstrap";

import PageSection from "../../../../components/PageSection";

import * as yup from "yup";
import { Formik, Form, Field, FieldArray } from "formik";
import InputFormField from "../../../../components/fields/InputFormField";
import CheckFormField from "../../../../components/fields/CheckFormField";
import ReactSelectFormField from "../../../../components/fields/ReactSelectFormField";
import SelectFormField from "../../../../components/fields/SelectFormField";

const schema = yup.object({
  name: yup.string().required("Please fill in the survey name"),
});

const SurveyEdit = ({ surveyStore, id, surveyAdminStore, close }) => {
  const [survey, setSurvey] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [archiving, setArchiving] = useState(false);
  const { t } = useTranslation();
  const [questions, setQuestions] = useState(null);
  const [questionOptions, setQuestionOptions] = useState(null);

  const surveyStartupOptions = [
    { label: "Test", value: "test" },
    { label: "pLiz tell me what needs to be here", value: "TEAM" },
  ];

  useEffect(() => {
    surveyStore
      .getById(id)
      .then((survey) => setSurvey(survey))
      .catch((e) => alert(e.message));
    surveyAdminStore
      .setAllQuestions()
      .then(getQuestionOptions)
      .catch((e) => alert(e.message));
  }, []);

  const getQuestionOptions = () => {
    const questionOptions = [];
    surveyAdminStore.allQuestions &&
      surveyAdminStore.allQuestions.map((u) => {
        questionOptions.push({ label: u.text, value: u.id });
      });
    setQuestionOptions(questionOptions);
  };

  const handleDeleteSurveyQuestion = (questionId) => {
    setArchiving(true);
    surveyAdminStore
      .deleteSurveyQuestions(questionId)
      .catch((e) => console.log(e.message));
    setArchiving(false);
  };

  return (
    <Container className="pt-4">
      {survey ? (
        <Formik
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            name: survey.name,
            type: survey.type,
            surveyCategory: "",
            active: survey.active,
            questions: survey.questions,
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            setUpdating(true);
            const surveyValues = {
              name: values.name,
              type: values.type,
              questionCount: values.questions.length,
              isActive: values.active,
            };
            surveyStore.updateSurvey(id, surveyValues);

            values.questions.map((o) => {
              const newSurveyQuestions = {
                page: o.page,
                position: o.position,
                question: Number(o.question.id),
                survey: Number(id),
              };
              if (survey.questions.includes(o)) {
                surveyAdminStore
                  .updateSurveyQuestions(o.id, newSurveyQuestions)
                  .then(() => close());
              } else {
                surveyAdminStore
                  .createSurveyQuestions(newSurveyQuestions)
                  .then(() => close());
              }
            });

            if (values.questions.length === 0) {
              close();
            }
            setUpdating(false);
            setSubmitting(false);
          }}
        >
          {({ values, isSubmitting }) => (
            <Form>
              <PageSection
                title={survey && survey.name + " " + "Details"}
                className="p-2"
              >
                <Field
                  label={t("management-survey.new.label-name")}
                  name="name"
                  component={InputFormField}
                />
                <Field
                  label={t("management-survey.new.label-type")}
                  disabled
                  name="type"
                  component={InputFormField}
                />
                {values.type === "STARTUP" || values.type === "" ? (
                  <Field
                    label={t("management-survey.new.label-cat")}
                    options={surveyStartupOptions}
                    disabled
                    name="surveyCategory"
                    component={SelectFormField}
                  />
                ) : (
                  <Field
                    label={t("management-survey.new.label-cat")}
                    options={surveyStartupOptions}
                    name="surveyCategory"
                    component={SelectFormField}
                  />
                )}
                {values.active === true ? (
                  <Field
                    label={t("management-survey.new.label-active")}
                    name="active"
                    value="active"
                    checked
                    component={CheckFormField}
                  />
                ) : (
                  <Field
                    label={t("management-survey.new.label-active")}
                    name="active"
                    value="active"
                    component={CheckFormField}
                  />
                )}
              </PageSection>

              <PageSection
                title={
                  survey &&
                  survey.name + " " + t("management-survey.edit.title")
                }
                className="p-2"
              >
                <div className="row">
                  <div className="col-6">
                    {t("management-survey.edit.label-question")}
                  </div>
                  <div className="col-2">
                    {t("management-survey.edit.label-page")}
                  </div>
                  <div className="col-2">
                    {t("management-survey.edit.label-pos")}
                  </div>
                </div>
                <FieldArray
                  name="questions"
                  render={(arrayHelpers) => (
                    <div>
                      {values.questions &&
                        values.questions.map((q, index) => (
                          <div key={index} className="row">
                            <div className="col-6 pt-4">
                              <Field
                                name={`questions.${index}.question.id`}
                                options={questionOptions}
                                component={ReactSelectFormField}
                              />
                            </div>
                            <div className="col-2">
                              <Field
                                type="number"
                                min="0"
                                name={`questions.${index}.page`}
                                component={InputFormField}
                              />
                            </div>
                            <div className="col-2">
                              <Field
                                type="number"
                                min="0"
                                name={`questions.${index}.position`}
                                component={InputFormField}
                              />
                            </div>
                            <div className="col pt-4">
                              <Button
                                variant="outline-secondary"
                                onClick={() => {
                                  handleDeleteSurveyQuestion(q.id);
                                  arrayHelpers.remove(index);
                                }}
                              >
                                -
                              </Button>
                            </div>
                          </div>
                        ))}
                      <Button
                        variant="outline-primary"
                        className="mt-4 w-100"
                        onClick={() => arrayHelpers.push("")}
                      >
                        +
                      </Button>
                    </div>
                  )}
                />
              </PageSection>
              <div className="text-right mb-3">
                <Button onClick={() => close()} variant="link">
                  {t("cancel")}
                </Button>
                <Button type="submit" disabled={isSubmitting} variant="primary">
                  {updating ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    t("save")
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <div className="d-flex justify-content-center align-items center p-5">
          <Spinner variant="primary" animation="border" />
        </div>
      )}
    </Container>
  );
};

export default inject(
  "surveyStore",
  "surveyAdminStore"
)(withRouter(observer(SurveyEdit)));
