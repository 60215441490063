import React from "react";
import { useTranslation } from "react-i18next";
import eye from "../../../assets/icons/eye.svg";

const KanbanCard = ({ content, editCard }) => {
  let body = null;
  const { t } = useTranslation();

  if (content.infoCard === true) {
    body = (
      <>
        <div className="text-align-center d-flex flex-column align-items-center justify-content-center py-5">
          <h3 className="text-center">{t("kanban-screen.none-modal.title")}</h3>
          <p className="text-center text-muted small w-75 my-2">
            {t("kanban-screen.none-modal.desc")}
            <span className="font-italic">
              {" "}
              "{t("kanban-screen.none-modal.button")}"{" "}
            </span>
            .
          </p>
        </div>
      </>
    );
  } else {
    body = (
      <>
        <button className="edit btn btn-sm btn-light" onClick={editCard}>
          <span className="sr-only">{t("edit")}</span>
          <img src={eye} alt="Eye" />
        </button>
        <h3>{content.title}</h3>
        <p className="text-muted small">{content.description}</p>
      </>
    );
  }

  return <div className="kanban-card">{body}</div>;
};

export default KanbanCard;
