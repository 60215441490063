import { decorate, observable, action } from "mobx";
import Service from "../services/Service";

class SurveyAdminStore {
  questions = [];
  answers = [];
  options = [];
  groupCategories = [];
  phaseCategories = [];
  allQuestions = [];
  kpiAdjustments = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.surveyQuestionService = new Service(`survey-questions`);
    this.answerService = new Service(`answer-groups`);
    this.answerOptionsService = new Service(`answer-options`);
    this.groupCategoriesService = new Service(`group-categories`);
    this.phaseCategoriesService = new Service(`phase-categories`);
    this.questionService = new Service(`questions`);
    this.kpiAdjustmentService = new Service(`kpi-adjustments`);
  }

  // survey questions
  createSurveyQuestions = (props) => {
    return this.surveyQuestionService.create(props);
  };

  updateSurveyQuestions = (id, props) => {
    return this.surveyQuestionService.replace(id, props);
  };

  deleteSurveyQuestions = (id) => {
    return this.surveyQuestionService.delete(id);
  };

  setQuestions = () => {
    return this.surveyQuestionService
      .getAll()
      .then((questions) => (this.questions = questions));
  };

  getById = (id) => this.surveyQuestionService.getById(id);

  // Answer groups
  createAnswerGroup = (props) => {
    return this.answerService.create(props);
  };

  updateAnswerGroup = (id, props) => {
    return this.answerService.replace(id, props);
  };

  deleteAnswerGroup = (id) => {
    return this.answerService.delete(id);
  };

  getAnswerById = (id) => this.answerService.getById(id);

  setAnswers = () => {
    return this.answerService
      .getAll()
      .then((answers) => (this.answers = answers));
  };

  // Answer group options
  setAnswersOptions = () => {
    return this.answerOptionsService
      .getAll()
      .then((options) => (this.options = options));
  };

  updateAnswersOptions = (id, props) => {
    return this.answerOptionsService.replace(id, props);
  };

  createAnswersOptions = (props) => {
    return this.answerOptionsService.create(props);
  };

  deleteAnswerOption = (id) => {
    return this.answerOptionsService.delete(id);
  };

  // Group Categories
  createGroupCategory = (props) => {
    return this.groupCategoriesService.create(props);
  };

  updateGroupCategory = (id, props) => {
    return this.groupCategoriesService.replace(id, props);
  };

  deleteGroupCategory = (id) => {
    return this.groupCategoriesService.delete(id);
  };

  getGroupCategoryById = (id) => this.groupCategoriesService.getById(id);

  setGroupCategories = () => {
    return this.groupCategoriesService
      .getAll()
      .then((group) => (this.groupCategories = group));
  };

  // Phase Categories
  createPhaseCategory = (props) => {
    return this.phaseCategoriesService.create(props);
  };

  updatePhaseCategory = (id, props) => {
    return this.phaseCategoriesService.replace(id, props);
  };

  deletePhaseCategory = (id) => {
    return this.phaseCategoriesService.delete(id);
  };

  getPhaseCategoryById = (id) => this.phaseCategoriesService.getById(id);

  setPhaseCategories = () => {
    return this.phaseCategoriesService
      .getAll()
      .then((phase) => (this.phaseCategories = phase));
  };

  // questions
  createAllQuestions = (props) => {
    return this.questionService.create(props);
  };

  updateAllQuestions = (id, props) => {
    return this.questionService.replace(id, props);
  };

  deleteAllQuestions = (id) => {
    return this.questionService.delete(id);
  };

  getAllQuestionsById = (id) => this.questionService.getById(id);

  setAllQuestions = () => {
    return this.questionService.getAll().then((q) => (this.allQuestions = q));
  };

  // questions
  createKpiAdjustments = (props) => {
    return this.kpiAdjustmentService.create(props);
  };

  updateKpiAdjustments = (id, props) => {
    return this.kpiAdjustmentService.replace(id, props);
  };

  deleteKpiAdjustments = (id) => {
    return this.kpiAdjustmentService.delete(id);
  };

  getKpiAdjustmentsById = (id) => this.kpiAdjustmentService.getById(id);

  setKpiAdjustments = () => {
    return this.kpiAdjustmentService
      .getAll()
      .then((k) => (this.kpiAdjustments = k));
  };

  // description texts
}

decorate(SurveyAdminStore, {
  questions: observable,
  answers: observable,
  options: observable,
  groupCategories: observable,
  phaseCategories: observable,
  allQuestions: observable,
  kpiAdjustments: observable,
  setQuestions: action,
  setAnswers: action,
  setGroupCategories: action,
  setPhaseCategories: action,
  setAllQuestions: action,
  setKpiAdjustments: action,
});

export default SurveyAdminStore;
