import React from "react";
import { Link, useHistory } from "react-router-dom";

import { Nav } from "react-bootstrap";

const SecondaryMenu = ({ items }) => {
  let history = useHistory();

  return (
    <Nav className="secondary-menu">
      {items.map((item, key) => (
        <Nav.Item
          key={key}
          className={history.location.pathname === item.path && "active"}
        >
          <Link className="nav-link" to={item.path}>
            {item.title}
          </Link>
        </Nav.Item>
      ))}
    </Nav>
  );
};

export default SecondaryMenu;
