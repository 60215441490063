import { decorate, observable } from "mobx";
import Service from "../services/Service";

class KanbanStore {
  tasks = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.kanbanTasksService = new Service(`kanban/tasks`);
    this.kanbanTasksAdminService = new Service(`kanban/tasks/startup`);
  }

  getById = (id) => {
    return this.kanbanTasksService.getById(id).then((data) => {
      return data;
    });
  };

  updateTask = (id, props) => {
    return this.kanbanTasksService.replace(id, props);
  };

  createTask = (props) => {
    return this.kanbanTasksService.create(props);
  };

  createTaskAdmin = (startupId, props) =>{
    console.log(startupId, props)
    return this.kanbanTasksService.createKanbanAdmin(startupId, props);
  }

  deleteTask = (taskId) => {
    return this.kanbanTasksService.delete(taskId);
  };

  getAllKanbanTasks = async () => {
    const allTasks = [];
    await this.kanbanTasksService.getAll().then((data) => {
      data.forEach((d) => {
        allTasks.push(d);
      });
    });

    const tasks = allTasks;
    return tasks;
  };

  getAllKanbanTasksAdmin = async (startupId) => {
    return this.kanbanTasksAdminService.getById(startupId);
  };

  getAll = () => {
    this.kanbanTasksService.getAll().then((data) => {
      data.forEach((d) => {
        this.tasks.push(d);
      });
    });
  };
}

decorate(KanbanStore, {
  tasks: observable,
});

export default KanbanStore;
