import React from "react";
import { ROUTES } from "../../constants";
import { useHistory } from "react-router-dom";
import { inject, observer } from "mobx-react";

import LoginForm from "../../components/auth/LoginForm";
import ForgotPasswordForm from "../../components/auth/forgot/ForgotPasswordForm";
import SuccesForgotPassword from "../../components/auth/succes/SuccesForgotPassword";

import Logo_mywayfinder from "../../assets/logo_full.jpg";
import bg from "../../assets/test.svg";
import bgForgot from "../../assets/bg_psword.svg";
import bgSucces from "../../assets/bg_succes.svg";

const Login = ({ authStore }) => {
  let history = useHistory();

  // if (authStore.user) history.push(ROUTES.dashboard);

  switch (history.location.pathname) {
    case ROUTES.passwordForgot:
      return (
        <section className="auth-box">
          <div className="position-absolute">
            <img src={bgForgot} className="bgImg" alt="Background" />
          </div>
          <section className="auth-form-wrapper shadow-sm card p-4">
            <img src={Logo_mywayfinder} width="220" height="46" alt="Logo" />
            <ForgotPasswordForm />
          </section>
        </section>
      );
    case ROUTES.succesForgot:
      return (
        <section className="auth-box">
          <div className="position-absolute">
            <img src={bgSucces} className="bgImg" alt="Background" />
          </div>
          <section className="auth-form-wrapper shadow-sm card p-4">
            <img src={Logo_mywayfinder} width="220" height="46" alt="Logo" />
            <SuccesForgotPassword />
          </section>
        </section>
      );
    default:
      return (
        <section className="auth-box">
          <div className="position-absolute">
            <img src={bg} className="bgImg" alt="Background" />
          </div>
          <section className="auth-form-wrapper shadow-sm card p-4">
            <div className="text-center pb-4">
              <img src={Logo_mywayfinder} width="220" height="46" alt="Logo" />
            </div>
            <LoginForm />
          </section>
        </section>
      );
  }
};

export default inject("authStore")(observer(Login));
