import { decorate, observable, action } from "mobx";

class Survey {
  constructor(id, name, type, questionCount, active) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.questionCount = questionCount;
    this.active = active;
  }

  setId = (value) => (this.id = value);
  setName = (value) => (this.name = value);
  setType = (value) => (this.type = value);
  setQuestionCount = (value) => (this.questionCount = value);
  setActive = (value) => (this.active = value);

  updateFromServer = (values) => {
    if (values.id) this.setId(values.id);
    this.setName(values.name);
    this.setType(values.type);
    this.setQuestionCount(values.questionCount);
    this.setActive(values.active);
  };

  get values() {
    return {
      id: this.id,
      name: this.name,
      type: this.type,
      questionCount: this.questionCount,
      active: this.active,
    };
  }
}

decorate(Survey, {
  id: observable,
  name: observable,
  type: observable,
  questionCount: observable,
  active: observable,
  setId: action,
  setName: action,
  setType: action,
  setQuestions: action,
  setActive: action,
});

export default Survey;
