import { decorate, observable } from "mobx";
import Service from "../services/Service";
class ReportsStore {
  reports = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.snapshotService = new Service(`snapshots`);
    this.snapshotPrintService = new Service(`snapshots/printReport`);
  }

  getById = (id) => {
    return this.snapshotService.getById(id);
  };

  getByPrintId = (id) => {
    return this.snapshotPrintService.downloadFileRequest(id);
  };

  findAll = () => {
    const reports = this.reports;
    return reports;
  };

  findAllByCompletedAndSurvey = async (surveyName) => {
    const allReports = [];

    await this.snapshotService.getAll().then((data) => {
      data.forEach((d) => {
        allReports.push(d);
      });
    });

    const reports = allReports.filter((test) => test.completed === true);
    const reportFilteredBySurvey = reports.filter(
      (test) => test.survey.name === surveyName
    );

    return reportFilteredBySurvey;
  };

  findAllByCompleted = async () => {
    const allReports = [];

    await this.snapshotService.getAll().then((data) => {
      data.forEach((d) => {
        allReports.push(d);
      });
    });

    const reports = allReports.filter((test) => test.completed === true);

    return reports;
  };

  findById = (id) => {
    return this.snapshotService.getById(id);
  };

  findByIds = async (testIds) => {
    let details = [];
    testIds.forEach(async (d) => {
      const snapshot = await this.snapshotService.getById(d);
      details.push(snapshot);
    });
    return details;
  };

  updateSnapshot = (id, prop) => {
    return this.snapshotService.update(id, prop);
  };

  getAll = () => {
    this.snapshotService.getAll().then((data) => {
      data.map((d) => {
        this.reports.push(d);
      });
    });
  };
}

decorate(ReportsStore, {
  reports: observable,
});

export default ReportsStore;
