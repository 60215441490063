import AuthStore from "./AuthStore";
import UserStore from "./UserStore";
import SurveyStore from "./SurveyStore";
import ReportsStore from "./ReportsStore";
import KanbanStore from "./KanbanStore";
import SurveyAdminStore from "./SurveyAdminStore";

import StartupStore from "./StartupStore";
import OrganizationStore from "./OrganizationStore";
import TextStore from "./TextStore";
class Store {
  constructor() {
    this.authStore = new AuthStore(this);
    this.userStore = new UserStore(this);
    this.surveyStore = new SurveyStore(this);
    this.reportsStore = new ReportsStore(this);
    this.kanbanStore = new KanbanStore(this);
    this.surveyAdminStore = new SurveyAdminStore(this);
    this.startupStore = new StartupStore(this);
    this.organizationStore = new OrganizationStore(this);
    this.textStore = new TextStore(this);
  }
}

export default Store;
