import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { sprintf } from "sprintf-js";
import ReactHtmlParser from "react-html-parser";

import { Container, Table } from "react-bootstrap";

import emptyImg from "../../assets/empty_data.svg";
import PageHeader from "../../components/PageHeader";
import PageSection from "../../components/PageSection";

import { PERMISSIONS } from "../../constants/index";
import Select from "react-select";

const Results = ({
  authStore,
  reportsStore,
  startupStore,
  organizationStore,
  textStore,
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  const [startupOptions, setStartupOptions] = useState(null);
  const [organizationOptions, setOrganizationOptions] = useState(null);

  const hasPermission = (permission) => {
    return (
      authStore.user && authStore.user.permissions.find((p) => p === permission)
    );
  };

  const getOrganizationOptions = async () => {
    setData([]);

    let newOrganizationOptions = [];
    await organizationStore.setOrganizations().catch((e) => alert(e.message));

    organizationStore.organizations &&
      organizationStore.organizations.map((o) => {
        newOrganizationOptions.push({
          label: o.name,
          value: o.uuid,
        });
      });

    setOrganizationOptions(newOrganizationOptions);
  };

  const getStartupOptions = async (organizationId) => {
    const newStartupOptions = [];

    await startupStore.setStartups().catch((e) => {
      alert(e.message);
    });

    const allStartups = startupStore.startups;
    const filteredStartups = allStartups
      .map((item) => ({
        ...item,
        organizations: item.organizations.filter(
          (org) => org.uuid === organizationId
        ),
      }))
      .filter((startup) => startup.organizations.length > 0);

    filteredStartups.map((startup) => {
      newStartupOptions.push({
        label: startup.name,
        value: startup.uuid,
      });
    });

    setStartupOptions(newStartupOptions);
  };

  const getData = async (e) => {
    setData(null); // indicates loading

    let newData = [];

    const allSnapshots = await reportsStore.findAllByCompleted();
    let filteredSnapshots;

    if (e === undefined) {
      filteredSnapshots = allSnapshots.filter(
        (test) => test.createdBy.uuid === authStore.user.uuid
      );
    } else {
      filteredSnapshots = allSnapshots.filter(
        (test) => test.startup.uuid === e.value
      );
    }

    filteredSnapshots.forEach((snapshot) => {
      let date = new Date(snapshot.date);

      newData.push({
        id: snapshot.id,
        surveyName: snapshot.survey.name,
        date: date,
        user: snapshot.createdBy.name,
        surveyType: snapshot.survey.type === "STARTUP" ? "Dream" : "Team",
      });
    });

    setData(newData);
  };

  useEffect(() => {
    hasPermission(PERMISSIONS.RESULTS_LIST_ALL)
      ? getOrganizationOptions()
      : hasPermission(PERMISSIONS.RESULTS_LIST_ORGANIZATION) &&
        !hasPermission(PERMISSIONS.RESULTS_LIST_ALL)
      ? getStartupOptions(authStore.user.organization.uuid)
      : getData();

    textStore.setTexts().then(getText);
  }, []);

  const getText = textStore.texts.find((p) => p.page === "SNAPSHOTS");
  return (
    <>
      <PageHeader
        title={t("menu-items.snapshots")}
        explainer={getText && getText.text}
        link={getText && getText.link}
        textLink={`/rs/text/5`}
      />

      <Container className="pt-4">
        {hasPermission(PERMISSIONS.RESULTS_LIST_ALL) && (
          <PageSection title={t("menu-items.organizations")}>
            <label htmlFor="startup" className="sr-only">
              {t("growth-reports-screen.survey")}
            </label>
            <Select
              id="organization"
              name="organization"
              placeholder={t("placeholderOrgSelect")}
              options={organizationOptions}
              onChange={(e) => getStartupOptions(e.value)}
            />
          </PageSection>
        )}
        {hasPermission(PERMISSIONS.RESULTS_LIST_ORGANIZATION) &&
          startupOptions !== null && (
            <PageSection title={t("menu-items.startups")}>
              <label htmlFor="startup" className="sr-only">
                {t("growth-reports-screen.survey")}
              </label>
              <Select
                id="startup"
                name="startup"
                placeholder={t("placeholderStartupSelect")}
                options={startupOptions}
                onChange={(e) => getData(e)}
              />
            </PageSection>
          )}
        {data === null || data.length > 0 ? (
          <PageSection
            loading={data === null}
            title={t("result-screen.snapshots")}
            className="p-2"
          >
            <Table className="mb-0" responsive>
              <thead>
                <tr>
                  <th>{t("result-screen.name")}</th>
                  <th className="d-none d-md-table-cell">
                    {t("result-screen.date")}
                  </th>
                  <th>{t("result-screen.user")}</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data !== null &&
                  data.map((line, key) => (
                    <tr key={key}>
                      <td>
                        <div className="text-weight-bold">
                          {line.surveyName}
                        </div>
                        <div className="small text-muted text-capitalize">
                          {line.surveyType}
                        </div>
                      </td>
                      <td className="d-none d-md-table-cell">
                        {moment(line.date).format("MMMM Do YYYY")}
                        <div className="d-block  small text-muted">
                          {moment(line.date).format("h:mm:ss a")}
                        </div>
                      </td>
                      <td>{line.user}</td>
                      <td>
                        <div className="d-block small text-muted">
                          {moment(line.date).fromNow()}
                        </div>
                      </td>
                      <td className="text-right">
                        <Link
                          to={`results/${line.id}`}
                          className="btn btn-light btn-sm"
                        >
                          {ReactHtmlParser(
                            sprintf(
                              t("result-screen.list-cta"),
                              `<span class="d-none d-md-inline">`,
                              `</span>`
                            )
                          )}
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </PageSection>
        ) : (
          <div className="text-center py-5">
            <p className="display-4 mx-auto text-black-50">
              {t("result-screen.empty")}
            </p>
            <p className="w-75 mx-auto text-black-50">
              <Link to="/surveys/dream" className="btn btn-primary my-3">
                {t("result-screen.empty-cta")}
              </Link>
            </p>
            <img
              src={emptyImg}
              alt="No snapshots"
              className="img-fluid w-75 mx-auto mt-5"
            />
          </div>
        )}
      </Container>
    </>
  );
};

export default inject(
  `reportsStore`,
  "authStore",
  "startupStore",
  "organizationStore",
  "textStore"
)(observer(Results));
