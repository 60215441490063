import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { Container, Table } from "react-bootstrap/";
import moment from "moment";
import { sprintf } from "sprintf-js";

import PageHeader from "../../components/PageHeader";
import PageSection from "../../components/PageSection";

const ResultDetail = ({ id, reportsStore }) => {
  const { t } = useTranslation();

  const [snapshot, setSnapshot] = useState(null);
  const [data, setData] = useState([]);

  let getData = (snapshot) => {
    let newData = [];

    snapshot.answers.forEach((line) => {
      let answer;

      switch (line.question.answerGroup.type) {
        case "MULTIPLE_CHOICE":
          let answers = [];
          line.multipleSelectedAnswers.map((a) => answers.push(a.text));
          answer = answers.join(", ");
          break;
        case "SINGLE_CHOICE":
          answer = line.selectedAnswer.text;
          break;
        case "TEXT":
          answer = line.textResponse;
          break;
        case "YES_NO":
          answer = line.selectedAnswer.text === "TRUE" ? t("Yes") : t("No");
          break;
        default:
          answer = "N/A";
      }

      newData.push({
        id: line.id,
        question: line.question.text,
        answer,
      });
    });

    setData(newData);
  };

  useEffect(() => {
    reportsStore.findById(id).then((r) => {
      getData(r);
      setSnapshot(r);
    });
  }, []);

  return (
    <>
      <PageHeader
        title={
          <>
            {t("reports-screen.snapshot")}
            {snapshot && (
              <span>
                {" "}
                {t("result-detail-screen.from")} {snapshot.survey.name}
              </span>
            )}
          </>
        }
        actions={
          <>
            {snapshot && (
              <p className="text-black-50">
                {sprintf(
                  t("result-detail-screen.subtitle"),
                  moment(snapshot.date).format("ll"),
                  snapshot.createdBy.name
                )}
              </p>
            )}
            <Link className="d-inline-block my-3 text-primary" to="/results">
              {t("all")}
            </Link>
          </>
        }
        append
        hideActionsOnScroll
      />
      <Container>
        <PageSection loading={data.length === 0} title="" className="p-2">
          <Table className="mb-0" responsive>
            <thead>
              <tr>
                <th>{t("result-detail-screen.nmr")}</th>
                <th>{t("result-detail-screen.question")}</th>
                <th>{t("result-detail-screen.answer")}</th>
              </tr>
            </thead>
            <tbody>
              {data.map((line, key) => (
                <tr key={key}>
                  <td>{key + 1}</td>
                  <td>{line.question}</td>
                  <td>{line.answer}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </PageSection>
      </Container>
    </>
  );
};

export default inject(`reportsStore`)(observer(ResultDetail));
