import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import edit from "../assets/icons/edit.svg";
import { observer, inject } from "mobx-react";
import { PERMISSIONS } from "../constants/index";

const PageHeader = ({
  authStore,
  title,
  explainer,
  link,
  actions,
  retract,
  append,
  hideActionsOnScroll,
  textLink,
}) => {
  const [scrolled, setScrolled] = useState(false);

  const trackScrolling = (event) => {
    window.requestAnimationFrame(() => {
      const scrolledPixels = event.target.scrollTop;
      const evaluation = scrolledPixels > 220;

      if (!scrolled && evaluation) setScrolled(true);
      else setScrolled(false);
    });
  };

  const hasPermission = (permission) => {
    return (
      authStore.user && authStore.user.permissions.find((p) => p === permission)
    );
  };

  useEffect(() => {
    const page = document.getElementById("page");
    page.addEventListener("scroll", trackScrolling);
  }, []);

  const explainerContent = explainer ? (
    <>
      <div className="row">
        <p className="text-muted w-75 my-3 col">{explainer}</p>
        {hasPermission(PERMISSIONS.DASHBOARD_SHOW_ALL) && (
          <div className="text-right col-1">
            <Link to={textLink}>
              <img src={edit} alt="Edit icon" />
            </Link>
          </div>
        )}
      </div>
      <div className="row">
        {link && (
          <a href={link} target="_blank" className="text-primary w-75 ml-3">
            {link}
          </a>
        )}
      </div>
    </>
  ) : (
    <>
      {hasPermission(PERMISSIONS.DASHBOARD_SHOW_ALL) ? (
        <div className="text-right">
          <Link to={textLink}>
            <img src={edit} alt="Edit icon" />
          </Link>
        </div>
      ) : (
        <></>
      )}
    </>
  );

  return (
    <>
      <header
        className={
          "border-bottom sticky-page-header " +
          classNames({ show: retract || scrolled })
        }
      >
        <Container className="d-flex align-items-md-center justify-content-between flex-column flex-md-row pt-2 pt-md-0">
          <h1 className="m-0">{title}</h1>
          {!hideActionsOnScroll && <div>{actions}</div>}
        </Container>
      </header>

      <header
        className={
          "border-bottom page-header " +
          classNames({ retract: retract || scrolled })
        }
      >
        <Container className={append ? "pt-5" : "py-5"}>
          <h1 className="m-0">{title}</h1>
          {explainerContent}

          <div>{actions}</div>
        </Container>
      </header>
    </>
  );
};

export default inject(`authStore`)(observer(PageHeader));
