import { reponseHandlers } from "../api";
import download from "downloadjs";
class Service {
  constructor(entity) {
    this.entity = entity;
  }

  getById = async (id) => {
    return window.api.get(`${this.entity}/${id}`).then(...reponseHandlers);
  };

  downloadFileRequest = async (id) => {
    window.api
      .get(`${this.entity}/${id}`, {
        headers: this.headers,
        responseType: "blob",
      })
      .then((response) => {
        const content = response.headers["content-type"];
        return download(response.data, `${this.entity}/${id}`, content);
      })
      .catch((error) => console.log(error));
  };

  getAll = async () => {
    return window.api.get(this.entity).then(...reponseHandlers);
  };

  create = async (data) => {
    return window.api.post(this.entity, data).then(...reponseHandlers);
  };

  createKanbanAdmin = async (startupId, data) => {
    console.log(startupId, data)
    return window.api
      .post(`${this.entity}/${startupId}`, data)
      .then(...reponseHandlers);
  };

  update = async (id, data) => {
    return window.api
      .patch(`${this.entity}/${id}`, data)
      .then(...reponseHandlers);
  };

  replace = async (id, data) => {
    return window.api
      .put(`${this.entity}/${id}`, data)
      .then(...reponseHandlers);
  };

  delete = async (id) => {
    return window.api.delete(`${this.entity}/${id}`).then(...reponseHandlers);
  };
}

export default Service;
