import React from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { ROUTES } from "../../../constants";
import succesResetPasswordStyles from "./SuccesResetPassword.module.scss";
import globalStyles from "../../../styles/main.scss";

const SuccesResetPassword = ({ history }) => {
  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    history.push(ROUTES.login);
  };

  return (
    <form onSubmit={handleSubmit} className={globalStyles.formContainer}>
      <p className={succesResetPasswordStyles.bio}>
        {t("reset-password-succes-screen.intro")}
      </p>
      <button type="submit" className="btn btn-secondary">
        {t("reset-password-succes-screen.cta")}
      </button>
    </form>
  );
};

export default withRouter(SuccesResetPassword);
