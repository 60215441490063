import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";

import { Container, Table, Button, Form } from "react-bootstrap";

import PageSection from "../../components/PageSection";
import NavSVG from "../../components/NavSVG";

const Management = ({ surveyStore, surveys, close, history }) => {
  const { t } = useTranslation();
  const [select, setSelect] = useState("");
  const [check, setCheck] = useState("");

  const [archiving, setArchiving] = useState(false);

  const filterType =
    surveys &&
    surveys.filter((u) => {
      return u.type.toLowerCase().includes(select.toLowerCase());
    });

  const filterActive =
    filterType &&
    filterType.filter((u) => {
      return check === "" ? filterType : u.active === check;
    });

  useEffect(() => {}, []);

  const handleDeleteSurvey = (surveyId) => {
    setArchiving(true);
    surveyStore.deleteSurvey(surveyId).catch(() => close());
    setArchiving(false);
  };

  return (
    <Container className="pt-4">
      <PageSection title={t("questions-survey.filters-title")} className="p-2">
        <div className="row cols-3">
          <div className="col">
            <Form.Label>{t("management-survey.label")}</Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => setSelect(e.target.value)}
              id="search"
            >
              <option value="">{t("management-survey.all")}</option>
              <option>{t("management-survey.startup")}</option>
              <option>{t("management-survey.team")}</option>
            </Form.Control>
          </div>
          <div className="col">
            <Form.Label></Form.Label>
            <Form.Check
              type="switch"
              label={t("management-survey.active")}
              value="true"
              onChange={() => (check === true ? setCheck("") : setCheck(true))}
              id="checked"
            />
          </div>
        </div>
      </PageSection>

      <div className="text-right">
        <Link to={`/surveys/management/new`}>{t("management-survey.cta")}</Link>
      </div>

      <PageSection title="Check-ups" className="p-2">
        <Table className="mb-0" responsive>
          <thead>
            <tr>
              <th>{t("result-screen.name")}</th>
              <th className="d-none d-md-table-cell">
                {t("management-survey.labels.noq")}
              </th>
              <th>{t("management-survey.label")}</th>
              <th>{t("management-survey.active")}</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filterActive &&
              filterActive.map((survey, key) => (
                <tr key={key}>
                  <td>
                    <div className="text-weight-bold">{survey.name}</div>
                  </td>
                  <td>{survey.questionCount}</td>
                  <td>{survey.type}</td>
                  <td>
                    <NavSVG icon={survey.active === true ? "Check" : "Cross"} />
                  </td>
                  <td className="text-right">
                    <Link to={`/surveys/management/${survey.id}`}>
                      {t("edit")}
                    </Link>
                  </td>
                  <td className="text-right">
                    {survey.hasSnapshots !== true ? (
                      <Button
                        variant="link"
                        className="p-0 text-danger"
                        onClick={() => {
                          window.confirm(t("sure")) &&
                            handleDeleteSurvey(survey.id);
                        }}
                      >
                        {t("delete")}
                      </Button>
                    ) : (
                      <p className="text-muted">
                        {t("management-survey.cant")}
                      </p>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </PageSection>
    </Container>
  );
};

export default inject(`surveyStore`)(observer(Management));
