import React from "react";
import { ROUTES } from "../../constants";
import { useHistory, withRouter } from "react-router-dom";

import ResetPassword from "../../components/auth/reset/ResetPasswordForm";
import ResetSucces from "../../components/auth/succes/SuccesResetPassword";

import Logo_mywayfinder from "../../assets/logo_full.jpg";

import bgReset from "../../assets/bg_reset.svg";
import bgResetSucces from "../../assets/bg_resetSucces.svg";

import { Container } from "react-bootstrap";

const Reset = () => {
  let history = useHistory();

  switch (history.location.pathname) {
    case ROUTES.resetSucces:
      return (
        <Container fluid>
          <section>
            <article className="shadow-sm card">
              <img src={Logo_mywayfinder} width="220" height="46" alt="Logo" />
              <ResetSucces />
            </article>
            <div>
              <img src={bgResetSucces} alt="achtergrond pic" />
            </div>
          </section>
        </Container>
      );
    default:
      return (
        <Container fluid>
          <section>
            <article className="shadow-sm card">
              <img src={Logo_mywayfinder} width="220" height="46" alt="Logo" />
              <ResetPassword />
            </article>
            <div>
              <img src={bgReset} alt="achtergrond pic" />
            </div>
          </section>
        </Container>
      );
  }
};

export default withRouter(Reset);
