import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { withRouter, useLocation } from "react-router-dom";
import { ROUTES } from "../../../constants";
import { inject } from "mobx-react";

import NavSVG from "../../NavSVG";

import { Button, Spinner } from "react-bootstrap";

import * as yup from "yup";
import { Formik, Form, Field } from "formik";
import InputFormField from "../../fields/InputFormField";

const schema = yup.object({
  password: yup.string().required("Please fill in a new password"),
});

const ResetPasswordForm = ({ authStore, history }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [emailUrl, setEmailUrl] = useState("");
  const [tokenUrl, setTokenUrl] = useState("");

  let location = useLocation();

  useEffect(() => {
    let searchParams = new URLSearchParams(location.search);

    let email = searchParams.get("email");
    setEmailUrl(email);

    let token = searchParams.get("token");
    setTokenUrl(token);
  }, [location]);

  const handleSubmit = (e) => {
    setLoading(true);

    authStore
      .resetPassword(emailUrl, tokenUrl, e.password)
      .then(() => {
        history.push(ROUTES.resetSucces);
      })
      .then(() => setLoading(false));
  };

  return (
    <Formik
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{ password: "" }}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        await handleSubmit(values);
        setSubmitting(false);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <p className="small text-muted">{t("reset-password-screen.intro")}</p>
          <div className="password-field-wrapper mt-3">
            <Field
              label={t("login-screen.password")}
              name="password"
              type={showPassword ? "text" : "password"}
              component={InputFormField}
            />
            <button
              type="button"
              className="toggle-password-visibility"
              onClick={() => setShowPassword(!showPassword)}
            >
              <NavSVG icon={showPassword ? "visible" : "visible-off"} />
            </button>
          </div>
          <Button
            variant="primary"
            disabled={isSubmitting}
            className="mt-4"
            type="submit"
            block
          >
            {loading && (
              <Spinner
                className="mr-2 mb-1"
                as="span"
                variant="light"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            {t("reset-password-screen.cta")}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default inject(`authStore`)(withRouter(ResetPasswordForm));
