import { decorate, observable, action } from "mobx";
import UserService from "../services/UserService";
import Service from "../services/Service";

class UserStore {
  users = undefined;
  roles = undefined;

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.userService = new UserService();
    this.roleService = new Service(`roles`);
    this.pwService = new Service(`users/resetPassword`);
  }

  getById = (id) => this.userService.getById(id);

  updateUser = (id, props) => {
    return this.userService.replace(id, props);
  };

  updateUserPassword = (id, props) => {
    return this.pwService.replace(id, props);
  };

  createUser = (props) => {
    return this.userService.create(props);
  };

  deleteUser = (id) => {
    return this.userService.delete(id);
  };

  setUsers = () => {
    return this.userService.getAll().then((users) => (this.users = users));
  };

  setRoles = () => {
    return this.roleService.getAll().then((r) => (this.roles = r));
  };
}

decorate(UserStore, {
  users: observable,
  roles: observable,
  setUsers: action,
  createUser: action,
  deleteUser: action,
  updateUser: action,
  setRoles: action,
});

export default UserStore;
