import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";

import { Container, Table, Button } from "react-bootstrap";

import PageSection from "../../components/PageSection";

const PhaseCategories = ({ surveyAdminStore, phaseCategories, close }) => {
  const { t } = useTranslation();

  useEffect(() => {}, []);

  const handleDelete = (phaseCategorieId) => {
    surveyAdminStore
      .deletePhaseCategory(phaseCategorieId)
      .then(() => close())
      .catch((e) => alert(e.message));
  };

  return (
    <Container className="pt-4">
      <div className="text-right">
        <Link to={`/surveys/phase-categories/new`}>
          {t("phase-cat-survey.cta")}
        </Link>
      </div>

      <PageSection title={t("phase-cat-survey.title")} className="p-2">
        <Table className="mb-0" responsive>
          <thead>
            <tr>
              <th>{t("phase-cat-survey.name")}</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {phaseCategories &&
              phaseCategories.map((phase, key) => (
                <tr key={key}>
                  <td>
                    <div>{phase.name}</div>
                  </td>
                  <td>
                    <Link to={`/surveys/phase-categories/${phase.id}`}>
                      {t("edit")}
                    </Link>
                  </td>
                  <td className="text-right">
                    <Button
                      variant="link"
                      className="p-0 text-danger"
                      onClick={() => {
                        window.confirm(t("sure")) && handleDelete(phase.id);
                      }}
                    >
                      {t("delete")}
                    </Button>
                    {/* <p className="text-muted">Can't be deleted</p> */}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </PageSection>
    </Container>
  );
};

export default inject(`surveyAdminStore`)(observer(PhaseCategories));
