import React from "react";
import Form from "react-bootstrap/Form";

const CheckFormField = ({ field, ...props }) => {
  return (
    <div>
      <Form.Check {...field} {...props} />
    </div>
  );
};

export default CheckFormField;
