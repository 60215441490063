import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Modal, Spinner, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InputFormField from "./fields/InputFormField";
import { Formik, Form, Field } from "formik";

const PageHeaderTextEdit = ({ textStore, title, close, history }) => {
  const { t } = useTranslation();
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    textStore.setTexts().then(getText);
  }, []);

  const getText = textStore.texts.find((p) => p.page === title.toUpperCase());

  return (
    <section className="modal-wrapper">
      <Modal.Dialog size="lg">
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            text: getText && getText.text,
            link: getText && getText.link,
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            setUpdating(true);
            const updatePage = getText && getText.page;
            const valuesNew = {
              page: title.toUpperCase,
              text: values.text,
              link: values.link,
            };

            getText
              ? textStore
                  .updateText(updatePage, values)
                  .then(() => history.push(close))
              : textStore.createText(valuesNew).then(() => history.push(close));
            setUpdating(false);
            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Modal.Header closeButton onHide={() => history.push(close)}>
                <Modal.Title>{title} Description</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <div className="row">
                  <div className="col">
                    <Field
                      label="Description"
                      as="textarea"
                      className="w-100"
                      rows="10"
                      name="text"
                      placeholder="Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum aspernatur illum assumenda delectus fugit placeat, enim inventore nisi quia, at, ad officia necessitatibus neque!"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <Field
                      label="Video / Website / ... Link"
                      name="link"
                      component={InputFormField}
                      description="Don't forget to add the https:// or http://"
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className="mt-4">
                <Button onClick={() => history.push(close)} variant="link">
                  {t("cancel")}
                </Button>
                <Button type="submit" disabled={isSubmitting} variant="primary">
                  {updating ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    t("save")
                  )}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal.Dialog>
    </section>
  );
};

export default inject("textStore")(withRouter(observer(PageHeaderTextEdit)));
