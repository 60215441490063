import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Container, Form } from "react-bootstrap";

import PageHeader from "../../components/PageHeader";
import PageSection from "../../components/PageSection";
import { PERMISSIONS } from "../../constants";
import sections from "./sections";

import IncompatibleSectionWarning from "./components/IncompatibleSectionWarning";
import CustomPermissionsModal from "./components/CustomPermissionsModal";

import { useTranslation } from "react-i18next";

const Permissions = ({ userStore, id }) => {
  const [user, setUser] = useState(null);
  const { t } = useTranslation();
  const [showCustomPermissionsModal, setShowCustomPermissionsModal] = useState(
    false
  );
  const [sectionCPM, setSectionCPM] = useState("");

  useEffect(() => {
    userStore
      .getById(id)
      .then((user) => setUser(user))
      .catch((e) => alert(e.message));
  }, []);

  const getPermissions = (section, level) => {
    let permissions = [];
    for (let i = 0; i < level + 1; i++) {
      permissions = permissions.concat(sections[section][i].adds);
    }
    return [...new Set(permissions)];
  };

  const levelIsActive = (section, _permissions) => {
    for (let i = 0; i < _permissions.length; i++) {
      if (!user.permissions.includes(_permissions[i])) return false;
    }

    const sectionPermissions = user.permissions.filter((p) =>
      p.startsWith(section.toUpperCase())
    );

    for (let i = 0; i < sectionPermissions.length; i++) {
      if (!_permissions.includes(sectionPermissions[i])) return false;
    }

    return true;
  };

  const sectionIsActive = (section) => {
    const permissions = user.permissions.filter((p) =>
      p.startsWith(section.toUpperCase())
    );
    return permissions.length > 0;
  };

  const isOther = (section) => {
    for (let i = 0; i < sections[section].length; i++) {
      if (levelIsActive(section, getPermissions(section, i))) return false;
    }
    return sectionIsActive(section);
  };

  const updatePermissions = (section, _permissions) => {
    let permissions = user.permissions.filter(
      (p) => !p.startsWith(section.toUpperCase())
    );
    permissions = permissions.concat(_permissions);
    user.permissions = [...new Set(permissions)];
    setUser({ ...user });
  };

  const getActivePermissionsForSection = (section) => {
    return user.permissions.filter((p) => p.startsWith(section.toUpperCase()));
  };

  const deactivateSection = (section) => {
    updatePermissions(section, []);
  };

  const activateSection = (section) => {
    updatePermissions(section, sections[section][0].adds);
  };

  const truncateString = (string, maxLength = 50) => {
    if (!string) return null;
    if (string.length <= maxLength) return string;
    return `${string.substring(0, maxLength)}...`;
  };

  const showIncompatibleSectionWarning = (section) => {
    return (
      section === "Reports" &&
      sectionIsActive(section) &&
      user &&
      !getActivePermissionsForSection("RESULTS").includes(
        PERMISSIONS.RESULTS_LIST_STARTUP
      )
    );
  };

  const editCustomPermissions = (section) => {
    setSectionCPM(section);
    setShowCustomPermissionsModal(true);
  };

  return (
    <>
      <PageHeader
        title={
          t("permissions.title") +
          (user ? ` ${t("permissions.title2")} ${user.name}` : "")
        }
      />
      <Container fluid>
        <PageSection loading={!user} className="p-0">
          {user && (
            <>
              {Object.keys(sections).map((section, sKey) => {
                const levels = sections[section];
                return (
                  <section
                    className="d-flex align-items-center border-bottom flex-nowrap  bg-light"
                    key={`section_${sKey}`}
                  >
                    <div className="d-flex align-items-center flex-nowrap section-container">
                      <h2 className="m-3 section-title mx-3">{section}</h2>
                      <div className="toggle p-3">
                        <Form.Check
                          type="switch"
                          onChange={(e) => {
                            if (sectionIsActive(section))
                              deactivateSection(section);
                            else activateSection(section);
                          }}
                          checked={sectionIsActive(section)}
                          id={`switch_section_${sKey}`}
                          label=""
                        />
                      </div>
                    </div>
                    <div className="flex-grow-1 overflow-hidden">
                      <IncompatibleSectionWarning
                        show={showIncompatibleSectionWarning(section)}
                      />

                      <div className="d-flex overflow-x-auto w-100 bg-white py-3">
                        <div className="spacer"></div>
                        <button
                          onClick={() => {
                            editCustomPermissions(section);
                          }}
                          className={
                            "border p-3 m-2 rounded-lg bg-white level level-custom " +
                            (isOther(section) ? "border-primary shadow-sm" : "")
                          }
                        >
                          <span className="d-block font-weight-bold">
                            {t("changed")}
                          </span>
                        </button>

                        {levels.map((permission, level) => (
                          <button
                            onClick={() => {
                              updatePermissions(
                                section,
                                getPermissions(section, level)
                              );
                            }}
                            key={`button_section_${sKey}_level_${level}`}
                            className={
                              "border p-3 m-2 rounded-lg bg-white level " +
                              (levelIsActive(
                                section,
                                getPermissions(section, level)
                              )
                                ? "border-primary shadow-sm"
                                : "")
                            }
                          >
                            <span className="d-block font-weight-bold">
                              {truncateString(permission.title, 21)}
                            </span>
                            <span className="d-block mt-1 small text-muted">
                              {truncateString(permission.explainer, 45)}
                            </span>
                          </button>
                        ))}

                        <div className="spacer"></div>
                      </div>
                    </div>
                  </section>
                );
              })}
            </>
          )}
        </PageSection>
      </Container>

      <CustomPermissionsModal
        user={user}
        shown={showCustomPermissionsModal}
        updatePermissions={updatePermissions}
        section={sectionCPM}
        close={() => setShowCustomPermissionsModal(false)}
      />
    </>
  );
};

export default inject("userStore")(observer(Permissions));
