import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { observer, inject } from "mobx-react";

import { Container, Table, Form } from "react-bootstrap";

import PageSection from "../../components/PageSection";
import NavSVG from "../../components/NavSVG";

const Questions = ({ surveyAdminStore, questions }) => {
  const { t } = useTranslation();
  const [select, setSelect] = useState("");
  const [kpiCheck, setKpiCheck] = useState("");
  const [algoCheck, setAlgoCheck] = useState("");
  const [filterCheck, setFilterCheck] = useState("");
  const [otherCheck, setOtherCheck] = useState("");
  const [allAnswers, setAllAnswers] = useState(null);

  useEffect(() => {
    surveyAdminStore
      .setAnswers()
      .then((a) => setAllAnswers(a))
      .catch((e) => alert(e.message));
  }, []);

  const filterKpi =
    questions &&
    questions.filter((u) => {
      return kpiCheck === "" ? questions : u.kpi === kpiCheck;
    });

  const filterAlgo =
    filterKpi &&
    filterKpi.filter((u) => {
      return algoCheck === "" ? filterKpi : u.algo && u.algo === algoCheck;
    });

  const filterFilter =
    filterAlgo &&
    filterAlgo.filter((u) => {
      return filterCheck === ""
        ? filterAlgo
        : u.filter && u.filter === filterCheck;
    });

  const filterOther =
    filterFilter &&
    filterFilter.filter((u) => {
      return otherCheck === "" ? filterFilter : u.hasOther === otherCheck;
    });

  const filterAnswer =
    filterOther &&
    filterOther.filter((u) => {
      return (
        u.answerGroup &&
        u.answerGroup.name.toLowerCase().includes(select.toLowerCase())
      );
    });

  return (
    <>
      <Container className="pt-4  ">
        <PageSection
          title={t("questions-survey.filters-title")}
          className="p-2"
        >
          <div className="row cols-9">
            <div className="col">
              <Form.Label></Form.Label>
              <Form.Check
                type="switch"
                label={t("questions-survey.label-kpi")}
                value="true"
                onChange={() =>
                  kpiCheck === true ? setKpiCheck("") : setKpiCheck(true)
                }
                id="checked"
              />
            </div>
            <div className="col">
              <Form.Label></Form.Label>
              <Form.Check
                type="switch"
                label={t("questions-survey.label-algo")}
                value="true"
                onChange={() =>
                  algoCheck === true ? setAlgoCheck("") : setAlgoCheck(true)
                }
                id="checked1"
              />
            </div>
            <div className="col">
              <Form.Label></Form.Label>
              <Form.Check
                type="switch"
                label={t("questions-survey.label-filter")}
                value="true"
                onChange={() =>
                  filterCheck === true
                    ? setFilterCheck("")
                    : setFilterCheck(true)
                }
                id="checked2"
              />
            </div>
            <div className="col">
              <Form.Label></Form.Label>
              <Form.Check
                type="switch"
                label={t("questions-survey.label-other")}
                value="true"
                onChange={() =>
                  otherCheck === true ? setOtherCheck("") : setOtherCheck(true)
                }
                id="checked3"
              />
            </div>
            <div className="col-5">
              <Form.Label>{t("questions-survey.answer")}</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => setSelect(e.target.value)}
                id="search"
              >
                <option value="">{t("user-screen.all-option")}</option>
                {allAnswers &&
                  allAnswers.map((a, key) => (
                    <option key={key}>{a.name}</option>
                  ))}
              </Form.Control>
            </div>
          </div>
        </PageSection>
        <div className="text-right">
          <Link to={`/surveys/questions/new`}>{t("questions-survey.cta")}</Link>
        </div>

        <PageSection title="Questions" className="p-2">
          <Table className="mb-0" responsive>
            <thead>
              <tr>
                <th>{t("questions-survey.labels.question")}</th>
                <th>{t("questions-survey.label-kpi")}</th>
                <th>{t("questions-survey.label-algo")}</th>
                <th>{t("questions-survey.label-filter")}</th>
                <th>{t("questions-survey.label-other")}</th>
                <th>{t("questions-survey.labels.group")}</th>
                <th>{t("questions-survey.answer")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filterAnswer &&
                filterAnswer.map((question, key) => (
                  <tr key={key}>
                    <td>
                      <div>{question.text}</div>
                    </td>
                    <td>
                      <div>
                        {" "}
                        <NavSVG
                          icon={question.kpi === true ? "Check" : "Cross"}
                        />
                      </div>
                    </td>
                    <td>
                      <div>
                        {" "}
                        <NavSVG
                          icon={question.algo === true ? "Check" : "Cross"}
                        />
                      </div>
                    </td>
                    <td>
                      <div>
                        {" "}
                        <NavSVG
                          icon={question.filter === true ? "Check" : "Cross"}
                        />
                      </div>
                    </td>
                    <td>
                      <div>
                        <NavSVG
                          icon={question.hasOther === true ? "Check" : "Cross"}
                        />
                      </div>
                    </td>
                    <td>
                      <div>
                        {question.groupCategory && question.groupCategory.name}
                      </div>
                    </td>
                    <td>
                      <div>
                        {question.answerGroup && question.answerGroup.name}
                      </div>
                    </td>
                    <td>
                      <Link to={`/surveys/questions/${question.id}`}>Edit</Link>
                      <div>
                        <Link
                          to={`/surveys/questions/${question.id}/kpi-adjustments`}
                          className="text-muted"
                        >
                          {t("questions-survey.kpi-adjustments")}
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </PageSection>
      </Container>
    </>
  );
};

export default inject("surveyAdminStore")(observer(Questions));
