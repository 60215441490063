import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Container, Spinner } from "react-bootstrap";
import { inject, observer } from "mobx-react";

import Board from "@lourenci/react-kanban";
import "@lourenci/react-kanban/dist/styles.css";

import KanbanCard from "./components/KanbanCard.jsx";
import NewTaskModal from "./components/NewTaskModal.jsx";
import EditTaskModal from "./components/EditTaskModal.jsx";
import PageHeader from "../../components/PageHeader.jsx";
import PageSection from "../../components/PageSection";

import { PERMISSIONS } from "../../constants/index";
import Select from "react-select";

const Kanban = ({
  kanbanStore,
  textStore,
  authStore,
  organizationStore,
  startupStore,
}) => {
  const { t } = useTranslation();

  const [newTaskModalShown, setNewTaskModalShown] = useState(false);
  const [editTask, setEditTask] = useState(null);
  const [initialLoadCompleted, setInitialLoadCompleted] = useState(false);
  const [saving, setSaving] = useState(false);

  const [startupOptions, setStartupOptions] = useState(null);
  const [organizationOptions, setOrganizationOptions] = useState(null);
  const [startupUuid, setStartupUuid] = useState(null);

  const hasPermission = (permission) => {
    return (
      authStore.user && authStore.user.permissions.find((p) => p === permission)
    );
  };

  const getOrganizationOptions = async () => {
    let newOrganizationOptions = [];
    await organizationStore.setOrganizations().catch((e) => alert(e.message));

    organizationStore.organizations &&
      organizationStore.organizations.map((o) => {
        newOrganizationOptions.push({
          label: o.name,
          value: o.uuid,
        });
      });

    setOrganizationOptions(newOrganizationOptions);
  };

  const getStartupOptions = async (organizationId) => {
    const newStartupOptions = [];

    await startupStore.setStartups().catch((e) => {
      alert(e.message);
    });

    const allStartups = startupStore.startups;
    const filteredStartups = allStartups
      .map((item) => ({
        ...item,
        organizations: item.organizations.filter(
          (org) => org.uuid === organizationId
        ),
      }))
      .filter((startup) => startup.organizations.length > 0);

    filteredStartups.map((startup) => {
      newStartupOptions.push({
        label: startup.name,
        value: startup.uuid,
      });
    });

    setStartupOptions(newStartupOptions);
  };

  const board = {
    columns: [
      {
        id: 1,
        title: t("kanban-screen.todo"),
        cards: [],
      },
      {
        id: 2,
        title: t("kanban-screen.inProgress"),
        cards: [],
      },
      {
        id: 3,
        title: t("kanban-screen.done"),
        cards: [],
      },
    ],
  };

  const [initialBoard, setInitialBoard] = useState(board);

  const addInfoCard = () => {
    initialBoard.columns
      .find((c) => c.id === 1)
      .cards.push({
        id: 0,
        infoCard: true,
      });

    setInitialBoard({ ...initialBoard });
  };

  const clearBoard = () => {
    initialBoard.columns.map((c) => (c.cards = []));
    setInitialBoard({ ...initialBoard });
  };

  const getCards = async () => {
    setSaving(true);

    let cards = await kanbanStore.getAllKanbanTasks();

    clearBoard();

    const board = { ...initialBoard };

    cards = cards.sort((a, b) => a.position - b.position);

    cards.map((card) => {
      let column;

      switch (card.status) {
        case "TODO":
          column = board.columns.find((c) => c.id === 1);
          break;
        case "IN_PROGRESS":
          column = board.columns.find((c) => c.id === 2);
          break;
        case "DONE":
          column = board.columns.find((c) => c.id === 3);
          break;
        default:
          return null;
      }

      column.cards.push(card);
    });

    setInitialBoard(board);
    setSaving(false);
  };

  const getCardsAdmin = async (startupId) => {
    setSaving(true);

    let cards = await kanbanStore.getAllKanbanTasksAdmin(startupId);

    clearBoard();

    const board = { ...initialBoard };

    cards = cards.sort((a, b) => a.position - b.position);

    cards.map((card) => {
      let column;

      switch (card.status) {
        case "TODO":
          column = board.columns.find((c) => c.id === 1);
          break;
        case "IN_PROGRESS":
          column = board.columns.find((c) => c.id === 2);
          break;
        case "DONE":
          column = board.columns.find((c) => c.id === 3);
          break;
        default:
          return null;
      }

      column.cards.push(card);
    });

    setInitialBoard(board);
    setSaving(false);
  };

  const handleCardMove = (card, source, destination) => {
    const statuses = ["TODO", "IN_PROGRESS", "DONE"];
    const updatedCard = { ...card };

    setSaving(true);

    updatedCard.position = destination.toPosition;
    updatedCard.status = statuses[destination.toColumnId - 1];

    initialBoard.columns.map((column) => {
      if (column.id === source.fromColumnId) {
        const removeIndex = column.cards
          .map((item) => item.id)
          .indexOf(card.id);
        column.cards.splice(removeIndex, 1);
      }

      if (column.id === destination.toColumnId) {
        column.cards.splice(destination.toPosition, 0, updatedCard);
      }
    });

    setInitialBoard({ ...initialBoard });

    kanbanStore.updateTask(updatedCard.id, updatedCard).then(() => {
      hasPermission(PERMISSIONS.KANBAN_LIST_ALL) || hasPermission(PERMISSIONS.KANBAN_LIST_ORGANIZATION) 
      ? getCardsAdmin(startupUuid)
      : getCards();
    });
  };

  const handleNewCardAdded = (content) => {
    const position = initialBoard.columns[0].cards.length;

    const card = {
      title: content.title,
      description: content.description,
      status: "TODO",
      position: position,
    };

    return hasPermission(PERMISSIONS.KANBAN_LIST_ALL) || hasPermission(PERMISSIONS.KANBAN_LIST_ORGANIZATION) 
      ? kanbanStore.createTaskAdmin(startupUuid, card).then(() => { 
        getCardsAdmin(startupUuid);
      })
      : kanbanStore.createTask(card).then(() => { 
        getCards();
      });
  };

  useEffect(() => {
    hasPermission(PERMISSIONS.KANBAN_LIST_ALL)
      ? getOrganizationOptions()
      : hasPermission(PERMISSIONS.KANBAN_LIST_ORGANIZATION) &&
        !hasPermission(PERMISSIONS.KANBAN_LIST_ALL)
      ? getStartupOptions(authStore.user.organization.uuid)
      : getCards().then(() => {
          if (!initialBoard.columns.filter((c) => c.cards.length > 0).length) {
            addInfoCard();
          }

          setInitialLoadCompleted(true);
        });

    textStore.setTexts().then(getText);
  }, []);

  const getText = textStore.texts.find((p) => p.page === "KANBAN");

  return (
    <>
      <PageHeader
        title={t("kanban-screen.title")}
        explainer={getText && getText.text}
        link={getText && getText.link}
        textLink={`/k/text/10`}
        actions={
          <>
            <Button
              variant="primary"
              onClick={() => {
                setNewTaskModalShown(true);
              }}
            >
              {t("kanban-screen.new")}
            </Button>

            {saving && initialLoadCompleted && (
              <span className="text-muted">
                <Spinner
                  variant="primary"
                  animation="border"
                  size="sm"
                  className="ml-3 mr-1"
                />
                {t("saving")}
              </span>
            )}
          </>
        }
      />
      <Container className="pt-4">
        {hasPermission(PERMISSIONS.KANBAN_LIST_ALL) &&
          organizationOptions !== null && (
            <PageSection title={t("menu-items.organizations")}>
              <label htmlFor="startup" className="sr-only">
                {t("growth-reports-screen.survey")}
              </label>
              <Select
                id="organization"
                name="organization"
                placeholder={t("placeholderOrgSelect")}
                options={organizationOptions}
                onChange={(e) => {
                  setInitialLoadCompleted(false);
                  setStartupOptions(null);
                  getStartupOptions(e.value);
                }}
              />
            </PageSection>
          )}
        {hasPermission(PERMISSIONS.KANBAN_LIST_ORGANIZATION) &&
          startupOptions !== null && (
            <PageSection title={t("menu-items.startups")}>
              <label htmlFor="startup" className="sr-only">
                {t("growth-reports-screen.survey")}
              </label>
              <Select
                id="startup"
                name="startup"
                placeholder={t("placeholderStartupSelect")}
                options={startupOptions}
                onChange={(e) => {
                  setInitialLoadCompleted(false);
                  setStartupUuid(e.value);
                  getCardsAdmin(e.value).then(() => {
                    if (
                      !initialBoard.columns.filter((c) => c.cards.length > 0)
                        .length
                    ) {
                      addInfoCard();
                    }

                    setInitialLoadCompleted(true);
                  });
                }}
              />
            </PageSection>
          )}
      </Container>
      {initialLoadCompleted ? (
        <Container fluid>
          <div className="kanban-row mt-4 py-4">
            <Board
              allowRemoveCard
              disableColumnDrag
              onCardDragEnd={handleCardMove}
              renderCard={(card, { removeCard, dragging }) => (
                <KanbanCard
                  dragging={dragging}
                  content={card}
                  removeCard={removeCard}
                  editCard={() => {
                    kanbanStore.getById(card.id).then((task) => {
                      setEditTask(task);
                    });
                  }}
                />
              )}
            >
              {initialBoard}
            </Board>
          </div>
        </Container>
      ) : (
        <div className="d-flex justify-content-center py-5">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      <NewTaskModal
        shown={newTaskModalShown}
        close={() => {
          setNewTaskModalShown(false);
        }}
        handleCreate={handleNewCardAdded}
      />

      {editTask != null ? (
        <EditTaskModal
          shown={true}
          close={() => {
            hasPermission(PERMISSIONS.KANBAN_LIST_ALL) || hasPermission(PERMISSIONS.KANBAN_LIST_ORGANIZATION) 
              ? getCardsAdmin(startupUuid)
              : getCards();
            setEditTask(null);
          }}
          task={editTask}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default inject(
  `kanbanStore`,
  "textStore",
  "authStore",
  "organizationStore",
  "startupStore"
)(observer(Kanban));
