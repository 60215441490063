import { decorate, observable, action } from "mobx";
import AuthService from "../services/AuthService";
import UserService from "../services/UserService";
import JwtDecode from "jwt-decode";
import moment from "moment";

class AuthStore {
  user = undefined;
  startup = undefined;
  activeStartupUuid = undefined;

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.authService = new AuthService();
    this.userService = new UserService();

    this.attemptReauthentication();
  }

  setUser = (userId) => {
    return this.userService
      .getById(userId)
      .then((data) => {
        this.user = data;
        this.startup = data.startup;
        if (data.startup !== null) {
          this.setActiveStartupUuid(data.startup.uuid);
        } else {
          this.setActiveStartupUuid(false);
        }
      })
      .catch(() => {
        this.logout();
      });
  };

  setActiveStartupUuid = (startupUuid) => {
    localStorage.setItem("startup_uuid", startupUuid);
    this.activeStartupUuid = startupUuid;

    if (startupUuid) {
      window.api.defaults.headers.common["X-Startup"] = startupUuid;
    } else {
      window.api.defaults.headers.common["X-Startup"] = null;
    }
  };

  authenticate = (email, password) => {
    return this.authService
      .login({
        email,
        password,
      })
      .then(async (data) => {
        const tokenPayload = JwtDecode(data.token);

        localStorage.setItem("auth_token", data.token);

        window.api.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${data.token}`;

        await this.setUser(tokenPayload.sub);
      })
      .catch((error) => {
        this.logout();
        throw error;
      });
  };

  attemptReauthentication = () => {
    const token = localStorage.getItem("auth_token");
    if (!token) return this.logout();

    const tokenPayload = JwtDecode(token);

    if (moment().utc().unix().valueOf() < tokenPayload.exp) {
      window.api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      this.setUser(tokenPayload.sub);
    } else this.logout();
  };

  logout = () => {
    this.user = null;
    this.startup = null;
    this.startupUuid = null;

    localStorage.removeItem("auth_token");
    localStorage.removeItem("startup_uuid");
  };

  requestPasswordReset = async (email) => {
    return this.authService.requestPasswordReset({
      email,
    });
  };

  resetPassword = async (email, token, password) => {
    return this.authService.resetPassword({
      email,
      token,
      password,
    });
  };
}

decorate(AuthStore, {
  user: observable,
  startup: observable,
  activeStartupUuid: observable,
  setUser: action,
  setStartups: action,
  setActiveStartupUuid: action,
  authenticate: action,
  attemptReauthentication: action,
  logout: action,
});

export default AuthStore;
