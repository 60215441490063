import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import { Link, Switch } from "react-router-dom";
import { Container, Form, Table } from "react-bootstrap";

import PageHeader from "../../components/PageHeader";
import PageSection from "../../components/PageSection";
import ProtectedRoute from "../../components/ProtectedRoute";

import UserDetailModal from "./components/UserDetailModal";
import NewUserModal from "./components/NewUserModal";
import { ROUTES } from "../../constants";

const Users = ({ userStore, textStore, history }) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [select, setSelect] = useState("");

  const setTheUsers = () => {
    userStore.setUsers().catch((e) => {
      alert(e.message);
    });
  };

  useEffect(() => {
    setTheUsers();
    userStore.setRoles().catch((e) => {
      alert(e.message);
    });
    textStore.setTexts().then(getText);
  }, []);

  const filteredUsers =
    userStore.users &&
    userStore.users.filter((u) => {
      return (
        u.name.toLowerCase().includes(search.toLowerCase()) ||
        u.email.toLowerCase().includes(search.toLowerCase())
      );
    });

  const filterRole =
    filteredUsers &&
    filteredUsers.filter((u) => {
      return select === ""
        ? filteredUsers
        : u.roleId
        ? u.roleId === Number(select)
        : "";
    });

  const getText = textStore.texts.find((p) => p.page === "USERS");
  return (
    <>
      <PageHeader
        title={t("menu-items.users")}
        explainer={getText && getText.text}
        link={getText && getText.link}
        actions={
          <>
            <Link to="/users/new" className="btn btn-primary">
              {t("user-screen.cta")}
            </Link>
          </>
        }
        textLink={`/u/text/8`}
      />

      <Container className="pt-4">
        <PageSection title={t("user-screen.search-title")}>
          <div className="search flex-grow-1">
            <Form.Label htmlFor="search" className="sr-only">
              {t("startup-admin-screen.search-title")}
            </Form.Label>
            <Form.Control
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder={t("user-screen.search-placeholder")}
              id="search"
            />
          </div>
        </PageSection>
        <PageSection title={t("user-screen.filter-title")}>
          <div className="search flex-grow-1">
            <Form.Label htmlFor="search" className="sr-only">
              {t("startup-admin-screen.search-title")}
            </Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => setSelect(e.target.value)}
              id="search"
            >
              <option value="">{t("user-screen.all-option")}</option>
              {userStore.roles &&
                userStore.roles.map((a, key) => (
                  <option key={key} value={a.id}>
                    {a.name}
                  </option>
                ))}
            </Form.Control>
          </div>
        </PageSection>
        <PageSection title={t("menu-items.users")} className="p-2">
          <Table className="mb-0" responsive>
            <thead>
              <tr>
                <th></th>
                <th>{t("user-screen.data-name")}</th>
                <th>{t("user-screen.data-mail")}</th>
                <th>{t("user-screen.data-role")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filterRole &&
                filterRole.map((user, key) => (
                  <tr key={key}>
                    <td>
                      <div className="w-100">
                        <img
                          className="avatar rounded-circle img-thumbnail mr-3"
                          src={`https://avatars.dicebear.com/api/initials/${
                            user.firstName[0] +
                            user.lastName[0] +
                            user.uuid.replace(/(^.+)(\w\d+\w)(.+$)/i, "$2")
                          }.svg`}
                          alt="Avatar"
                        />
                      </div>
                    </td>
                    <td>
                      <div>{user.name}</div>
                    </td>
                    <td>
                      <div className="text-muted">{user.email}</div>
                    </td>
                    <td>
                      <div>
                        {userStore.roles &&
                          userStore.roles[Number(user.roleId - 1)].name}
                      </div>
                    </td>
                    <td>
                      <Link
                        to={`/users/${user.uuid}`}
                        className="btn btn-light btn-sm"
                      >
                        {t("edit")}
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </PageSection>
      </Container>

      <Switch>
        <ProtectedRoute
          embedded
          path="/users/new"
          exact
          strict
          render={() => (
            <NewUserModal
              close={async () => {
                await history.push(ROUTES.users);
                setTheUsers();
              }}
            />
          )}
        />
        <ProtectedRoute
          embedded
          exact
          path="/users/:id"
          strict
          render={({ match }) => (
            <UserDetailModal
              id={match.params.id}
              close={async () => {
                await history.push(ROUTES.users);
                setTheUsers();
              }}
            />
          )}
        />
      </Switch>
    </>
  );
};

export default inject("userStore", "textStore")(observer(Users));
